/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  CardContent,
  Card,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Fab,
  makeStyles,
  Typography,
  ButtonGroup,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  AppBar,
  Popover,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import MapIcon from '@material-ui/icons/Map';
import FilterListIcon from '@material-ui/icons/FilterList';
import IndexLastOrder from '../Components/LastOrder';
import IndexListOrder from '../Components/ListOrder';
import { getOrderStatus } from '../../../components/utils/api/Orders/getOrderStatus';
import {
  getOrdersByVehicle, getUnassignedOrders, getListOrdersOthersVehicles, getMyAssignedVehicle,
} from '../../../redux/actions/vehicle';
import { postChangeOrderStatus } from '../../../components/utils/api/Orders/postChangeOrderStatus';
import StatusBarGlobal from '../../../components/globals/StatusBar/StatusBarGlobal';
import IndexEmpty from '../Components/Empty';
import IndexNotQuadrant from '../Components/NotQuadrant';
import { setOfflineSale } from '../../../components/utils/api/postOffline';
import { postComment } from '../../../components/utils/api/postComment';
import IndexDialogQuestion from '../Components/DialogQuestion';
import { setVehicleToVendor } from '../../../components/utils/api/postVehiculeToVendor';


const useStyles = makeStyles(() => ({
  fab: {
    position: 'fixed',
    bottom: 90,
    right: 20,
    zIndex: 10000,
  },
  fabFilter: {
    position: 'fixed',
    bottom: 30,
    right: 20,
    zIndex: 10000,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
  },
}));

const List = ({
  id, orderStatus, orderNumber, statusId, history, tab, setTab, setView, vehicles, assignmentData, assignmentDataError, listOrders, unassignedListOrders, listOrdersOthersVehicles, cleanListOrdersOthersVehicles, cleanListOrders, cleanMyVehicle,
}) => {
  const classes = useStyles();

  const [load, setLoad] = useState(false);
  const [obtain, setObtain] = useState(false);
  const [obtaindata, setObtainData] = useState(false);
  const [question, setQuestion] = useState(false);
  const [message, setMessage] = useState('');
  const [menuFilter, setMenuFilter] = useState(false);
  const [productFilter, setProductFilter] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterRecompra, setFilterRecompra] = useState(true);
  const [filterNuevo, setFilterNuevo] = useState(true);
  const [questionGrey, setQuestionGrey] = useState(false);
  const [questionOrderNum] = useState(0);
  const [licensePlate, setLicensePlate] = useState('');
  const [licensePlateOthers, setLicensePlateOthers] = useState('');
  const [disabledLicense, setDisabledLicense] = useState(false);
  const [disabledValue, setDisabledValue] = useState(true);

  const dispatch = useDispatch();

  const handleGetOrders = async () => {
    await dispatch(getOrdersByVehicle({
      payload: {
        initDate: null, finalDate: null, vehicle: assignmentData.idSalePointLocation,
      },
    }));
  };

  const filterOrderStatus = (info) => {
    const send = [];
    info.map((i) => {
      send.push({
        show: true,
        description: i.description,
        id: i.idOrderStatus,
      });
      return true;
    });
    setFilterData(send);
  };

  const findProducts = () => {
    const productsAux = [];
    listOrders.map((order) => {
      order.orderProducts.map((products) => {
        const finder = productsAux.find((prod) => prod.id === products.productId);
        if (finder === undefined) {
          productsAux.push({
            show: true,
            description: products.productName,
            id: products.productId,
          });
        }
      });
      return true;
    });
    setProductFilter(productsAux);
  };

  const assignmentVehicle = (licenseP) => {
    setDisabledLicense(true);
    const req = {
      userId: id,
      idSalePoint: licenseP,
    };
    setVehicleToVendor(req, (status, res) => {
      let msj;
      if (licenseP === '' || licenseP === 0) {
        msj = status ? '¡Auto desasignado!' : 'Error al desasignar auto';
      } else if (licenseP !== '' && licenseP !== 0) {
        if (status) {
          msj = '¡Auto asignado con exito!';
        } else {
          msj = res.error.errorMessage;
        }
      }
      setMessage(msj);
      setDisabledLicense(false);
      dispatch(getMyAssignedVehicle({
        payload: {
          id,
        },
      }));
    });
  };

  useEffect(() => {
    getOrderStatus(async (resOrderS, statusOrderS) => {
      if (statusOrderS) {
        orderStatus(resOrderS.data);
        filterOrderStatus(resOrderS.data);
      }
    });
    dispatch(getMyAssignedVehicle({
      payload: {
        id,
      },
    }));
  }, []);

  useEffect(() => {
    if (assignmentData && assignmentData.idSalePointLocation) {
      handleGetOrders();
    }
  }, [assignmentData]);
  
  useEffect(() => {
    (async function () {
      if (assignmentData && assignmentData.idSalePointLocation) {
        setDisabledLicense(true);
        dispatch(getOrdersByVehicle({
          payload: {
            initDate: null, finalDate: null, vehicle: assignmentData.idSalePointLocation,
          },
        }));
        setDisabledLicense(false);
        setLoad(false);
        setObtainData(true);
      } else {
        await cleanListOrders();
        setLoad(false);
      }
    }());
  }, [assignmentData, tab]);


  useEffect(() => {
    if (message !== 'No tiene auto asignado' && assignmentDataError !== '') {
      setMessage('No tiene auto asignado');
    } else if (message === 'No tiene auto asignado' && assignmentDataError === '') {
      setMessage('');
    }
  }, [assignmentDataError, tab]);

  const handleChangeStatus = (bol, setDisabled) => {
    if (!bol) {
      setQuestion(false);
      setTimeout(() => {
        setDisabled(false);
      }, 1000);
      return;
    }

    postChangeOrderStatus({
      UserId: id,
      OrderNumber: orderNumber,
      NewStatusId: statusId,
      IdSalePointLocation: assignmentData.idSalePointLocation,
      IdCountry: 43,
    }, (res, status) => {
      handleGetOrders();
      setQuestion(false);
      if (status) {
        if (res.error.errorCode === 400) {
          setMessage('Pedido ya se encuentra asignado a otro vendedor');
        } else {
          setMessage('¡Cambiado con exito!');
        }
        // if (assignmentData.idSalePointLocation) {
        // } else {
        //   dispatch(getUnassignedOrders({
        //     payload: {
        //       initDate: null, finalDate: null, vehicle: 0,
        //     },
        //   }));
        // }
      } else {
        setMessage('Error al cambiar estado');
      }
    });
    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  };

  const handleOfflineSale = (orderValue) => {
    setOfflineSale(orderValue, (res, status) => {
      handleGetOrders();
      setMessage(status ? '¡Cambiado con exito!' : 'Error al cambiar modo offline');
    });
  };

  const handleAddComment = (orderValue) => {
    postComment(orderValue, (res, status) => {
      handleGetOrders();
      setMessage(status ? '¡Cambiado con exito!' : 'Error al cambiar modo offline');
    });
  };

  const handleChange = (event, newValue) => {
    setLoad(true);
    setTab(newValue);
    if (newValue === 1) {
      dispatch(getUnassignedOrders({
        payload: {
          initDate: null, finalDate: null, vehicle: 0,
        },
      }));
    }
    setLoad(false);
  };

  const obtainOthersOrders = () => {
    dispatch(getListOrdersOthersVehicles({
      payload: {
        initDate: null, finalDate: null, vehicle: licensePlateOthers,
      },
    }));
    setLoad(false);
    setDisabledLicense(false);
    setDisabledValue(false);
    setObtain(true);
  };

  const validationMap = () => {
    if (listOrders.length > 0 && tab === 0) {
      return true;
    }
    if (unassignedListOrders.length > 0 && tab === 1) {
      return true;
    }
    if (listOrdersOthersVehicles.length > 0 && tab === 2) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (listOrders.length > 0 && productFilter.length === 0) findProducts();
  }, [listOrders, productFilter]);

  const dataEncamino = listOrders.length > 0 ? listOrders.find((order) => order.status === 4) : undefined;
  const dataOnWayOtherVehicle = listOrdersOthersVehicles.length > 0 ? listOrdersOthersVehicles.find((order) => order.status === 4) : [];
  const dataOnWayUnassigned = unassignedListOrders.length > 0 ? unassignedListOrders.find((order) => order.status === 4) : [];
  const vehicleAux = assignmentData && assignmentData.idSalePointLocation && vehicles.find((vehicle) => vehicle.idSalePoint === assignmentData.idSalePointLocation);
  const nameVehicle = vehicleAux && vehicleAux.name ? vehicleAux.name : '';
  const otherVehicleAux = licensePlateOthers !== '' && vehicles.find((vehicle) => vehicle.idSalePoint === licensePlateOthers);
  const disabledClean = dataEncamino || (dataEncamino && dataEncamino.length > 0);

  return (
    <div style={{ marginTop: 50 }}>
      <AppBar position="static" color="default" style={{ marginBottom: 30 }}>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Mi Asignación" />
          <Tab label="Sin Asignación" />
          {/* <Tab label="Otros Autos" /> */}
        </Tabs>
      </AppBar>
      {tab === 0
        && (
        <Grid container>
          <Grid item xs={12}>
            {load || disabledLicense
              ? <SkeletonElem />
              : obtaindata
                ? (
                  <>
                    <Typography variant="h2">
                      Tu auto asignado es:
                      {' '}
                      {nameVehicle}
                    </Typography>
                    <Button
                      disabled={disabledClean}
                      onClick={() => {
                        setObtainData(false);
                        setLoad(true);
                        setDisabledValue(true);
                        setLicensePlate('');
                        cleanListOrders();
                        cleanMyVehicle();
                        assignmentVehicle(0);
                      }}
                      style={{ marginBottom: 20 }}
                      color="primary"
                    >
                      Limpiar
                    </Button>
                  </>
                )
                : (
                  <>
                    <InputLabel>Ingresa la matricula de su auto</InputLabel>
                    <Select
                      key="licenseP"
                      displayEmpty
                      value={licensePlate}
                      style={{ width: '100%' }}
                      inputProps={{ key: 'licensePlate' }}
                      onChange={(event) => { setLicensePlate(event.target.value); setDisabledValue(false); }}
                    >
                      {vehicles.map((licenseP) => (
                        <MenuItem key={`${licenseP.idSalePoint}`} value={licenseP.idSalePoint}>{licenseP.name}</MenuItem>
                      ))}
                    </Select>
                    <div className="row" style={{ justifyContent: 'space-around' }}>
                      <Button disabled={disabledValue} onClick={() => { assignmentVehicle(licensePlate); setDisabledValue(true); }} color="primary">
                        Aceptar
                      </Button>
                      <Button
                        onClick={() => {
                          setObtainData(false);
                          setDisabledValue(true);
                          setLicensePlate('');
                          cleanListOrders();
                        }}
                        color="primary"
                      >
                        Limpiar
                      </Button>
                    </div>
                  </>
                )}
            {
            load
              ? <SkeletonElem />
              : !obtaindata
                ? <IndexNotQuadrant msg="No tienes auto asignado" />
                : assignmentData && assignmentData.idSalePointLocation && listOrders.length < 1
                  ? <IndexEmpty msg="No tienes pedidos" />
                  : dataEncamino
                  && (
                    listOrders.map((orderItem) => {
                      if (orderItem.status === 4) {
                        return (
                          <IndexLastOrder
                            data={orderItem}
                            load={load}
                            setQuestion={setQuestion}
                            history={history}
                            onOfflineSale={handleOfflineSale}
                            onAddComment={handleAddComment}
                            setQuestionGrey={setQuestionGrey}
                            setMessage={setMessage}
                            own
                          />
                        );
                      }
                    })
                  )
          }
          </Grid>
          <Grid item xs={12}>
            {
            load
              ? <SkeletonElem style={{ marginTop: '30px' }} />
              : listOrders.length > 0
              && (
                <IndexListOrder
                  data={listOrders}
                  filterData={filterData}
                  load={load}
                  setQuestion={setQuestion}
                  history={history}
                  onAddComment={handleAddComment}
                  setQuestionGrey={setQuestionGrey}
                  filterRecompra={filterRecompra}
                  filterNuevo={filterNuevo}
                  productFilter={productFilter}
                  own
                />
              )
          }
          </Grid>
        </Grid>
        )}
      {tab === 1
        && (
        <Grid container>
          <Grid item xs={12}>
            {
            load
              ? <SkeletonElem />
              : unassignedListOrders.length < 1
                ? <IndexEmpty msg="No hay pedidos sin asignar" />
                : dataOnWayUnassigned
                && (
                  unassignedListOrders.map((orderItem) => {
                    if (orderItem.status === 4) {
                      return (
                        <IndexLastOrder
                          data={orderItem}
                          load={load}
                          setQuestion={setQuestion}
                          history={history}
                          onOfflineSale={handleOfflineSale}
                          onAddComment={handleAddComment}
                          setQuestionGrey={setQuestionGrey}
                          setMessage={setMessage}
                          own={false}
                        />
                      );
                    }
                  })
                )
          }
          </Grid>
          <Grid item xs={12}>
            {
            load
              ? <SkeletonElem style={{ marginTop: '30px' }} />
              : unassignedListOrders.length > 0
              && (
                <IndexListOrder
                  data={unassignedListOrders}
                  filterData={filterData}
                  load={load}
                  setQuestion={setQuestion}
                  history={history}
                  onAddComment={handleAddComment}
                  setQuestionGrey={setQuestionGrey}
                  filterRecompra={filterRecompra}
                  filterNuevo={filterNuevo}
                  productFilter={productFilter}
                  own={false}
                />
              )
          }
          </Grid>
        </Grid>
        )}
      {tab === 2
        && (
        <Grid container>
          <Grid item xs={12}>
            {load || disabledLicense
              ? <SkeletonElem />
              : licensePlateOthers !== '' && obtain
                ? (
                  <>
                    <Typography variant="h2">
                      Auto:
                      {' '}
                      {otherVehicleAux && otherVehicleAux.name}
                    </Typography>
                    <Button onClick={() => { setDisabledValue(true); setLicensePlateOthers(''); cleanListOrdersOthersVehicles(); setObtain(false); }} style={{ marginBottom: 20 }} color="primary">
                      Limpiar
                    </Button>
                  </>
                )
                : (
                  <>
                    <InputLabel>Ingresa la matricula del auto</InputLabel>
                    <Select
                      key="licenseP"
                      displayEmpty
                      value={licensePlateOthers}
                      style={{ width: '100%' }}
                      inputProps={{ key: 'licensePlateOthers' }}
                      onChange={(event) => { setLicensePlateOthers(event.target.value); setDisabledValue(false); }}
                    >
                      {vehicles.map((licenseP) => (
                        <MenuItem key={`${licenseP.idSalePoint}`} value={licenseP.idSalePoint}>{licenseP.name}</MenuItem>
                      ))}
                    </Select>
                    <div className="row" style={{ justifyContent: 'space-around' }}>
                      <Button disabled={disabledValue} onClick={() => { setDisabledValue(true); setLoad(true); setDisabledLicense(true); obtainOthersOrders(); }} color="primary">
                        Aceptar
                      </Button>
                      <Button
                        onClick={() => {
                          setDisabledValue(true);
                          setLicensePlateOthers('');
                          setObtain(false);
                          cleanListOrdersOthersVehicles();
                        }}
                        color="primary"
                      >
                        Limpiar
                      </Button>
                    </div>
                  </>
                )}
            {
            load
              ? <SkeletonElem />
              : licensePlateOthers === '' || !obtain
                ? <IndexNotQuadrant msg="No tienes auto seleccionado" />
                : listOrdersOthersVehicles.length < 1
                  ? <IndexEmpty msg="El auto no tiene pedidos" />
                  : dataOnWayOtherVehicle
                  && (
                    listOrdersOthersVehicles.map((orderItem) => {
                      if (orderItem.status === 4) {
                        return (
                          <IndexLastOrder
                            data={orderItem}
                            load={load}
                            setQuestion={setQuestion}
                            history={history}
                            onOfflineSale={handleOfflineSale}
                            onAddComment={handleAddComment}
                            setQuestionGrey={setQuestionGrey}
                            setMessage={setMessage}
                            own={false}
                          />
                        );
                      }
                    })
                  )
          }
          </Grid>
          <Grid item xs={12}>
            {
            load
              ? <SkeletonElem style={{ marginTop: '30px' }} />
              : licensePlateOthers !== '' && listOrdersOthersVehicles.length > 0
              && (
                <IndexListOrder
                  data={listOrdersOthersVehicles}
                  filterData={filterData}
                  load={load}
                  setQuestion={setQuestion}
                  history={history}
                  onAddComment={handleAddComment}
                  setQuestionGrey={setQuestionGrey}
                  filterRecompra={filterRecompra}
                  filterNuevo={filterNuevo}
                  productFilter={productFilter}
                  own={false}
                />
              )
          }
          </Grid>
        </Grid>
        )}

      <DialogQuestion
        open={question}
        handleClose={(e, setDisabled) => handleChangeStatus(e, setDisabled)}
      />
      <StatusBarGlobal message={message} setMessage={setMessage} />

      {listOrders.length > 0 && tab === 0 && (
        <Fab variant="extended" color="primary" aria-label="add" className={classes.fab} onClick={() => setView(0)}>
          <MapIcon style={{ marginRight: 5 }} />
          Mapa
        </Fab>
      )}

      {listOrders.length > 0 && tab === 0 && (
        <Fab variant="extended" color="primary" aria-label="add" className={classes.fabFilter} onClick={() => setMenuFilter(true)}>
          <FilterListIcon style={{ marginRight: 5 }} />
          Filtro
        </Fab>
      )}
      <MenuFilter menu={{
        menuFilter,
        setMenuFilter,
        filterData,
        setFilterData,
        setFilterRecompra,
        filterRecompra,
        filterNuevo,
        setFilterNuevo,
        productFilter,
        setProductFilter,
      }}
      />

      <IndexDialogQuestion
        handleGetOrders={handleGetOrders}
        questionGrey={questionGrey}
        setQuestionGrey={setQuestionGrey}
        questionOrderNum={questionOrderNum}
        data={listOrders}
        orderNumber={orderNumber}
        setMessage={setMessage}
        getOrdersByVehicle={getOrdersByVehicle()}
        // disabled={true}
      />
    </div>
  );
};

const SkeletonElem = ({ style }) => (
  <Card style={style}>
    <CardContent>
      <Skeleton animation="wave" variant="rect" width="100%" height={10} style={{ marginBottom: 6 }} />
      <Skeleton animation="wave" variant="rect" width="100%" height={10} style={{ marginBottom: 6 }} />
      <Skeleton animation="wave" variant="rect" width="80%" height={10} style={{ marginBottom: 6 }} />
      <Skeleton animation="wave" variant="rect" width="60%" height={10} style={{ marginBottom: 6 }} />
    </CardContent>
  </Card>
);

const DialogQuestion = ({ open, handleClose }) => {
  const [disabled, setDisabled] = useState(false);
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false, setDisabled)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">¿Estás seguro?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Cambiarás el estado de este pedido
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={disabled}
          onClick={() => {
            setDisabled(true);
            handleClose(false, setDisabled);
          }}
          color="primary"
        >
          Cancelar
        </Button>
        <Button
          disabled={disabled}
          onClick={() => {
            setDisabled(true);
            handleClose(true, setDisabled);
          }}
          color="primary"
          autoFocus
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// const Maping = ({
//   open, handleClose, tab,
// }) => {
//   return (
//     <Modal
//       aria-labelledby="transition-modal-title"
//       aria-describedby="transition-modal-description"
//     // className={classes.modal}
//       open={open}
//       onClose={handleClose}
//       closeAfterTransition
//       BackdropComponent={Backdrop}
//       BackdropProps={{
//         timeout: 500,
//       }}
//     >
//       <Grow in={open}>
//         <div className="MapModal">
//           <IndexMap
//             handleClose={handleClose}
//             tab={tab} />
//         </div>
//       </Grow>
//     </Modal>
//   );
// };


const MenuFilter = ({ menu }) => {
  const {
    menuFilter, setMenuFilter, filterData, setFilterData, filterRecompra, setFilterRecompra,
    setFilterNuevo, filterNuevo, productFilter, setProductFilter,
  } = menu;

  const toggleButton = (id) => {
    const obj = Object.assign([], filterData);
    const key = obj.findIndex((i) => i.id === id);
    obj[key].show = !filterData[key].show;
    setFilterData(obj);
  };
  const filterProduct = (id) => {
    const obj = Object.assign([], productFilter);
    const key = obj.findIndex((i) => i.id === id);
    obj[key].show = !productFilter[key].show;
    setProductFilter(obj);
  };
  return (
    <Popover
      id="menu-filter"
      open={Boolean(menuFilter)}
      anchorEl={menuFilter}
      onClose={() => setMenuFilter(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className="fabFilter-container">
        <Typography variant="h6">Filtro</Typography>
        <ButtonGroup
          orientation="vertical"
          color="primary"
          aria-label="vertical outlined primary button group"
          fullWidth
        >
          {
            filterData.map((i) => i.id !== 4 && <Button variant={i.show && 'contained'} onClick={() => toggleButton(i.id)}>{i.description}</Button>)
          }
        </ButtonGroup>
        <hr />
        <ButtonGroup
          orientation="vertical"
          color="primary"
          aria-label="vertical outlined primary button group"
          fullWidth
        >
          <Button
            onClick={() => setFilterRecompra(!filterRecompra)}
            variant={filterRecompra && 'contained'}
            fullWidth
          >
            Cliente Recompra
          </Button>
        </ButtonGroup>
        <ButtonGroup
          orientation="vertical"
          color="primary"
          aria-label="vertical outlined primary button group"
          fullWidth
        >
          <Button
            onClick={() => setFilterNuevo(!filterNuevo)}
            variant={filterNuevo && 'contained'}
            fullWidth
          >
            Cliente Nuevo
          </Button>
        </ButtonGroup>
        <hr />
        <ButtonGroup
          orientation="vertical"
          color="primary"
          aria-label="vertical outlined primary button group"
          fullWidth
        >
          {
            productFilter.map((i) => <Button variant={i.show && 'contained'} onClick={() => filterProduct(i.id)}>{i.description}</Button>)
          }
        </ButtonGroup>
      </div>
    </Popover>
  );
};

DialogQuestion.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

List.propTypes = {
  id: PropTypes.string.isRequired,
  orderStatus: PropTypes.func.isRequired,
  cleanListOrdersOthersVehicles: PropTypes.func.isRequired,
  cleanListOrders: PropTypes.func.isRequired,
  cleanMyVehicle: PropTypes.func.isRequired,
  orderNumber: PropTypes.number.isRequired,
  statusId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  setView: PropTypes.func.isRequired,
  vehicles: PropTypes.array.isRequired,
  assignmentData: PropTypes.string,
  assignmentDataError: PropTypes.string,
  listOrders: PropTypes.array,
  listOrdersOthersVehicles: PropTypes.array,
  unassignedListOrders: PropTypes.array,
  tab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired,
};

// Maping.propTypes = {
//   open: PropTypes.bool.isRequired,
//   handleClose: PropTypes.func.isRequired,
//   tab: PropTypes.number.isRequired,
// };

MenuFilter.propTypes = {
  menu: PropTypes.array.isRequired,
};

SkeletonElem.propTypes = {
  style: PropTypes.array.isRequired,
};

List.defaultProps = {
  assignmentData: null,
  assignmentDataError: '',
  listOrders: [],
  unassignedListOrders: [],
  listOrdersOthersVehicles: [],
};

const mapStateToProps = (state) => ({
  id: state.session.id,
  orderNumber: state.app.indexViewQuestion.orderNumber,
  statusId: state.app.indexViewQuestion.status,
  vehicles: state.vehicles.vehicles ? state.vehicles.vehicles : [],
  assignmentData: state.myVehicles.assignmentData ? state.myVehicles.assignmentData : null,
  assignmentDataError: state.myVehicles.assignmentDataError,
  listOrders: state.ordersByVehicle.listOrders ? state.ordersByVehicle.listOrders : [],
  unassignedListOrders: state.unassignedOrders.unassignedListOrders ? state.unassignedOrders.unassignedListOrders : [],
  listOrdersOthersVehicles: state.listOrdersOthersVehicles.listOrdersOthersVehicles ? state.listOrdersOthersVehicles.listOrdersOthersVehicles : [],
});


const mapDispatchToProps = (dispatch) => ({
  orderStatus: (payload) => dispatch({ type: 'orderStatus', payload }),
  cleanListOrdersOthersVehicles: () => dispatch({ type: 'CLEAN_LIST_ORDERS_OTHERS_VEHICLE' }),
  cleanListOrders: () => dispatch({ type: 'CLEAN_LIST_ORDERS' }),
  cleanMyVehicle: () => dispatch({ type: 'CLEAN_MY_VEHICLE' }),
});


export default connect(mapStateToProps, mapDispatchToProps)(List);
