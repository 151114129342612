const defaultState = {
  listOrdersOthersVehicles: [],
};

export function listOrdersOthersVehicles(state = defaultState, action) {
  switch (action.type) {
    case "GET_LIST_ORDERS_OTHERS_VEHICLE":
      return { ...state, ...action.payload };
    case "CLEAN_LIST_ORDERS_OTHERS_VEHICLE":
      return { ...state, listOrdersOthersVehicles: [] };

    default:
      return state;
  }
}
