import React from 'react';
import PropTypes from 'prop-types';
import {
  InputLabel, Select, MenuItem, Grid,
} from '@material-ui/core';


const FormField = ({
  field, label, handleChange, selected,
}) => (
  <Grid item sm={6} xs={12} style={{ paddingBottom: '1%' }}>
    <InputLabel>{ label }</InputLabel>
    <Select
      value={selected}
      style={{ width: '100%' }}
      inputProps={{ key: field.key }}
      onChange={(event) => handleChange(field.key, event)}
    >
      {field.options.map((option) => (
        <MenuItem key={`${field.key}${option[field.key]}`} value={option[field.key]}>{option.description}</MenuItem>
      ))}
    </Select>
  </Grid>
);

FormField.propTypes = {
  field: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  selected: PropTypes.number.isRequired,
};

export default FormField;
