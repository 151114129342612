import { apicenter } from "../../../data/apicenter";

export async function setVehicleToVendor(req, callback) {
  try {
    const link = `${apicenter.API}/Vendor/SetVehicleToVendor`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...apicenter.APIHEADER,
    };
    const body = JSON.stringify(req);
    const response = await fetch(link, { method, headers, body });
    const data = await response.json();
    if (data.error.errorCode === 0) {
      if (callback) callback(true, data);
    } else if (callback) callback(false, data);
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("Error POST setVehicleToVendor =>", { req, error });
    callback(false);
    return null;
  }
}

/*
  {
      "OrderNumber": 1441,
      "RBlockId": 10,
      "RSchedulingDate": "2020-06-17",
      "UserId" : 6901
  }
*/
