import React from 'react';
import PropTypes from 'prop-types';
import { amber, purple } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import TransformIcon from '@material-ui/icons/Transform';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import CardFloatIcon from '../../ui/Card/CardFloatIcon';
import LoadingLines from '../../globals/LoadingLines';
import { Fn } from '../../utils/Fn';


const NumberCards = ({ indicators, loading }) => {
  const indicatorsData = [{
    name: 'Clientes referidos',
    number: indicators !== undefined && indicators.data !== null ? indicators.data.refClientCount : 0,
    icon: PersonAddRoundedIcon,
    primaryColor: '#00B7BD',
    secondaryColor: '#8DD4BA',
  },
  {
    name: 'Clientes Totales',
    number: indicators !== undefined && indicators.data !== null ? indicators.data.totalUnicClientCount : 0,
    icon: TransformIcon,
    primaryColor: purple[300],
    secondaryColor: purple[200],
  },
  {
    name: 'Ventas totales',
    number: indicators !== undefined && indicators.data !== null ? `$${Fn.separadorMiles(Math.trunc(indicators.data.totalSalesAmount))}` : 0,
    icon: MonetizationOnIcon,
    primaryColor: amber[400],
    secondaryColor: amber[200],
  }];
  
  const cardIndicator = (ind) => (
    <Grid item xs={12} sm={4} style={{ marginBottom: '5%' }}>
      <Card className="CardFloatIcon-contain">
        <CardFloatIcon
          icon={ind.icon}
          iconStyles={{ color: 'white' }}
          background={[ind.primaryColor, ind.secondaryColor]}
        />
        <CardContent style={{ textAlign: 'center' }}>
          {!loading && (
          <>
            <Typography variant="h6" style={{ opacity: 0.4, textAlign: 'right' }}>{ind.name}</Typography>
            <hr />
            <Typography variant="h2">
              {`${ind.number}`}
            </Typography>
          </>
          )}
          {loading && (
            <LoadingLines />
          )}
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <>
      {indicatorsData.map((ind) => cardIndicator(ind))}
    </>

  );
};

NumberCards.propTypes = {
  indicators: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};


export { NumberCards };
