import { apicenter } from "../../../data/apicenter";

export function getPitPoints(callback) {
  const link = `${apicenter.API}/vendor/GetVendorRestPoint/`;
  const method = "GET";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...apicenter.APIHEADER,
  };

  fetch(link, { method, headers })
    .then((res) => res.json())
    .then((res) => {
      callback(res, true);
    })
    .catch((error) => {
      callback(error, false);
    });
}
