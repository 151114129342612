import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import RoundedIconButton from '../../globals/Buttons/RoundIconButton';

const useStyles = makeStyles((theme) => ({
  table: {
    maxHeight: '72vh',
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderColor: theme.palette.primary.main,
    border: '1px solid',
  },
  iconButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    boxShadow: theme.shadows[1],
  },

}));

function PromosClient({ clients, onView }) {
  const classes = useStyles();
  const headers = ['Nombre', 'Agregar Promo'];

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table>
        <TableHead>
          <TableRow>
            {
              headers.map((text) => (
                <TableCell className={classes.head} key={text}>{text}</TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {clients.map((client) => (
            <TableRow key={client.userId}>
              <TableCell component="th" scope="client">
                <Typography style={{ fontWeight: 'bold' }}>{client.client}</Typography>
                <Typography>{client.email}</Typography>
              </TableCell>
              <TableCell align="center">
                <RoundedIconButton size="small" onClick={() => onView(client)}>
                  <LoyaltyIcon fontSize="small" />
                </RoundedIconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


PromosClient.propTypes = {
  clients: PropTypes.array.isRequired,
  onView: PropTypes.func.isRequired,
};

export default PromosClient;
