/* eslint no-unused-expressions: [2, { allowShortCircuit: true }] */
/*
    Ejemplo => https://tricicloapidev.azurewebsites.net/api/Schedule/GetBlockByDateZone/${date}/${idZone}
*/

import { apicenter } from '../../../../data/apicenter';

export async function getBlockByDateZone(date, idZone, idProvider) {
  try {
    const link = `${apicenter.API}/Schedule/GetBlockByDateZone/${date}/${idZone}/${idProvider}`;
    const method = 'GET';
    const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };
    const response = await fetch(link, { method, headers });
    const data = await response.json();
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error POST postSetReschedule =>', { date, idZone });
    return null;
  }
}
