import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { isEmpty } from 'lodash';
import IconText from '../../components/globals/IconText';
import { getPrice, getOrderPrice, isNewPack } from './validations';


function ResultCard({
  isValidSale, loadingSale, token, onSale, showToken, data, sale,
}) {
  const validContent = isValidSale && (
    <>
      <IconText text="Venta válida" iconType="SUCCESS" />
      {showToken ? (
        <>
          <Typography variant="body1">Ingresa el siguiente código en el dispensador</Typography>
          <Typography variant="h2">{`${token}`}</Typography>
        </>
      ) : (
        <>
          <Typography variant="body1">Presiona el botón para cobrar la venta al usuario. Luego se mostrará un código para que sea ingresado en el dispensador</Typography>
          <Button color="primary" variant="contained" disabled={loadingSale} onClick={onSale}>Realizar venta</Button>
        </>
      )}
    </>
  );
  const invalidContent = (
    <IconText text="Venta inválida" iconType="ERROR" />
  );
  let salePrice = 0;
  let newPack = true;
  if (!isEmpty(data.product)) {
    if (isEmpty(data.order)) {
      salePrice = getPrice(data.pack, data.product, sale);
      newPack = isNewPack(data.pack);
    } else {
      salePrice = getOrderPrice(data.order, data.product, sale);
      newPack = data.order.isNewPackage;
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h2">Resultado</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ width: '100%' }}>
          <CardContent>
            <IconText text={`Venta total de $ ${salePrice}`} iconType="MONEY" />
            {newPack ? <IconText text="Envase nuevo" iconType="NEW" /> : <IconText text="Envase reutilizado" iconType="RECYCLE" />}
            <hr />
            {isValidSale ? validContent : invalidContent}
          </CardContent>
        </Paper>
      </Grid>
    </>
  );
}

ResultCard.propTypes = {
  isValidSale: PropTypes.bool.isRequired,
  loadingSale: PropTypes.bool.isRequired,
  showToken: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  sale: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  onSale: PropTypes.func.isRequired,
};


export default ResultCard;
