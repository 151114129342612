import React, { useState } from 'react';
import {
  Modal, Backdrop, Fade, makeStyles, Grid, Button, CircularProgress,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { getChargeBill } from '../../components/utils/api/getChargeBill';
import { Fn } from '../../components/utils/Fn';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const SeeClientModal = ({
  open, info, closeModal, handleObtainAll, id, setMessage,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleCharge = () => {
    setLoading(true);
    getChargeBill({
      userId: info.userId,
      vendorId: id,
      credit: info.credits,
      bill: info.bill,
    }, (res, status) => {
      if (status) {
        handleObtainAll();
        setMessage('Carga de saldo exitosa.');
        closeModal();
      }
      setLoading(false);
    });
  };

  return (
    <Modal
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">Confirmar carga de saldo</h2>
          <p id="transition-modal-description">
            Cuenta:
            {info.client}
          </p>
          <p id="transition-modal-description">
            Monto a cargar: $
            {Fn.separadorMiles(info.credits)}
          </p>
          <p id="transition-modal-description">
            Boleta número:
            {info.bill}
          </p>
          <Grid style={{ width: '100%' }}>
            <Button onClick={closeModal} variant="contained" style={{ marginRight: 20 }}>CANCELAR</Button>
            {loading ? (
              <CircularProgress
                size="small"
                style={{ marginLeft: 20 }}
              />
            )
              : (<Button disabled={loading} onClick={() => handleCharge()} variant="contained" color="primary">ACEPTAR</Button>)}
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  id: state.session.id,
});

const mapDispatchToProps = (dispatch) => ({
  setMessage: (message) => dispatch({ type: 'ADD_STATUS', message }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeeClientModal);
