const defaultState = {
  editClient: null,
  chargeBill: null,
  orderStatus: null,
  indexViewQuestion: {
    orderNumber: 0,
    status: 0,
  },
  failedAnyFetch: {
    show: false,
    message: '',
  },
};

export function app(state = defaultState, { payload, type }) {
  switch (type) {
    case 'editClient':
      return { ...state, editClient: payload };
    case 'chargeBill':
      return { ...state, chargeBill: payload };
    case 'orderStatus':
      return { ...state, orderStatus: payload };
    case 'indexViewQuestion':
      return { ...state, indexViewQuestion: payload };
    case 'FAILED_ANY_FETCH':
      return { ...state, failedAnyFetch: { show: true, message: payload } };
    case 'HIDE_FAILED_ANY_FETCH':
      return { ...state, failedAnyFetch: { show: false, message: '' } };
    default:
      return state;
  }
}
