import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {
  Grid, CircularProgress, makeStyles, Button,
} from '@material-ui/core';
import UserCard from './Components/UserCard';
import PackCard from './Components/PackCard';
import ValidationCard from './Components/ValidationCard';
import { getClient } from '../../components/utils/api/getClient';
import { getAllProductForSale } from '../../components/utils/api/getAllProductForSale';
import { postSaleInvoice } from '../../components/utils/api/postSaleInvoice';
import { postInsertSale } from '../../components/utils/api/postInsertSale';

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
}));

const View = ({ app, vendorId, setStatus }) => {
  const history = useHistory();
  const classes = useStyles();
  const { clientId } = useParams();
  const [user, setUser] = useState({});
  const [userPack, setUserPack] = useState([]);
  const [loading, setLoading] = useState(false);
  const [carts, setCarts] = useState([]);
  const [errorEndpoint] = useState('');
  const [products, setProducts] = useState();
  const [productSelect, setProductSelect] = useState([]);
  const [salePackageDTO, setSalePackageDTO] = useState([]);
  const [packageSelect, setPackageSelect] = useState([]);
  const [saleGiftPackage, setSaleGiftPackage] = useState([]);
  const [giftPackageSelect, setGiftPackageSelect] = useState([]);
  const [view, setView] = useState(0);

  const orderPackages = (packagesGroup) => {
    const packagesByProduct = [];
    packagesGroup.map((pckges, index) => {
      const count = pckges.length;
      const product = pckges[0];
      packagesByProduct.push({
        alias: product.alias,
        idPackage: product.idPackage,
        prodId: product.prodId,
        userId: product.userId,
        vol: product.vol,
        quantity: count,
      });
    });
    setUserPack(packagesByProduct);
  };

  useEffect(() => {
    setLoading(true);
    getClient(clientId, (res, status) => {
      if (status) {
        setUser(res.data.clientData);
        orderPackages(res.data.packagesGroup);
      }
    });
    getAllProductForSale((res, status) => {
      if (status) {
        setProducts(res.data.saleProductsDTO);
        setSalePackageDTO(res.data.salePackageDTO.map((i) => {
          i.count = 1;
          return i;
        }));
        setSaleGiftPackage(res.data.saleGiftPackage);
      }
    });
    setLoading(false);
  }, []);

  const onSale = (receipt) => {
    const aux = [];
    aux.push({
      saleProductsDTO: carts.saleProductsDTO,
      salePackageDTO: carts.salePackageDTO,
      saleGiftPackage: carts.saleGiftPackage,
      UserId: carts.userId,
      VendorId: carts.vendorId,
      receipt,
    });

    const req = aux[0];
    postInsertSale(req, (res, status, error) => {
      if (status) {
        setStatus(status ? 'Compra realizada  con éxito!' : error);
        setTimeout(() => {
          history.push('/sales');
        }, 1000);
      }
    });
  };


  const update = () => {
    const aux = [];
    let auxPackageSelect = false;
    let auxGiftPackageSelect = false;
    if (packageSelect.length > 0) {
      packageSelect.map((pack) => {
        if (pack.idPackage === undefined) {
          auxPackageSelect = true;
        }
      });
    }

    if (giftPackageSelect.length > 0) {
      giftPackageSelect.map((pack) => {
        if (pack.idPackage === undefined) {
          auxGiftPackageSelect = true;
        }
      });
    }
    aux.push({
      saleProductsDTO: productSelect,
      salePackageDTO: auxPackageSelect ? [] : packageSelect,
      saleGiftPackage: auxGiftPackageSelect ? [] : giftPackageSelect,
      UserId: clientId,
      VendorId: vendorId,
    });
    const req = aux[0];
    postSaleInvoice(req, (res, status) => {
      if (status) {
        setCarts(res.data);
        setView(1);
      }
    });
  };

  const validationContinue = () => {
    let condition = true;
    if (productSelect.length > 0) {
      productSelect.map((prod) => {
        if (prod.count > 0) {
          condition = false;
        }
      });
      return condition;
    }
    return condition;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ marginBottom: '3%' }}>
        <Typography variant="h2">Información de la venta</Typography>
      </Grid>
      {loading
        ? (
          <CircularProgress style={{ marginLeft: 100 }} />
        )
        : (
          <>
            {errorEndpoint ? (
              <span name="Packages" component="span" className={classes.error}>{errorEndpoint}</span>
            )
              : (
                <>
                  <UserCard user={user} userPack={userPack} />
                  {view === 0 && (
                    <>
                      <PackCard
                        title="Productos"
                        subtitle="Seleccione los productos que lleva"
                        par="productos que lleva"
                        products={products}
                        setSelectedList={setProductSelect}
                        selectedList={productSelect}
                      />
                      <PackCard
                        title="Envases Retornables"
                        subtitle="Seleccione los envases que retorna"
                        par="envases que retorna"
                        products={salePackageDTO}
                        setSelectedList={setPackageSelect}
                        selectedList={packageSelect}
                      />
                      <PackCard
                        title="Envases Regalados"
                        subtitle="Seleccione los envases que regala"
                        par="envases que regala"
                        products={saleGiftPackage}
                        setSelectedList={setGiftPackageSelect}
                        selectedList={giftPackageSelect}
                      />
                      <Button
                        onClick={() => update()}
                        disabled={validationContinue()}
                        variant="contained"
                        color="primary"
                        style={{ marginBottom: 20, marginLeft: 20 }}
                      >
                        Continuar
                      </Button>
                    </>
                  )}
                  {view === 1 && (
                    <ValidationCard
                      carts={carts}
                      onSale={onSale}
                      clientId={clientId}
                      history={history}
                    />
                  )}
                </>
              )}
          </>
        )}
    </Grid>
  );
};


View.propTypes = {
  vendorId: PropTypes.string.isRequired,
  app: PropTypes.object.isRequired,
  setStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
  vendorId: state.session.info.userId,
});

const mapDispatchToProps = (dispatch) => ({
  setStatus: (message) => dispatch({ type: 'ADD_STATUS', message }),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
