import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardContent, Input, Grid, Button, InputLabel, withStyles,
} from '@material-ui/core';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/app';

const styles = (theme) => ({
  error: {
    color: theme.palette.error.main,
  },
});

class SeeClientAmmount extends Component {
  render() {
    const { classes, chargeBill, data } = this.props;
    return (
      <Card style={{ width: '100%' }}>
        <CardContent>
          <Formik
            initialValues={{
              ammount: '',
              num: '',
            }}
            validate={(values) => {
              const errors = {};
              if (!values.ammount) {
                errors.ammount = 'Este campo es requerido';
              } else if (!values.num) {
                errors.num = 'Este campo es requerido';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                chargeBill({
                  credits: values.ammount,
                  bill: values.num,
                });
                data.openModal();
                setSubmitting(false);
                resetForm({});
              }, 400);
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ width: '100%', padding: 20 }}>
                <Grid container spacing={2}>
                  <Grid container item xs={6} spacing={2}>
                    <Grid container item xs={12} spacing={2}>
                      <InputLabel>Monto a cargar</InputLabel>
                      <Field type="text" name="ammount" as={Input} style={{ width: '100%' }} />
                      <ErrorMessage name="ammount" component="span" className={classes.error} />
                    </Grid>
                    <Grid container item xs={12} spacing={2} style={{ marginTop: 40 }}>
                      <InputLabel>Número de boleta</InputLabel>
                      <Field type="text" name="num" as={Input} style={{ width: '100%' }} />
                      <ErrorMessage name="num" component="div" className={classes.error} />
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} spacing={2} justifyContent="flex-end" alignItems="flex-end">
                    <Button type="submit" disabled={isSubmitting} variant="contained" color="primary">CARGAR</Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    );
  }
}

SeeClientAmmount.propTypes = {
  classes: PropTypes.object.isRequired,
  chargeBill: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
});

export default connect(mapStateToProps, actions)(withStyles(styles)(SeeClientAmmount));
