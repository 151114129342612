import { all } from "redux-saga/effects";
import orders from "./orders";
import vehicle from "./vehicle";
import myVehicle from "./myVehicle";
import ordersByVehicle from "./ordersByVehicle";
import unassignedOrders from "./unassignedOrders";
import listOrdersOthersVehicles from "./listOrdersOthersVehicles";

export default function* rootSaga() {
  yield all([
    orders(),
    vehicle(),
    myVehicle(),
    ordersByVehicle(),
    unassignedOrders(),
    listOrdersOthersVehicles(),
  ]);
}
