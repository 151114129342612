import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Add from '@material-ui/icons/Add';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/app';
import SearchBar from '../globals/Inputs/SearchBar';
import RoundedIconButton from '../globals/Buttons/RoundIconButton';
import { obtainLanguage } from '../../data/lang/obtainLanguage';
import ClientTable from './ClientsComponents/Table';
import { getClientSearch } from '../utils/api/getClientSearch';

function ClientsView(props) {
  const [text, setText] = useState('');
  const [textTemp, setTextTemp] = useState('');
  const [textSearch, setTextSearch] = useState('');
  const [list, setList] = useState([]);
  const [searching, setSearching] = useState(false);

  const HandleObtainClient = (valueText = '') => {
    console.debug("a buscar:", valueText, ", text:", text, ", textTemp:", textTemp, ", searching:", searching);
    setTextSearch(valueText);
    if( (valueText.length < 3 && valueText.length != 0) || searching) return;
    setTextTemp(valueText);
    setSearching(true);
    getClientSearch(valueText, (res, status) => {
      if (status) {
        setList(res.data || []);
      } else {
        setList([]);
      }
      setSearching(false);
    });
  };

  useEffect(() => {
    HandleObtainClient();
  }, []);

  useEffect(() => {
    if(textSearch != textTemp && ! searching){
      HandleObtainClient(textSearch);
    }
  }, [searching]);

  const handleView = (client) => {
    props.history.push(`client/${client.userId}`);
  };

  const handleAddClient = () => {
    props.history.push('createClient');
  };

  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography variant="h2">{obtainLanguage.clients.title}</Typography>
        <RoundedIconButton onClick={handleAddClient}>
          <Add />
        </RoundedIconButton>
      </Grid>
      <SearchBar
        onChange={(value) => {
          HandleObtainClient(value);
        }}
        text={text}
        setText={setText}
        loading={searching}
      />
      <Paper style={{ marginBottom: 100 }}>
        <ClientTable clients={list} onView={handleView} />
      </Paper>

    </div>
  );
}

ClientsView.propTypes = {
  history: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
});

export default connect(mapStateToProps, actions)(ClientsView);
