/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  Switch,
  FormControlLabel,
  TextField,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Alert from '@material-ui/lab/Alert';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import { Fn } from '../../components/utils/Fn';
import { postClientEdit } from '../../components/utils/api/postClientEdit';
import StatusBarGlobal from '../../components/globals/StatusBar/StatusBarGlobal';

function SeeClientInfo(props) {
  const { clientInfo: data, handleObtainInfoClient } = props.data;
  const [edit, setEdit] = useState(false);
  const [message, setMessage] = useState('');

  return (
    <Card style={{ width: '100%', padding: 10 }}>
      <CardContent>
        <Grid container spacing={1} style={{ position: 'relative' }}>
          {!edit ? (
            <ShowClient data={data} message={message} setMessage={setMessage} />
          ) : (
            <EditClient
              data={data}
              setEdit={setEdit}
              setMessage={setMessage}
              handleObtainInfoClient={handleObtainInfoClient}
            />
          )}
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <FormControlLabel
              control={(
                <Switch
                  checked={edit}
                  onChange={(e) => setEdit(e.target.checked)}
                  name="checkedEit"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              )}
              label="Editar"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
const ShowClient = ({ data, message, setMessage }) => {
  const {
    name,
    lastName,
    email,
    credits,
    rut,
    birthDate,
    phone,
    imagesrc,
    favoriteAddress,
  } = data;
  return (
    <>
      <Grid
        item
        xs={10}
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Avatar alt={name} src={imagesrc} style={{ marginRight: 10 }} />
        <Typography variant="h5">
          <b>
            {name} {lastName}
          </b>
        </Typography>
      </Grid>
      {/* <Grid item xs={2} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      </Grid> */}
      <Grid item xs={12}>
        <Typography variant="caption">Correo</Typography>
        <Typography variant="body1">
          <b>{email}</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">Saldo actual</Typography>
        <Typography variant="body1">
          <b>$ {Fn.separadorMiles(credits)}</b>
        </Typography>
      </Grid>
      {phone && (
        <Grid item xs={12}>
          <Typography variant="caption">Teléfono</Typography>
          <Typography variant="body1">
            <b>{phone}</b>
          </Typography>
        </Grid>
      )}
      {rut && (
        <Grid item xs={12}>
          <Typography variant="caption">Rut</Typography>
          <Typography variant="body1">
            <b>{rut}</b>
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography variant="caption">Fecha nacimiento</Typography>
        <Typography variant="body1">
          <b>{moment(birthDate).format('DD-MM-YYYY')}</b>
        </Typography>
      </Grid>
      {favoriteAddress && (
        <Grid item xs={12}>
          <Typography variant="caption">Dirección Favorita</Typography>
          <Typography variant="body1">
            <b>{favoriteAddress.address1}</b>
          </Typography>
        </Grid>
      )}
      <StatusBarGlobal message={message} setMessage={setMessage} />
    </>
  );
};

const EditClient = ({
  data, setEdit, handleObtainInfoClient, setMessage,
}) => {
  const [formEdit, setFormEdit] = useState({
    name: false,
    lastName: false,
    phone: false,
    rut: false,
    birthDate: false,
  });

  const isActiveEdit = !!Object.values(formEdit).find((i) => i === true);

  function HandleChangeEdit(event) {
    const { name } = event.currentTarget.attributes;
    setFormEdit((prevState) => ({ ...prevState, [name.value]: true }));
  }

  function HandleChangeOffEdit(name, setFieldValue) {
    setFieldValue(
      name,
      name === 'birthDate'
        ? moment(data.birthDate).format('YYYY-MM-DD')
        : data[name],
    );
    setFormEdit((prevState) => ({ ...prevState, [name]: false }));
  }

  const labels = {
    name: 'Nombre',
    lastName: 'Apellido',
    phone: 'Teléfono',
    rut: 'Rut',
    birthDate: 'Fecha nacimiento',
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: data.name,
          lastName: data.lastName,
          phone: data.phone,
          rut: data.rut,
          birthDate: moment(data.birthDate).format('YYYY-MM-DD'),
        }}
        validate={(values) => {
          const errors = {};
          if (formEdit.name && !values.name) {
            errors.name = 'Este campo debes rellenar';
          } else if (formEdit.lastName && !values.lastName) {
            errors.lastName = 'Este campo debes rellenar';
          } else if (formEdit.phone && !values.phone) {
            errors.phone = 'Este campo debes rellenar';
          } else if (formEdit.phone && values.phone.length !== 9) {
            errors.phone = 'Ingresa un número válido';
          } else if (formEdit.phone && values.phone.includes('+56')) {
            errors.phone = 'Ya viene ingresado el +56';
          } else if (formEdit.rut && values.rut.length < 1) {
            errors.rut = 'Este campo debes rellenar';
          } else if (formEdit.rut && !Fn.validaRut(values.rut)) {
            errors.rut = 'Ingresa un rut válido, sin puntos y con guion';
          } else if (formEdit.birthDate && values.birthDate.length < 1) {
            errors.birthDate = 'Este campo debes rellenar';
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          const send = {};

          Object.keys(values).map((i) => {
            if (formEdit[i]) {
              if (i === 'rut') {
                send.rut = values.rut.replace('-', '');
              } else if (i === 'birthDate') {
                send.birthDate = moment(values.birthDate).format('YYYY/MM/DD');
              } else {
                send[i] = values[i];
              }
            }
          });

          send.UserId = data.userId;
          send.email = data.email;

          postClientEdit(send, (res, status, err) => {
            if (!status) {
              setFieldError('post', err);
            } else {
              setEdit(false);
              handleObtainInfoClient(data.userId);
              setMessage('¡Cliente actualizado con éxito!');
            }
            setSubmitting(false);
          });
        }}
      >
        {({
          isSubmitting, errors, values, setFieldValue,
        }) => (
          <Form>
            <Grid container spacing={3}>
              {Object.keys(values).map((i) => (
                <Grid item xs={10} lg={6}>
                  {!formEdit[i] ? (
                    <EditClientElem
                      label={labels[i]}
                      name={i}
                      value={values[i]}
                      onClick={HandleChangeEdit}
                    />
                  ) : (
                    <>
                      <Grid container>
                        <Grid item xs={10}>
                          <Field
                            type={i === 'birthDate' ? 'date' : 'text'}
                            label={labels[i]}
                            name={i}
                            as={TextField}
                            variant="filled"
                            style={{ marginBottom: 20, width: '100%' }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            paddingBottom: 20,
                          }}
                        >
                          <HighlightOffIcon
                            onClick={() => HandleChangeOffEdit(i, setFieldValue)}
                          />
                        </Grid>
                      </Grid>
                      <ErrorMessage
                        name={i}
                        component="div"
                        style={{ marginBottom: 20, color: 'red' }}
                      />
                    </>
                  )}
                </Grid>
              ))}
              <Grid item xs={10} lg={6}>
                {errors.post && (
                <Alert severity="error" style={{ marginBottom: 20 }}>
                  {errors.post}
                </Alert>
                )}
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isActiveEdit ? isSubmitting : true}
                >
                  Actualizar perfil
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const EditClientElem = (props) => (
  <div {...props}>
    <label>{props.label}</label>
    <Typography variant="h6">
      {props.value} <EditIcon />
    </Typography>
  </div>
);

SeeClientInfo.propTypes = {
  data: PropTypes.any.isRequired,
};

ShowClient.propTypes = {
  data: PropTypes.object.isRequired,
};

EditClient.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SeeClientInfo;
