const defaultState = {
  unassignedListOrders: [],
};

export function unassignedOrders(state = defaultState, action) {
  switch (action.type) {
    case "GET_UNASSIGNED_ORDERS":
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
