import { takeLatest, put } from "redux-saga/effects";
import { getOrdersVehicle } from "./orders/getOrdersByVehicle";

function* obtainListOrdersOthersVehicles({ payload }) {
  try {
    const result = yield getOrdersVehicle(payload);
    if (result.data.error.errorCode !== 0) {
      if (result.data.error.errorCode === 1) {
        yield put({
          type: "GET_LIST_ORDERS_OTHERS_VEHICLE",
          payload: {
            listOrdersOthersVehicles: [],
          },
        });
      } else {
        yield put({
          type: "FAILED_ANY_FETCH",
          payload: "Error al obtener ordenes",
        });
      }
    } else {
      yield put({
        type: "GET_LIST_ORDERS_OTHERS_VEHICLE",
        payload: {
          listOrdersOthersVehicles: result.data.data,
        },
      });
    }
  } catch (error) {
    yield put({
      type: "FAILED_ANY_FETCH",
      payload: "Error al obtener ordenes",
    });
    console.log("Error GET_LIST_ORDERS_OTHERS_VEHICLE", { error, payload });
  }
}

export default function* getListOrdersOthersVehicles() {
  yield takeLatest(
    "LAUNCH_GET_LIST_ORDERS_OTHERS_VEHICLES",
    obtainListOrdersOthersVehicles
  );
}
