import React from 'react';
import 'bootstrap/dist/css/bootstrap-grid.css';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './redux/store';
import Layout from './layouts/Layout';
import theme from './data/theme';
import './App.css';

const { persistor, store } = configureStore();

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <HashRouter basename="/">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Layout />
          </PersistGate>
        </Provider>
      </HashRouter>
    </MuiThemeProvider>
  );
}

export default App;
