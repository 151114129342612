
import { apicenter } from '../../../data/apicenter';

const postCheck = async (user, check, packages, comment, dispensers, tricycle, callback) => {
  const link = `${apicenter.API}/Check/Insert`;
  const req = {
    UserId: user, IdSalePoint: check.idSalePoint, IdCheckType: check.idCheckType, idPos: check.idPos, Comment: comment || '',
  };
  req.CheckDetail = Object.entries(dispensers).map(([k, v]) => {
    const dispAnsw = { ...v };
    dispAnsw.idCheckResultDetail = dispAnsw.idCheckResultDetail.join(',');
    return { ...dispAnsw, IdDispenser: k };
  });
  req.CheckPackageDetail = Object.entries(packages).map((entry) => {
    const [k, v] = entry;
    return { IdCheckPackage: k, Quantity: v };
  });
  req.CheckSalePoint = tricycle.Kms === undefined ? [] : [tricycle];

  const method = 'POST';
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };
  const body = JSON.stringify(req);

  fetch(link, { method, headers, body })
    .then((res) => res.json())
    .then((res) => {
      if (res.error.errorCode === 0) {
        callback(true, '');
      } else {
        callback(false, res.error.errorMessage);
      }
    })
    .catch(() => {
      callback(false, 'Error en la consulta');
    });
};

export default postCheck;
