import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardContent, Select, Button, InputLabel, MenuItem,
} from '@material-ui/core';

const Coupon = ({ data }) => {
  const {
    coupons, onCouponSelect, onCouponSend, couponId, loading,
  } = data;

  const couponDescription = couponId > 0 && (
  <p>{coupons.find((c) => c.idPromo === couponId).description}</p>
  );
  const noCouponsView = (
    <p>
      No hay cupones disponibles.
      Por favor contactarse con ventas y cobranza para agregar cupones.
    </p>
  );
  const coupView = (
    <Card style={{ width: '100%' }}>
      <CardContent>
        <InputLabel>Escoge un cupón</InputLabel>
        <Select
          key="idSpt"
          displayEmpty
          value={couponId}
          style={{ width: '100%' }}
          inputProps={{ key: 'idPromo' }}
          onChange={(event) => onCouponSelect(event.target.value)}
        >
          {coupons.map((coup) => (
            <MenuItem key={coup.idPromo} value={coup.idPromo}>{coup.code}</MenuItem>
          ))}
        </Select>
        {couponDescription}
        <Button onClick={onCouponSend} disabled={loading} color="primary" variant="contained" style={{ marginTop: 10 }}>Ingresar</Button>
        <p>
          (*) Solo se pueden usar cupones de carga de saldo. Los cupones que sean
          específicos sobre la venta o compra de un producto deben ser ingresados por la app móvil.
        </p>
      </CardContent>
    </Card>
  );
  return coupons.length ? coupView : noCouponsView;
};

Coupon.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Coupon;
