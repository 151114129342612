/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DispenserForm from './DispenserForm';
import PackagesForm from './PackagesForm';
import getFormFields from './utils';
import { getCheckInfo } from '../../utils/api/getCheck';


function CheckContainer({ storeId, onSubmit, onPrev }) {
  const [check, setCheck] = useState({ dispensers: [], package: [] });
  useEffect(() => {
    getCheckInfo(storeId, (res, status) => {
      if (status) setCheck(res.data);
    });
  }, []);

  const { dispensers } = check;

  const [index, setIndex] = useState(0);
  const [dispResp, setDispResp] = useState({});

  const handleNext = () => {
    const n = index === dispensers.length ? 0 : index + 1;
    setIndex(n);
  };
  const handlePrev = () => {
    if (index === 0) {
      onPrev();
    } else {
      setIndex(index - 1);
    }
  };

  const formFields = getFormFields(check);
  const handleDispSubmit = (dispId) => (values) => {
    setDispResp({ ...dispResp, [dispId]: values });
  };

  const buttonsProps = {
    index, onNext: handleNext, onPrev: handlePrev, length: dispensers.length,
  };

  const view = (
    <CardContent>
      {dispensers.map((disp, i) => (
        i === index
        && (
          <DispenserForm
            key={`d${disp.id}`}
            onSubmit={handleDispSubmit(disp.id)}
            dispenser={disp}
            initialVals={dispResp[disp.id]}
            fields={formFields}
            buttonProps={{ ...buttonsProps }}
          />
        )
      ))}
      {index === dispensers.length && (
      <div>
        <Typography variant="h4">Conteo de Envases</Typography>
        <PackagesForm
          packages={check.package}
          onSubmit={(values) => onSubmit(values, dispResp)}
          onPrev={handlePrev}
        />
      </div>
      )}
    </CardContent>
  );

  return (
    dispensers.length > 0 && view
  );
}

CheckContainer.propTypes = {
  storeId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
};


export default CheckContainer;
