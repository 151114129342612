import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';


const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    boxShadow: theme.shadows[2],
  },
}));


function BottomButtons({
  onNext, onPrev, onSubmit, dispenserName, dispenserDesc,
}) {
  const classes = useStyles();
  return (
    <Grid item container xs={12} sm={12} direction="row-reverse" justifyContent="space-between" style={{ paddingTop: '0', paddingBottom: '3%' }}>
      <IconButton onClick={() => { onNext(); onSubmit(); }} color="default" className={classes.iconButton}>
        <ChevronRight />
      </IconButton>
      <Hidden smUp>
        <div>
          <div>
            <Typography variant="caption" style={{ fontWeight: 'bold' }}>{dispenserName}</Typography>
          </div>
          <Typography variant="caption">{dispenserDesc}</Typography>
        </div>
      </Hidden>
      <IconButton onClick={() => { onPrev(); onSubmit(); }} color="default" className={classes.iconButton}>
        <ChevronLeft />
      </IconButton>
    </Grid>
  );
}

BottomButtons.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dispenserName: PropTypes.string,
  dispenserDesc: PropTypes.string,
};

BottomButtons.defaultProps = {
  dispenserDesc: '',
  dispenserName: '',
};

export default BottomButtons;
