import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Fn } from '../../../components/utils/Fn';


function UserCard({ user, userPack }) {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4">Datos usuario</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ marginBottom: 10 }}>
          <CardContent>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td>
                    <Typography variant="body1">Nombre</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`${capitalize(user.name)} ${capitalize(user.lastName)}`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">Saldo</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`$ ${Fn.separadorMiles(user.credits)}`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">Mail</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`${user.email}`}</Typography>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Typography variant="body1">Rut</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`${user.rut}`}</Typography>
                  </td>
                </tr> 
                <tr>
                  <td>
                    <Typography variant="body1">Teléfono</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`${user.phone}`}</Typography>
                  </td>
                </tr>
                {userPack.length > 0
                  && (
                  <tr>
                    <td>
                      <Typography variant="body1">{`Envase(s) `}</Typography>
                    </td>
                    <Typography variant="body1">Productos</Typography>
                    <td />
                  </tr>
                  )}
                {userPack.length > 0 && userPack.map((pckge) => (
                  <tr key={pckge.prodId}>
                    <td>
                      <Typography variant="body1">{pckge.quantity}</Typography>
                    </td>
                    <Typography variant="body1">{pckge.alias}</Typography>
                    <td />
                  </tr>
                ))}
              </tbody>
            </table>
          </CardContent>
        </Paper>
      </Grid>
    </>
  );
}

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
  userPack: PropTypes.array.isRequired,
};

export default UserCard;
