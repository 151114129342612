import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper, Grid, CardContent, Typography, Button, InputLabel, Input,
} from '@material-ui/core/';
import ConfirmSaleModal from './ConfirmSaleModal';


function ValidationCard({ carts, onSale, }) {
  const [receipt, setReceipt] = useState('');
  const [open, setOpen] = useState(false);

  const onClick = () => {
    onSale(receipt)
  };
  const closeModal = () => {
    window.location.reload();
    setOpen(false)
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4">Resumen Compra</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ marginBottom: 10 }}>
          <CardContent>
            {carts.saleProductsDTO.length > 0
              && (
                <>
                  <Typography variant="h4">Productos</Typography>
                  {carts.saleProductsDTO.map((selectItem, index) => (
                    <>
                      <Typography variant="h6">{selectItem.name}</Typography>
                      <table style={{ width: '100%', marginBottom: 20 }}>
                        <tbody>
                          <tr>
                            <td>
                              <Typography variant="body1">Cantidad</Typography>
                            </td>
                            <td>
                              <Typography variant="body1" className="ml-0">{selectItem.count}</Typography>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ))}
                </>
              )}
            {carts.salePackageDTO.length > 0
              && (
                <>
                  <Typography variant="h4">Envases Retornable</Typography>
                  {carts.salePackageDTO.map((selectItem, index) => (
                    <>
                      <Typography variant="h6">{selectItem.name}</Typography>
                      <table style={{ width: '100%', marginBottom: 20 }}>
                        <tbody>
                          <tr>
                            <td>
                              <Typography variant="body1">Cantidad</Typography>
                            </td>
                            <td>
                              <Typography variant="body1" className="ml-0">{selectItem.count}</Typography>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ))}
                </>
              )}
            {carts.saleGiftPackage.length > 0
              && (
                <>
                  <Typography variant="h4">Envases de Regalo</Typography>
                  {carts.saleGiftPackage.map((selectItem, index) => (
                    <>
                      <Typography variant="h6">{selectItem.name}</Typography>
                      <table style={{ width: '100%', marginBottom: 20 }}>
                        <tbody>
                          <tr>
                            <td>
                              <Typography variant="body1">Cantidad</Typography>
                            </td>
                            <td>
                              <Typography variant="body1" className="ml-0">{selectItem.count}</Typography>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ))}
                </>
              )}

            <table style={{ width: '100%', marginBottom: 40, marginTop: 60 }}>
              <Typography variant="h4">{`TOTAL: $${carts.totalAmountSale}`}</Typography>
              <tbody>
                <tr>
                  <td>
                    <Typography variant="body1">Valor Producto</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`$${carts.totalAmountProduct}`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">Envases nuevos</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`$${carts.totalAmountPackage}`}</Typography>
                  </td>
                </tr>
              </tbody>
            </table>

            <Grid item sm={12} xs={12}>
              <InputLabel>Ingrese número de boleta</InputLabel>
              <Input
                value={receipt}
                onChange={(event) => setReceipt(((event.target.value).replace(/\./g,' ')).replace(/\s/g, ''))}
                style={{ width: '100%', marginBottom: 20 }}
              />
            </Grid>

            <Button
              onClick={() => setOpen(true)}
              disabled={receipt === ''}
              variant="contained"
              color="primary"
              style={{ marginBottom: 20, marginLeft: 20 }}
            >
              Confirmar
            </Button>
          </CardContent>
        </Paper>

        <ConfirmSaleModal
          onSale={onClick}
          open={open}
          carts={carts}
          closeModal={closeModal}
        />
      </Grid>
    </>
  );
}

ValidationCard.propTypes = {
  carts: PropTypes.object.isRequired,
  onSale: PropTypes.func.isRequired,
};

export default ValidationCard;
