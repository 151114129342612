/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography, Checkbox, Input, InputLabel,
} from '@material-ui/core';
import FormField from './FormField';
import BottomButtons from './BottomButtons';


function DispenserForm({
  dispenser, fields, onSubmit, initialVals, buttonProps,
}) {
  let defaultValues = { CalibrationComment: '', ResultComment: '' };
  Object.values(fields).forEach((field) => {
    if (field.type === 'SELECT') {
      defaultValues[field.key] = field.options[field.options.length - 1][field.key];
    } else if (field.type === 'CHECKBOX') {
      defaultValues[field.key] = [];
    }
  });

  defaultValues = initialVals || defaultValues;
  const [response, setResponse] = useState(defaultValues);
  const handleChange = (key, event) => {
    setResponse({ ...response, [key]: event.target.value });
  };

  const handleCheckBox = (event) => {
    const value = Number(event.target.value);
    let checked = [...response.idCheckResultDetail];
    if (checked.includes(value)) {
      checked = checked.filter((v) => v !== value);
    } else {
      checked.push(value);
    }
    setResponse({ ...response, idCheckResultDetail: checked });
  };

  const isChecked = (id) => (response.idCheckResultDetail.includes(id));
  return (
    <Grid container spacing={10}>
      <Grid item sm={6} xs={12} style={{ paddingBottom: 0 }}>
        <span>
          <Typography variant="h4">
            {dispenser.prodDesc}
          </Typography>
        </span>
        <Typography variant="h6">
          {dispenser.description}
        </Typography>
      </Grid>
      <FormField
        field={fields.idCheckProductLevel}
        label="Nivel de producto"
        selected={response.idCheckProductLevel}
        handleChange={handleChange}
      />
      <FormField
        field={fields.idCheckPhysicalState}
        label="Estado físico"
        selected={response.idCheckPhysicalState}
        handleChange={handleChange}
      />
      <FormField
        field={fields.idCheckCleaning}
        label="Limpieza del dispensador"
        selected={response.idCheckCleaning}
        handleChange={handleChange}
      />
      <Grid item sm={12} xs={12} style={{ paddingBottom: 0 }}>
        <Typography variant="h6"> Prueba de dispensación </Typography>
      </Grid>
      <FormField
        field={fields.idCheckConnectionStatus}
        label="Conexión a Internet"
        selected={response.idCheckConnectionStatus}
        handleChange={handleChange}
      />
      <FormField
        field={fields.idReaderStatus}
        label="Estado del lector RFID"
        selected={response.idReaderStatus}
        handleChange={handleChange}
      />
      <FormField
        field={fields.idCheckCalibration}
        label="Calibración"
        selected={response.idCheckCalibration}
        handleChange={handleChange}
      />
      <Grid item xs={12} sm={6}>
        <InputLabel>Comentario sobre la calibración</InputLabel>
        <Input
          value={response.CalibrationComment}
          onChange={(e) => handleChange('CalibrationComment', e)}
          style={{ width: '100%' }}
        />
      </Grid>
      <FormField
        field={fields.idDropperStatus}
        label="Estado del cortagotas"
        selected={response.idDropperStatus}
        handleChange={handleChange}
      />
      <FormField
        field={fields.idCheckResult}
        label="Resultado de prueba de dispensación"
        selected={response.idCheckResult}
        handleChange={handleChange}
      />
      <Grid container item xs={12} sm={12} direction="row">
        {fields.idCheckResultDetail.options.map((option) => (
          option.description !== 'Otros' ? (
            <Grid item sm={4} xs={12} key={`ch${option.idCheckResultDetail}`}>
              <Checkbox
                checked={isChecked(option.idCheckResultDetail)}
                onChange={handleCheckBox}
                value={option.idCheckResultDetail}
              />
              <Typography variant="caption">{option.description}</Typography>
            </Grid>
          ) : (
            <Grid item sm={12} xs={12} key={`ch${option.idCheckResultDetail}`}>
              <Checkbox
                checked={isChecked(option.idCheckResultDetail)}
                onChange={handleCheckBox}
                value={option.idCheckResultDetail}
              />
              <Typography variant="caption">{option.description}</Typography>
              <Input
                style={{ marginLeft: '3%', fontSize: '0.85rem', width: '70%' }}
                value={response.ResultComment}
                onChange={(e) => handleChange('ResultComment', e)}

              />
            </Grid>
          )
        ))}
      </Grid>
      <BottomButtons
        {...buttonProps}
        onSubmit={() => onSubmit(response)}
        dispenserName={dispenser.prodDesc}
        dispenserDesc={dispenser.description}
      />
    </Grid>
  );
}

DispenserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  dispenser: PropTypes.object.isRequired,
  initialVals: PropTypes.object,
  fields: PropTypes.object.isRequired,
  buttonProps: PropTypes.object.isRequired,
};

DispenserForm.defaultProps = {
  initialVals: null,
};


export default DispenserForm;
