import { takeLatest, put } from "redux-saga/effects";
import { getVehicle } from "./orders/getVehicle";

function* obtainVehicle({ payload }) {
  try {
    const result = yield getVehicle();
    if (result.data.error.errorCode !== 0) {
      if (result.data.error.errorCode === 1) {
        yield put({
          type: "GET_ALL_VEHICLE",
          payload: {
            vehicles: [],
          },
        });
      } else {
        yield put({
          type: "FAILED_ANY_FETCH",
          payload: "Error al obtener vehiculos",
        });
      }
    } else {
      yield put({
        type: "GET_ALL_VEHICLE",
        payload: {
          vehicles: result.data.data,
        },
      });
    }
  } catch (error) {
    yield put({
      type: "FAILED_ANY_FETCH",
      payload: "Error al obtener vehiculos",
    });
    console.log("Error GET_ALL_VEHICLE", { error, payload });
  }
}

export default function* getAllVehicle() {
  yield takeLatest("LAUNCH_GET_ALL_VEHICLE", obtainVehicle);
}
