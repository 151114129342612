import axios from 'axios';
import { apicenter } from '../../../data/apicenter';

export const getOrders = async ({
  id,
  date,
}) => {
  const link = `${apicenter.API}/Orders/GetOrdersByVendor/${id}/${date}`;
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };
  const res = await axios.get(link, { headers });
  return res;
};
