import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import reduxSaga from "redux-saga";
import { session } from "./reducers/session";
import { app } from "./reducers/app";
import { status } from "./reducers/status";
import { ui } from "./reducers/ui";
import { orders } from "./reducers/orders";
import { vehicles } from "./reducers/vehicle";
import { myVehicles } from "./reducers/myVehicle";
import { ordersByVehicle } from "./reducers/ordersByVehicle";
import { unassignedOrders } from "./reducers/unassignedListOrders";
import { listOrdersOthersVehicles } from "./reducers/listOrdersOthersVehicles";
import rootSaga from "./sagas";

const reducer = combineReducers({
  session,
  app,
  status,
  ui,
  orders,
  vehicles,
  myVehicles,
  ordersByVehicle,
  unassignedOrders,
  listOrdersOthersVehicles,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["session"],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = reduxSaga();

export const store = createStore(
  persistedReducer,
  // eslint-disable-next-line no-underscore-dangle
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

export default () => {
  const persistor = persistStore(store);
  return {
    store: {
      ...store,
      runSaga: sagaMiddleware.run(rootSaga),
    },
    persistor,
  };
};
