import { apicenter } from "../../../data/apicenter";

function getIndicator(userId, initDate, lastDate, callback) {
  const link = `${apicenter.API}/Vendor/GetVendorIndicator/${userId}/${initDate}/${lastDate}`;
  const method = "GET";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...apicenter.APIHEADER,
  };
  fetch(link, { method, headers })
    .then((res) => res.json())
    .then((res) => {
      if (callback) callback(res, true);
    })
    .catch((error) => {
      if (callback) callback(error, false);
    });
}
function getOrderStatus(userId, time, month, year, callback) {
  const link = `${apicenter.API}/Vendor/GetVendorOrderStatus/${userId}/${time}/${year}/${month}`;
  const method = "GET";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...apicenter.APIHEADER,
  };

  fetch(link, { method, headers })
    .then((res) => res.json())
    .then((res) => {
      if (callback) callback(res, true);
    })
    .catch((error) => {
      if (callback) callback(error, false);
    });
}

export { getIndicator, getOrderStatus };
