import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  Paper,
  Button,
  Modal,
  Box,
  makeStyles,
  LinearProgress
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { postSetPromoClientList } from '../../components/utils/api/postSetPromoClientList';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CountButtons from '../../components/globals/Buttons/CountButtons';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

import Alert from '@material-ui/lab/Alert';
import SnackBarError from '../../components/ui/Snackbar/SnackBarError';
import Snackbar from '@material-ui/core/Snackbar';

import ListItemIcon from '@material-ui/core/ListItemIcon';

const ITEM_HEIGHT = 88;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 450,
    },
  },
};

const SelectPromo = ({ data }) => {
  
  const [promoCode, setPromoCode] = useState([]);
  const [promoList, setPromoSelected ] = useState(data.promoList);
  const [dataClient, setDataClient ] = useState(data.clientInfo);
  const handleObtainPromosClient = data.handleObtainPromosClient;
  
  const [loading, setLoading] = useState('0%');
  const [submit, setSubmit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [submitOk, setSubmitOk] = useState(false);
  const [submitKo, setSubmitKo] = useState(false);

  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '80%',
      maxWidth: '550px',
      padding: '1rem',
      margin: 'auto',
      borderRadius: '16px',
      backgroundColor: 'white',
      flexDirection: 'column',
    },
    text: {
      fontWeight: 'bold',
      color: '#273746',
      textAlign: 'center',
    },
    load: {
      fontWeight: 'bold',
      color: '#273746',
      textAlign: 'center',
    },    
    success: {
      color: theme.palette.success.light,
    },
  }));

  const classes = useStyles();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    console.log("value onchange", value);

    setPromoCode(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    setPromoSelected(promoList.map( (pro) => {if(value.find(val => val === pro.code) && (pro.quantity == 0 || !pro.quantity)) pro.quantity = 1; else if(!value.find(val => val === pro.code)) pro.quantity = 0; return pro; } ));
  };

  const handleMinus = (idPromo) => {
    
    console.log("menos", idPromo, "de la promo" , promoTMP);
    setPromoSelected(promoList.map( (pro) => {if(pro.idPromo === idPromo && pro.quantity > 0) pro.quantity--; return pro; } ));
    var promoTMP = promoList.find(obj => { return obj.idPromo === idPromo; });
    if(promoTMP.quantity < 1){
      console.log("se quita", promoTMP.code, "de la lista", promoTMP.code, " -> ", promoCode.filter(pro => pro !== promoTMP.code));
      setPromoCode(promoCode.filter(pro => pro !== promoTMP.code));
    }
  }

  const handlePlus = (idPromo) => {
    console.log("MAS!", idPromo);
    setPromoSelected(promoList.map( (pro) => {if(pro.idPromo === idPromo && pro.quantity > 0) pro.quantity++; return pro; }) );
  }
  
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const handleModalOpen = () => {
    setOpenModal(true);
  };
  
  const handleRsetQuantity = () => {    
    setPromoSelected(promoList.map( (pro) => {pro.quantity = 0; return pro;}) );
  };
  const handleConfirmation = async () => {
    setSubmit(true);
    setLoading('100%');
    // send validation to backend
    const res = await postSetPromoClientList({promoList, userId: dataClient.userId} , (res, status, error) => {
      setLoading('0%');
      if(res.data == "OK") {
        handleObtainPromosClient(dataClient.userId);
        setOpenModal(false);
        setSubmit(false);
        setSubmitOk(true);
        setPromoCode([]);
        handleRsetQuantity();
        /*
        setTimeout(() => {
          history.push('/promoClients');
        }, 5000);
        */
      }else{
        setSubmit(false);
        setSubmitKo(true);
      }
    });
    //setOpenModal(false);
  };

  useEffect(() => {
    setPromoSelected(data.promoList);
  }, []);

  return (
    <Paper style={{ padding: '1rem', marginTop: '1rem', width: '100%', display: 'flex' }} container>
      <Grid
        item
        xs={12}
      >
        {promoList.length > 0 && 
        <FormControl sx={12} style={{width: '100%'}}>
          <InputLabel id="demo-multiple-checkbox-label">Promos</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={promoCode}
            onChange={handleChange}
            input={<OutlinedInput label="Promos" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            sx={12}
          >
            {promoList.map((promo) => (
              <MenuItem key={promo.code} value={promo.code}>
                <Checkbox checked={promoCode.indexOf(promo.code)  > -1} key={promo.idPromo+"_chk"} value={promo.code} id={`chk_${promo.idPromo}`} />
                <ListItemText primary={promo.code} secondary={promo.description} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        }
        
        {promoList.length > 0 && 
        <List dense={false}> 
        {promoList.map((promocount) => ( promocount.quantity > 0 &&           
          <ListItem key={promocount.code+"_q"}>
            <ListItemText
              primary={promocount.code}
              secondary={promocount.description}
            />
            <ListItemSecondaryAction>
            <CountButtons onClickMinus={handleMinus} onClickPlus={handlePlus} id={promocount.idPromo} quantity={promocount.quantity} title={`(${promocount.code}) ${promocount.description}`} width={"100%"} />
            </ListItemSecondaryAction>          
          </ListItem>            
        )
        )}
        </List>}
        
        {promoList.length > 0 && promoList.filter((promocount) => ( promocount.quantity && promocount.quantity > 0)).length > 0 &&
        <Box  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button
            style={{ backgroundColor: 'black', borderRadius: '16px', marginTop: '2rem', width: '50%', maxWidth: '250px', boxShadow: 'none' }}
            disabled={submit}            
            variant="contained"
            color="primary"
            onClick={handleModalOpen}
          >
            <Typography id="modal-modal-description" variant="body2">
              Asignar Promociones
            </Typography>
          </Button>
        </Box>
        }
      </Grid>
      
      <Modal open={openModal} onClose={handleModalClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box className={classes.modal}>
          <Box className={classes.text} id="modal-modal-title" variant="h6" component="h2">
            Confirmar asignación de promos
          </Box>
          <Box className={classes.text} style={{ marginTop: '2rem' }} id="modal-modal-description" variant="body2">
            Estas son las promociones que se asignaran:
            <List dense={true}>
              {promoList.map((promocount) => (promocount.quantity > 0 &&
                <ListItem key={`lst${promocount.code}`}>
                  <ListItemIcon>
                    {promocount.quantity}
                  </ListItemIcon>
                  <ListItemText
                    primary={promocount.description}
                    secondary={promocount.code}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <LinearProgress color="secondary" style={{ display: 'flex', height: '5px', width: loading, display: 'flex', justifyContent: 'center' }} />
          {loading == '0%' && <Button
            style={{ backgroundColor: 'black', borderRadius: '16px', marginTop: '2rem', width: '50%', maxWidth: '250px', boxShadow: 'none' }}
            disabled={submit}            
            variant="contained"
            color="primary"
            onClick={handleConfirmation}
          >
            <Typography id="modal-modal-description" variant="body2">
              Confirmar
            </Typography>
          </Button>}
          {submitOk &&
            <DoneOutlineIcon className={classes.success} />
          }
        </Box>
      </Modal>
      <SnackBarError
        show={submitKo}
        handleClose={() => { setSubmitKo(false); }}
        message="No fue posible registrar la entrega"
      />
      <Snackbar open={submitOk} autoHideDuration={6000} onClose={() => { setSubmitOk(false); }}>
        <Alert onClose={() => { setSubmitOk(false); }} severity="success" sx={{ width: '100%' }}>
          Entrega de envase registrada
        </Alert>
      </Snackbar>
    </Paper>
  );
};
SelectPromo.propTypes = {
  data: PropTypes.object.isRequired
};
export default (SelectPromo);
