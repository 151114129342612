/* Actualizar aquí el estado de la app */
const status = process.env.REACT_APP_API_ENV;

/* Actualizar las URL aquí */
const develop = {
  // API: 'https://tricicloapidev.azurewebsites.net/api/',
  API: 'https://algramodev-api.azure-api.net/vendors/api', //  'https://localhost:44332/api',
  APIHEADER: {
    'Ocp-Apim-Subscription-Key': '23febf5204364b7590922925bba9af41',
    'Ocp-Apim-Trace': true,
  },
  API_DISP: 'https://algramodev-api.azure-api.net/salespoint',
  PAGOFACIL: 'https://algramopagofacil-dev.azurewebsites.net',
  KUSHKI: {
    url: 'https://api-uat.kushkipagos.com',
    publicKey: '20000000108321153000',
  },
};

const QA = {
  API: 'https://preprod-algramo.azure-api.net/vendors/api',
  APIHEADER: {
    'Ocp-Apim-Subscription-Key': 'a0274c040c2b4a83b856152b08213534',
    'Ocp-Apim-Trace': true,
  },
  API_DISP: 'https://preprod-algramo.azure-api.net/salespoint',
  PAGOFACIL: 'https://algramopagofacil-dev.azurewebsites.net',
  KUSHKI: {
    url: 'https://api-uat.kushkipagos.com',
    publicKey: '20000000108321153000',
  },
};

const production = {
  // API: 'https://tricicloapi.azurewebsites.net/api',
  API: 'https://algramoprod.azure-api.net/vendors/api',
  API_DISP: 'https://algramoprod.azure-api.net/salespoint',
  APIHEADER: {
    'Ocp-Apim-Subscription-Key': '80e7f8b4c3a24aacb0723121f5eb9bd7',
    'Ocp-Apim-Trace': true,
  },
  PAGOFACIL: 'https://alagramopagofacil.azurewebsites.net',
  KUSHKI: {
    url: 'https://api.kushkipagos.com',
    publicKey: '46f4249c2789419f96a32f1737d93eed',
  },
};

const all = {
  GOOGLEAPI: 'https://maps.googleapis.com/maps/api/',
  GOOGLEKEY: 'AIzaSyDsO6eYhSYplWyiL3uWKMQ_KBkwczJy0Hk',
  IMAGES: 'https://tricicloblobprod.blob.core.windows.net',
  GRAPHFACEBOOK: 'https://graph.facebook.com/',
  STATUS: status,
};

/*
/**
 *  Funciones, no tocars
 */

function obtainUrl(key) {
  switch (key) {
    case 'development':
      return { ...develop, ...all };
    case 'qa':
      return { ...QA, ...all };
    case 'production':
      return { ...production, ...all };
    default:
      return { ...develop, ...all };
  }
}

export const apicenter = obtainUrl(status);
