import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Typography,
  Grid,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Input,
} from '@material-ui/core';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import moment from 'moment';
import { getBlockByDateZone } from '../../../components/utils/api/Schedule/getBlockByDateZone';
import { postSetReschedule } from '../../../components/utils/api/Orders/postSetReschedule';
import { postChangeOrderStatus } from '../../../components/utils/api/Orders/postChangeOrderStatus';


function IndexDialogQuestion({
  questionGrey,
  setQuestionGrey,
  orderNumber,
  userId,
  setMessage,
  handleGetOrders,
  data,
  assignmentData,
}) {
  const [blockHour, setBlockHour] = useState([]);

  const obtainBlockDateZone = async (date) => {
    if (data) {
      const finder = data.find((i) => i.orderNumber === orderNumber);
      const provider = Number(finder.orderProducts[0].idProvider);
      const block = await getBlockByDateZone(date, finder.idZone, 0);
      // eslint-disable-next-line no-unused-expressions
      block && setBlockHour(block.data.filter((i) => i.isActive === true));
    }
  };

  return (
    <Dialog
      open={questionGrey}
      onClose={() => setQuestionGrey(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Formik
        initialValues={{
          date: moment().format('YYYY-MM-DD'),
          hour: 0,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.date) {
            errors.date = 'Este campo es requerido';
          } else if (blockHour.length < 1) {
            errors.date = 'Selecciona una fecha con horarios disponibles';
          }
          return errors;
        }}
        onSubmit={async (values, { setErrors }) => {
          const req = {
            OrderNumber: orderNumber,
            RBlockId: blockHour[values.hour].blockId,
            RSchedulingDate: values.date,
            UserId: userId,
          };
          const res = await postSetReschedule(req);

          if (res.data) {
            setMessage('Se envió la solicitud con éxito');
            setQuestionGrey(false);
            postChangeOrderStatus({
              UserId: userId,
              orderNumber,
              NewStatusId: 1,
              IdCountry: 43,
              IdSalePointLocation: assignmentData.idSalePointLocation,
            }, (_res, status) => {
              setMessage(status ? '¡Cambiado con exito!' : 'Error al cambiar estado');
              handleGetOrders();
            });
          } else {
            setErrors('date', 'Error en la consulta');
          }
        }}
      >
        {({ setFieldValue, values }) => (
          <Form style={{ width: '100%', padding: 20 }}>
            <DialogTitle id="alert-dialog-title">Reagendarás este pedido</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Ingresa fecha y hora de entrega
                <Grid container spacing={4} style={{ marginTop: 30 }}>
                  <Grid item sm={6} xs={12}>
                    <InputLabel>Fecha de entrega</InputLabel>
                    <Field
                      type="date"
                      name="date"
                      as={Input}
                      min={moment().format('YYYY-MM-DD')}
                      style={{ width: '100%' }}
                      onChange={(value) => {
                        setFieldValue('date', value.target.value);
                        // eslint-disable-next-line no-unused-expressions
                        value.target.value && obtainBlockDateZone(value.target.value, orderNumber);
                      }}
                    />
                    <ErrorMessage name="date" component="div" style={{ color: 'red' }} />
                  </Grid>

                  {blockHour.filter((e) => e.isActive === true).length > 0 ? (
                    <Grid item sm={6} xs={12}>
                      <Typography variant="caption">
                        <b>Estado del pedido:</b>
                      </Typography>
                      <Select
                        style={{ width: '100%' }}
                        value={values.hour}
                        onChange={(val) => {
                          setFieldValue('hour', val.target.value);
                        }}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        {
                          blockHour.map((i, key) => (
                            i.isActive && (
                              <MenuItem
                                key={i.blockId}
                                value={key}
                              >
                                {i.block}
                              </MenuItem>
                            )
                          ))
                        }
                      </Select>
                      <ErrorMessage name="hours" component="div" style={{ color: 'red' }} />
                    </Grid>
                  ) : <Typography>No hay horas disponibles</Typography>}
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setQuestionGrey(false)} color="primary">
                Cancelar
              </Button>
              <Button type="submit" color="primary" autoFocus>
                Aceptar
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}


IndexDialogQuestion.propTypes = {
  questionGrey: PropTypes.bool.isRequired,
  setQuestionGrey: PropTypes.func.isRequired,
  handleGetOrders: PropTypes.func.isRequired,
  // obtainBlockDateZone: PropTypes.func.isRequired,
  orderNumber: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  setMessage: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
  assignmentData: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  orderStatus: state.app.orderStatus,
  userId: state.session.id,
  assignmentData: state.myVehicles.assignmentData ? state.myVehicles.assignmentData : null,
});


export default connect(mapStateToProps)(IndexDialogQuestion);
