const defaultState = {
  isAuthenticated: false,
  id: null,
  token: null,
  userInfo: null,
  info: null,
  userType: null,
};

export function session(state = defaultState, action) {
  switch (action.type) {
    case 'SIGN_IN':
      return {
        ...state, isAuthenticated: true, id: action.payload.userId, info: { ...action.payload },
      };
    case 'SIGN_OUT':
      return { ...state, ...defaultState };
    case 'SET_TOKEN':
      return {
        ...state, token: action.payload.token, userType: action.payload.userType,
      };
    default:
      return state;
  }
}
