export const getAllVehicle = (payload) => ({
  type: "LAUNCH_GET_ALL_VEHICLE",
  ...payload,
});

export const getMyAssignedVehicle = (payload) => ({
  type: "LAUNCH_GET_MY_VEHICLE",
  ...payload,
});

export const getOrdersByVehicle = (payload) => ({
  type: "LAUNCH_GET_ORDERS_BY_VEHICLE",
  ...payload,
});

export const getUnassignedOrders = (payload) => ({
  type: "LAUNCH_GET_UNASSIGNED_ORDERS",
  ...payload,
});

export const getListOrdersOthersVehicles = (payload) => ({
  type: "LAUNCH_GET_LIST_ORDERS_OTHERS_VEHICLES",
  ...payload,
});
