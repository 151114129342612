import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


function OrderCard({ order }) {
  const orderContent = !isEmpty(order) && (
    <>
      <Grid item xs={12}>
        <Typography variant="h2">Datos pedido pagado</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ marginBottom: 10 }}>
          <CardContent>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td>
                    <Typography variant="body1">Número de la orden</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`#${order.orderNumber}`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">Cantidad disponible</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`${order.qtyNotUsed}ml`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">Monto disponible (*)</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`$${order.creditNotUsed}`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">Total pagado del producto</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`$${order.total}`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">Estado envase</Typography>
                  </td>
                  <td>
                    {order.qtyPkgNotUsed > 0 && <Typography variant="body1">Pagado</Typography>}
                    {order.qtyPkgNotUsed === 0 && <Typography variant="body1">NO pagado</Typography>}
                  </td>
                </tr>
              </tbody>
            </table>
            <Typography variant="caption">(*) Este monto solo considera la cantidad de producto pagada, no el envase. En el caso que se esté usando un envase nuevo que no haya sido pagado previamente en el pedido, se verá limitada esta cantidad</Typography>
          </CardContent>
        </Paper>
        <Typography variant="caption">Datos del pedido asociado al usuario ingresado en el dispensador.</Typography>
      </Grid>
    </>
  );

  return orderContent;
}

OrderCard.propTypes = {
  order: PropTypes.object,
};

OrderCard.defaultProps = {
  order: null,
};


export default OrderCard;
