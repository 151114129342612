import { apicenter } from '../../../../data/apicenter';


export function postPayment(user, product, sale, order, callback) {
  const link = `${apicenter.API_DISP}/RegisterSale`;
  const method = 'POST';
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };
  const body = JSON.stringify({
    points_used: 0,
    charged: sale.charged,
    identifier: sale.identifier,
    userid: user.userId,
    prodid: product.id,
    currency: product.currency,
    idcountry: product.idCountry,
    idBarrel: sale.idBarrel,
    dispenser: product.dispenserName,
    globalOrderId: order !== null ? order.globalOrderId : 0,
    orderIdProd: order !== null ? order.orderIdProd : 0,
    orderIdPackage: order !== null ? order.orderIdPackage : 0,
    packageStatus: order !== null ? order.packageStatus : 0,
  });
  fetch(link, { method, headers, body })
    .then((res) => {
      if (callback && res.status === 200) callback(true);
      else if (callback && res.status !== 200) callback(false);
    })
    .catch(() => {
      if (callback) callback(false);
    });
}
