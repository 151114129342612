import { apicenter } from '../../../../data/apicenter';

export async function postSetReschedule(req) {
  try {
    const link = `${apicenter.API}/Orders/SetReschedule`;
    const method = 'POST';
    const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };
    const body = JSON.stringify(req);
    const response = await fetch(link, { method, headers, body });
    const data = await response.json();
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error POST postSetReschedule =>', { req, error });
    return null;
  }
}

/*
  {
      "OrderNumber": 1441,
      "RBlockId": 10,
      "RSchedulingDate": "2020-06-17",
      "UserId" : 6901
  }
*/
