import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { isEmpty } from 'lodash';
import { getUser } from '../../components/utils/api/Sale/user';
import { getProduct } from '../../components/utils/api/Sale/product';
import { getPack } from '../../components/utils/api/Sale/pack';
import { getOrder } from '../../components/utils/api/Sale/order';
import { getValidations } from './validations';
import Validation from './ValidationItem';
import OrderCard from './OrderCard';
import PackCard from './PackCard';
import UserCard from './UserCard';

function ValidationContainer({ sale, onIsValidSale, onValidData }) {
  const [user, setUser] = useState({});
  const [pack, setPack] = useState(null);
  const [product, setProduct] = useState({});
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(0);
  const [validations, setValidations] = useState([]);

  useEffect(() => {
    if (!isEmpty(sale)) {
      setLoading(3);
      getUser(sale.user, (res, status) => {
        if (status) setUser(res.data);
        setLoading(loading - 1);
      });
      getPack(sale.identifier, (res, status) => {
        if (status) setPack(res.data);
        setLoading(loading - 1);
      });
      getProduct(sale.mac, (res, status) => {
        if (status) {
          const prod = res.data.find((p) => p.id === sale.prodid);
          const { packages } = prod;
          const packs = packages.find((p) => p.idPackage === sale.idPackage);
          setProduct({ ...prod, ...packs });
        }
        setLoading(loading - 1);
      });
    }
  }, [sale]);


  useEffect(() => {
    if (!isEmpty(user) && !isEmpty(product)) {
      setLoading(loading + 1);
      getOrder(user.userId, product.id, sale.identifier, sale.idPackage, (res, status) => {
        if (status) {
          const orderResp = res.data[0];
          setOrder(orderResp.useBalance ? orderResp : null);
        }
        setLoading(loading - 1);
      });
    }
  }, [user, product]);

  useEffect(() => {
    const load = (user === null || !isEmpty(user)) && !isEmpty(product);
    const vals = load ? getValidations(user, pack, product, sale, order) : [];
    setValidations(vals);
    onIsValidSale(load && vals.every((val) => val.isAlert || val.condition));
    onValidData({
      user, product, order, pack,
    });
  }, [user, product, order, pack]);

  const view = (
    <>
      <UserCard user={user} product={product} sale={sale} />
      <PackCard pack={pack} />
      <OrderCard order={order} />
      <Grid item xs={12}>
        <Typography variant="h2">Validaciones</Typography>
      </Grid>
      {validations.map((val) => (
        <Grid key={val.key} item xs={12}>
          <Validation
            condition={val.condition}
            successText={val.successText}
            errorText={val.errorText}
            fixText={val.fixText}
            isAlert={val.isAlert}
          />
        </Grid>
      ))}
      <Typography variant="caption">Criterios para que la venta sea aceptada.</Typography>

    </>

  );

  const loadingView = (
    <Typography> Cargando datos, esto puede tardar un poco.... </Typography>
  );

  const ret = loading > 0 ? loadingView : view;

  return (
    !isEmpty(sale) && ret
  );
}

ValidationContainer.propTypes = {
  sale: PropTypes.object.isRequired,
  onIsValidSale: PropTypes.func.isRequired,
  onValidData: PropTypes.func.isRequired,
};


export default ValidationContainer;
