import React, { useState } from 'react';
import {
  Typography,
  Grid,
  Paper,
  Button,
  Checkbox,
  Modal,
  Box,
  makeStyles,
  LinearProgress
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import SnackBarError from '../../components/ui/Snackbar/SnackBarError';
import Snackbar from '@material-ui/core/Snackbar';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

import { postDeliveryProd } from '../../components/utils/api/postDeliveryProd';

const ValidateCollect = ({ handleReset, dataOrder, dataProdOrder, vendorId }) => {
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '80%',
      maxWidth: '550px',
      padding: '1rem',
      margin: 'auto',
      borderRadius: '16px',
      backgroundColor: 'white',
      flexDirection: 'column',
    },
    text: {
      fontWeight: 'bold',
      color: '#273746',
      textAlign: 'center',
    },
    load: {
      fontWeight: 'bold',
      color: '#273746',
      textAlign: 'center',
    },    
    success: {
      color: theme.palette.success.light,
    },
  }));
  
  const {
    nameProduct,
    isStarterPack,
    isDeliverable,
    priceProduct,
    orderStatus,
    orderStatusName,
  } = dataProdOrder;
  const {
    orderNumber
  } = dataOrder
  const classes = useStyles();
  const [loading, setLoading] = useState('0%');
  const [checked, setChecked] = useState(false);
  const [available, setAvailable] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [submitOrder, setSubmitOrder] = useState(false);
  
  const [notSetOrder, setNotSetOrder] = useState(false);
  const [setOkOrder, setOkSetOrder] = useState(false);

  const handleStatusChange = () => {
    setChecked(!checked);    
    setAvailable(orderStatus == 0 && isStarterPack && isDeliverable);
  };
  const handleSubmit = () => {
    setSubmit(true);
  };
  const handleModalClose = () => {
    setSubmit(false);
  };
  const handleValidation = async () => {
    setSubmitOrder(true);
    setOkSetOrder(false);
    setLoading('100%');
    // send validation to backend
    const res = await postDeliveryProd({orderNumber, vendorId}, (res, status, error) => {
      setLoading('0%');
      if(res.data == "OK") {
        setOkSetOrder(true);
        setTimeout(() => {
          setSubmitOrder(false);
          handleReset();
          history.push('/packhome');
        }, 3000);
      }else{
        setNotSetOrder(true)
      }
    });
    setSubmitOrder(false);
  };
  return ( dataProdOrder &&
    <div>
      <SnackBarError
        show={notSetOrder}
        handleClose={() => { setNotSetOrder(false); }}
        message="No fue posible registrar la entrega"
      />
      <Snackbar open={setOkOrder} autoHideDuration={6000} onClose={() => { setOkSetOrder(false); }}>
        <Alert onClose={() => { setOkSetOrder(false); }} severity="success" sx={{ width: '100%' }}>
          Entrega de envase registrada
        </Alert>
      </Snackbar>
      <Modal open={submit} onClose={handleModalClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box className={classes.modal}>
          <Typography className={classes.text} id="modal-modal-title" variant="h6" component="h2">
            Confirmar entrega
          </Typography>
          <Typography className={classes.text} style={{ marginTop: '2rem' }} id="modal-modal-description" variant="body2">
            ¿Estás seguro que quieres confirmar la entrega de este envase?
          </Typography>
          <LinearProgress color="secondary" style={{ display: 'flex', height: '5px', width: loading, display: 'flex', justifyContent: 'center' }} />
          {loading == '0%' && !setOkOrder && <Button
            style={{ backgroundColor: 'black', borderRadius: '16px', marginTop: '2rem', width: '50%', maxWidth: '250px', boxShadow: 'none' }}
            disabled={submitOrder}            
            variant="contained"
            color="primary"
            onClick={handleValidation}
          >
            <Typography id="modal-modal-description" variant="body2">
              Confirmar
            </Typography>
          </Button>}
          {setOkOrder &&
            <DoneOutlineIcon className={classes.success} />
          }
        </Box>
      </Modal>
      <Paper style={{ padding: '2rem', marginTop: '1rem' }}>
        <Grid
          item
          xs={12}
        >
          <Typography variant="caption">Orden de SP</Typography>
          <Typography variant="body1">
            <b>{nameProduct}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">Estado</Typography>
          <Typography variant="body1">
            <b>{orderStatusName}</b>
          </Typography>
        </Grid>
      </Paper>
      { orderStatus < 2 &&
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '2rem' }}>
          <Typography variant="h4">Recepción conforme</Typography>
          <span style={{ display: 'flex', marginTop: '5px' }}>
            <Checkbox checked={checked} onChange={handleStatusChange} />
            <Typography style={{margin: 'auto 0'}} variant="body1">Usuario recibe conforme su producto</Typography>
          </span>
          <Button
            style={{ margin: '2rem auto 0 auto' }}
            disabled={!checked || !available}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Validar Entrega
          </Button>
        </div>
      }
    </div>
  );
};

export default ValidateCollect;
