import React from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderColor: theme.palette.primary.main,
    border: '1px solid',
  },
}));


const PromoTable = ({ data }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} style={{ marginBottom: 100 }}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.head}>Promoción</TableCell>
            <TableCell className={classes.head}>Disponible</TableCell>
            <TableCell className={classes.head}>Fecha creación</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? data.map((row, i) => (
            <TableRow key={`${row.code}${i}`}>
              <TableCell>{row.description}({row.code})</TableCell>
              <TableCell>{row.isActive ? "Si": "No"}</TableCell>
              <TableCell>{moment(row.updateDate).format('DD/MM/YYYY - HH:MM')}</TableCell>
            </TableRow>
          )) : <TableRow><Typography><TableCell align="right">No hay datos</TableCell></Typography></TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PromoTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default PromoTable;
