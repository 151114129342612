import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00B5BB',
      contrastText: '#fff',
    },
    secondary: {
      main: '#383838',
      contrastText: '#fff',
    },
  },
  typography: {
    h2: {
      fontSize: '1.6rem',
    },
    h4: {
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },
  },
});

export default theme;
