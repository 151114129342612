export const Fn = {
  // Valida el rut con su cadena completa "XXXXXXXX-X"
  validaRut(rutc) {
    const rutCompleto = rutc.replace(".", "").replace(" ", "");
    if (rutCompleto !== "") {
      if (rutCompleto.length >= 9) {
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
        const tmp = rutCompleto.split("-");
        let digv = tmp[1];
        let rut = tmp[0];
        if (digv === "K") digv = "k";
        let M = 0;
        let S = 1;
        for (; rut; rut = Math.floor(rut / 10)) {
          S = (S + (rut % 10) * (9 - (M++ % 6))) % 11;
        }
        const digvOk = S ? S - 1 : "k";
        // eslint-disable-next-line eqeqeq
        return digvOk == digv;
      }
      return false;
    }
    return false;
  },
  validateEmail: (mail) =>
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail),
  formatRut: (rut) => `${rut.substr(0, 8)}-${rut.substr(8, 1)}`,
  separadorMiles: (val) => {
    let num = val;
    if (!isNaN(num)) {
      num = num
        .toString()
        .split("")
        .reverse()
        .join("")
        .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
      num = num.split("").reverse().join("").replace(/^[\.]/, "");
      return num;
    }
  },
  contain_num: (texto) => {
    const num = "0123456789";
    let i = 0;
    for (i; i < texto.length; i++) {
      if (num.indexOf(texto.charAt(i), 0) != -1) {
        return 1;
      }
    }
    return 0;
  },
};
