import { apicenter } from '../../../../data/apicenter';


export function getPack(rfid, callback) {
  const link = `${apicenter.API_DISP}/package2/${rfid}`;
  const method = 'GET';
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };

  fetch(link, { method, headers })
    .then((res) => res.json())
    .then((res) => {
      if (callback) callback(res, true);
    })
    .catch((error) => {
      if (callback) callback(error, false);
    });
}


export function postPack(identifier, prodId, userId, packId, callback) {
  const link = `${apicenter.API_DISP}/regpackage2`;
  const method = 'POST';
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };
  const body = JSON.stringify({
    identifier, userid: userId, prodid: prodId, idPackage: packId,
  });

  fetch(link, { method, headers, body })
    .then((res) => {
      if (callback && res.status === 200) callback(true);
      else if (callback && res.status !== 200) callback(false);
    })
    .catch(() => {
      if (callback) callback(false);
    });
}
