import React, { } from 'react';
import {
  Typography,
  Grid,
  Paper,
} from '@material-ui/core';

const ClientInfoReduced = ({ data, vertical = false }) => {
  const {
    name,
    lastName,
    email,
  } = data;
  return (
    <Paper style={{ padding: '2rem', marginTop: '1rem', width: '100%', display: 'flex' }} container>
      <Grid
        item
        xs={vertical ? 12 : 6}
      >
        <Typography variant="caption">Nombre</Typography>
        <Typography variant="body1">
          <b> {name} - {lastName} </b>
        </Typography>
      </Grid>
      {/* <Grid item xs={2} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      </Grid> */}
      <Grid item xs={vertical ? 12 : 6}>
        <Typography variant="caption">Correo</Typography>
        <Typography variant="body1">
          <b>{email}</b>
        </Typography>
      </Grid>
    </Paper>
  );
};

export default ClientInfoReduced;
