export function signIn(payload) {
  return {
    payload,
    type: 'SIGN_IN',
  };
}

export function signOut(payload) {
  return {
    payload,
    type: 'SIGN_OUT',
  };
}

export function setToken(payload) {
  return {
    payload,
    type: 'SET_TOKEN',
  };
}
