import { apicenter } from "../../../../data/apicenter";

export async function getPlacesBack(req) {
  const { input, location } = req;
  const language = "es-419";
  const locationQuery = location
    ? `&location=${location.lat},${location.lng}&radius=500`
    : "";
  try {
    const link = `${apicenter.API}/Clients/GetGoogleAutocomplete/${input}/${language}/address${locationQuery}`;
    const method = "GET";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...apicenter.APIHEADER,
    };

    const response = await fetch(link, { method, headers });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error GET getPlaces =>", { error });
    return null;
  }
}
