import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { obtainLanguage } from '../../data/lang/obtainLanguage';
import * as actions from '../../redux/actions/app';
import { getClientByCode } from '../utils/api/getClient';
import ClientInfoReduced from '../../modules/client/InfoReduced';
import SnackBarError from '../ui/Snackbar/SnackBarError';
import ValidateCollect from '../../modules/client/ValidateCollect';

// import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    margin: '3% 0',
    display: 'flex',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  inputRoot: {
    width: '100%',
  },

}));
const CollectView = ({
  app, vendorId
}) => {
  const [userCode, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [orderInfo, setOrderInfo] = useState(null);
  const [orderProdInfo, setOrderProdInfo] = useState(null);
  const [notFound, setNotFound] = useState(false);
  // const [list, setList] = useState([]);
  const classes = useStyles();

  const handleReset = () => {
    setCode('');
    setUserInfo(null);
    setOrderInfo(null);
  }
  const handleSearch = async () => {
    setUserInfo(null);
    setOrderInfo(null);
    setLoading(true);
    if (userCode === '') {
      return;
    }
    const res = await getClientByCode(userCode);
    setLoading(false);
    setCode('');
    //  llamado a la api para buscar
    if (!res.data || res.data.length === 0) {
      setNotFound(true);
      return;
    }
    setNotFound(false);
    const {
      name,
      lastName,
      email,
      userId,
    } = res.data.client;
    const {orderNumber} = res.data.globalOrder;
    const { nameProduct, isStarterPack, isDeliverable, priceProduct, orderStatus, orderStatusName } = res.data.productOrder;
    setUserInfo({
      name,
      lastName,
      email,
      userId,
    });
    setOrderInfo({orderNumber});
    setOrderProdInfo({ nameProduct, isStarterPack, isDeliverable, priceProduct, orderStatus, orderStatusName });
  };
  return (
    <div>
      <SnackBarError
        show={notFound}
        handleClose={() => { setNotFound(false); }}
        message={obtainLanguage.collect.codeNotFound}
      />
      <Typography variant="h4">{obtainLanguage.collect.title}</Typography>
      <Typography variant="h6">{obtainLanguage.collect.search}</Typography>
      <Paper className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          type="number"
          value={userCode}
          onChange={(event) => {
            setCode(event.target.value);
          }}
          placeholder={obtainLanguage.collect.search}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
        />
        <Button
          disabled={userCode === '' || loading}
          variant="contained"
          color="primary"
          onClick={() => {
            handleSearch();
          }}
        >
          {obtainLanguage.collect.searchBtn}
        </Button>
      </Paper>
      <div datos={JSON.stringify(orderInfo)}>
        {userInfo && <ClientInfoReduced data={userInfo} />}
        {orderInfo && orderProdInfo && <ValidateCollect handleReset={handleReset} dataOrder={orderInfo} dataProdOrder={orderProdInfo} vendorId={vendorId} />}
        {loading && <CircularProgress style={{ margin: 'auto' , display: 'flex', marginTop: '20px',}} />}
      </div>
    </div>
  );
}
CollectView.propTypes = {
  vendorId: PropTypes.string.isRequired,
  app: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  app: state.app,
  vendorId: state.session.info.userId,
});

export default connect(mapStateToProps, actions)(CollectView);
