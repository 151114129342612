/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { obtainLanguage } from '../../data/lang/obtainLanguage';
import { getCheckTypes, getPos } from '../utils/api/getCheck';
import { getSalePoints } from '../utils/api/getSalePoints';
import CheckTypeForm from './CheckComponents/CheckTypeForm';
import CheckContainer from './CheckComponents/CheckContainer';
import TricycleForm from './CheckComponents/TricycleForm';
import postCheck from '../utils/api/postCheck';

class CheckView extends React.Component {
  constructor(props) {
    super(props);
    const { idSalePoint, idPos } = this.props;
    this.state = {
      index: 0,
      checkTypes: [],
      salePoints: [],
      posInfo: [],
      check: { idPos, idSalePoint, idCheckType: null },
      tricycle: {},
    };
    this.isTrycicle = this.isTrycicle.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    getPos((res, status) => {
      if (status) this.handleChange('posInfo', res.data);
    });
    getCheckTypes((res, status) => {
      if (status) this.handleChange('checkTypes', res.data);
    });
    getSalePoints((res, status) => {
      if (status) this.handleChange('salePoints', res.data);
    });
  }

  handleChange(key, value) {
    const newValue = {};
    newValue[key] = value;
    this.setState((prevState) => ({ ...prevState, ...newValue }));
  }

  handlePrev() {
    const { index } = this.state;
    let i = index > 0 ? index - 1 : 0;
    if (i === 1 && !this.isTrycicle()) { i = 0; }
    this.handleChange('index', i);
  }

  handleNext() {
    const { index, check } = this.state;
    let i = index < 2 ? index + 1 : 2;
    if (i === 1 && !this.isTrycicle()) { i = 2; }
    if (check.idSalePoint === null || check.idSalePoint === '') { i = 0; }
    this.handleChange('index', i);
  }

  handleSubmit(packValues, dispResp) {
    const { userId, setStatus, history } = this.props;
    const { check, tricycle } = this.state;

    const { comment } = packValues;
    const ps = { ...packValues };
    delete ps.comment;
    postCheck(userId, check, ps, comment, dispResp, tricycle, (success, message) => {
      if (success) {
        setStatus('Registro creado con éxito');
        history.push('/');
      } else {
        setStatus(message);
      }
    });
  }

  isTrycicle() {
    const { check, salePoints } = this.state;
    return (
      check.idSalePoint !== null && check.idSalePoint !== '' && salePoints.filter((s) => s.idSalePoint === check.idSalePoint)[0].salePointType === 'Triciclo'
    );
  }

  render() {
    const {
      tricycle, salePoints, checkTypes, posInfo, check, index,
    } = this.state;
    const view = (
      <div>
        <Typography variant="h2">{obtainLanguage.checkin.title}</Typography>
        <Card style={{ padding: '3%', marginTop: '2%', marginBottom: '2%' }}>
          {index === 0 && (
          <CardContent>
            <CheckTypeForm
              defaultIdPos={check.idPos}
              defaultIdSalePoint={check.idSalePoint}
              data={{ checkTypes, salePoints, posInfo }}
              submit={async (values) => { await this.handleChange('check', values); this.handleNext(); }}
            />
          </CardContent>
          )}
          {index === 1 && (
          <TricycleForm
            initialVals={tricycle}
            tricycle={salePoints.filter((s) => s.idSalePoint === check.idSalePoint)[0]}
            onNext={async (values) => { await this.handleChange('tricycle', values); this.handleNext(); }}
            onPrev={async () => { await this.handleChange('check', this.checkInit); this.handlePrev(); }}
          />
          )}
          {index === 2 && (
          <CheckContainer
            storeId={check.idSalePoint}
            onPrev={this.handlePrev}
            onSubmit={(p, d) => this.handleSubmit(p, d)}
          />
          )}
        </Card>
      </div>
    );

    return (
      checkTypes && salePoints && posInfo && view
    );
  }
}

CheckView.propTypes = {
  userId: PropTypes.string.isRequired,
  idSalePoint: PropTypes.number.isRequired,
  idPos: PropTypes.number.isRequired,
  setStatus: PropTypes.func.isRequired,
  history: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => (
  {
    userId: state.session.id,
    idPos: state.session.info.defaultPOS ? state.session.info.defaultPOS.idPos : '',
    idSalePoint: state.session.info.defaultSalePoint ? state.session.info.defaultSalePoint.code : '',
  }
);

const mapDispatchToProps = (dispatch) => ({
  setStatus: (message) => dispatch({ type: 'ADD_STATUS', message }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckView));
