import React from 'react';
import { makeStyles } from '@material-ui/core/styles';



export default function CardFloatIcon({
  icon: Icon,
  iconStyles,
  background
}) {

const useStyles = makeStyles((theme) => ({
  card: {
    background: `linear-gradient(60deg, ${background[0]},${background[1]})`,
  },
}));
  const classes = useStyles();
  return (
    <div className={`${classes.card} CardFloatIcon`}>
      <Icon style={iconStyles} />
    </div>
  );
}
