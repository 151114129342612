import { apicenter } from '../../../data/apicenter';

export function getPromoClient(id, callback) {
  const link = `${apicenter.API}/Promo/GetPromoClientList/${id}`;
  const method = 'GET';
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };

  fetch(link, { method, headers })
    .then((res) => res.json())
    .then((res) => {
      if (callback) callback(res, true);
    })
    .catch((error) => {
      if (callback) callback(error, false);
    });
}
