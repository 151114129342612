import { apicenter } from "../../../data/apicenter";

export function getClient(id, callback) {
  const link = `${apicenter.API}/Clients/GetByID/${id}`;
  const method = "GET";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...apicenter.APIHEADER,
  };

  fetch(link, { method, headers })
    .then((res) => res.json())
    .then((res) => {
      if (callback) callback(res, true);
    })
    .catch((error) => {
      if (callback) callback(error, false);
    });
}

export async function getClientByCode(code, order = null) {
  console.log("order: " + order);
  const link = `${apicenter.API}/Orders/GetPendingOrder/${code}${(order)?`/${order}`:""}`;
  const method = 'GET';
  const headers = {
    Accept: 'application/json',
    // Content-Type: 'application/json',
    ...apicenter.APIHEADER,
  };

  return fetch(link, { method, headers })
    .then((res) => res.json())
    .catch(() => {
      console.log('error!');
    });
}
