import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import { postOrderSendNotification } from '../../../components/utils/api/Orders/postOrderSendNotification';

const IndexNotifyOutside = ({ setMessage, userId, client }) => {
  const [dialogShow, setDialogShow] = useState(false);

  // eslint-disable-next-line no-shadow
  const sendNotify = async (status, userId) => {
    if (status) {
      const req = {
        UserId: userId,
        Title: 'El pedido esta afuera',
        Body: `¡Hola ${client.split(' ')[0]}! El triciclo ya está afuera de tu hogar listo para atenderte.`,
      };
      const res = await postOrderSendNotification(req);
      console.log('postOrderSendNotification', { req, res, str: JSON.stringify(req) });
      if (res.error.errorCode === 0) {
        setDialogShow(false);
        setMessage('¡Mensaje enviado!');
      } else {
        alert('Error en la consulta');
      }
    } else {
      setDialogShow(false);
    }
  };

  return (
    <Grid item xs={12}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setDialogShow(true)}
      >
        Enviar notificación
        "Estoy afuera"
      </Button>

      <DialogQuestion open={dialogShow} handleClose={sendNotify} id={userId} />
    </Grid>
  );
};

const DialogQuestion = ({ open, handleClose, id }) => (
  <Dialog
    open={open}
    onClose={() => handleClose(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">¿Estás seguro?</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Enviaras una notificación al usuario avisandole que llegaste a su punto de entrega
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => handleClose(false)} color="primary">
        Cancelar
      </Button>
      <Button onClick={() => handleClose(true, id)} color="primary" autoFocus>
        Aceptar
      </Button>
    </DialogActions>
  </Dialog>
);


IndexNotifyOutside.propTypes = {
  setMessage: PropTypes.func.isRequired,
  userId: PropTypes.any.isRequired,
  client: PropTypes.any.isRequired,
};

DialogQuestion.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};


export default IndexNotifyOutside;
