import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Backdrop, makeStyles, Grid, Fade, CircularProgress, Button, CardContent, Typography, FormControlLabel, Checkbox,
} from '@material-ui/core/';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function ConfirmSaleModal({
  open, closeModal, onSale, carts,
}) {
  const classes = useStyles();

  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleChange = () => {
    setCheck(!check);
    setDisabled(!disabled);
  };
  const onClick = (number) => {
    setLoading(true);
    if (number === 1) onSale();
    if (number === 2) closeModal();
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title" style={{ marginTop: 20 }}>Confirmar compra</h2>
          <CardContent>
            <Typography style={{ width: '100%', marginBottom: 20 }} variant="h4">{`TOTAL: $${carts.totalAmountSale}`}</Typography>
            {carts.saleProductsDTO.length > 0
              && (
                <>
                  <Typography variant="body1">Productos</Typography>
                  {carts.saleProductsDTO.map((selectItem, index) => (
                    <>
                      <Typography variant="body1">{selectItem.name}</Typography>
                      <table style={{ width: '100%', marginBottom: 10 }}>
                        <tbody>
                          <tr>
                            <td>
                              <Typography variant="h4">Cantidad</Typography>
                            </td>
                            <td>
                              <Typography variant="h4" className="ml-0">{selectItem.count}</Typography>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ))}
                </>
              )}
            {carts.salePackageDTO.length > 0
              && (
                <>
                  <Typography variant="body1">Envases Retornable</Typography>
                  {carts.salePackageDTO.map((selectItem, index) => (
                    <>
                      <Typography variant="body1">{selectItem.name}</Typography>
                      <table style={{ width: '100%', marginBottom: 10 }}>
                        <tbody>
                          <tr>
                            <td>
                              <Typography variant="h4">Cantidad</Typography>
                            </td>
                            <td>
                              <Typography variant="h4" className="ml-0">{selectItem.count}</Typography>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ))}
                </>
              )}
            {carts.saleGiftPackage.length > 0
              && (
                <>
                  <Typography variant="body1">Envases de Regalo</Typography>
                  {carts.saleGiftPackage.map((selectItem, index) => (
                    <>
                      <Typography variant="body1">{selectItem.name}</Typography>
                      <table style={{ width: '100%', marginBottom: 10 }}>
                        <tbody>
                          <tr>
                            <td>
                              <Typography variant="h4">Cantidad</Typography>
                            </td>
                            <td>
                              <Typography variant="h4" className="ml-0">{selectItem.count}</Typography>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ))}
                </>
              )}
          </CardContent>
          <Grid style={{ width: '100%', marginBottom: 20 }}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={check}
                  onChange={handleChange}
                  name="checkedB"
                  color="primary"
                />
              )}
              label="Confirmo que la cantidad mostrada es la que se esta vendiendo "
            />
          </Grid>
          <Grid style={{ width: '100%' }}>
            {loading ? (
              <CircularProgress
                style={{ marginLeft: 20 }}
              />
            )
              : (
                <>
                  <Button disabled={!disabled} onClick={() => onClick(2)} variant="contained" style={{ marginRight: 20 }}>CANCELAR</Button>
                  <Button disabled={disabled} onClick={() => onClick(1)} variant="contained" color="primary">ACEPTAR</Button>
                </>
              )}
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
}

ConfirmSaleModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSale: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  carts: PropTypes.object.isRequired,
};

export default ConfirmSaleModal;
