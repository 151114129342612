import { apicenter } from '../../../../data/apicenter';

export async function postOrderSendNotification(req) {
  try {
    const link = `${apicenter.API}/Orders/SendNotification`;
    const method = 'POST';
    const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };
    const body = JSON.stringify(req);
    const response = await fetch(link, { method, headers, body });
    const data = await response.json();
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error POST postOrderSendNotification =>', { req, error });
    return null;
  }
}

/*
    {
      "UserId": 10705,
      "Title":"El pedido esta afuera",
      "Body":"Hola Misael, el triciclo de algramo ya esta listo para atenderte!"
    }
*/
