import { apicenter } from '../../../data/apicenter';

export function getCoupons() {
  const link = `${apicenter.API}/Promo/GetPromoVendor`;
  const method = 'GET';
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };
  return new Promise((resolve, reject) => fetch(link, { method, headers })
    .then(async (res) => {
      const data = await res.json();
      if (res.status === 200) {
        resolve(data.data);
      } else {
        reject(new Error(data.error.errorMessage));
      }
    })
    .catch(() => {
      reject(new Error('Error en el sistema'));
    }));
}

export function postCoupon(idPromo, userId, vendorId) {
  const link = `${apicenter.API}/Promo/InsertPromoVendor`;
  const method = 'POST';
  const body = JSON.stringify({
    idPromo, userId, vendorId, LNG: 'ES',
  });
  const headers = {
    Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER,
  };

  return new Promise((resolve, reject) => fetch(link, { method, headers, body })
    .then(async (res) => {
      const data = await res.json();
      if (data.error.errorCode !== 0) {
        const msg = JSON.stringify(data.error.errorMessage);
        reject(new Error(msg));
      } else {
        resolve();
      }
    })
    .catch(() => {
      reject(new Error('Error en el sistema'));
    }));
}
