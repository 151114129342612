export const editClient = (payload) => ({
  payload,
  type: 'editClient',
});

export const chargeBill = (payload) => ({
  payload,
  type: 'chargeBill',
});

export const orderStatus = (payload) => ({
  payload,
  type: 'orderStatus',
});

export const indexViewQuestion = (payload) => ({
  payload,
  type: 'indexViewQuestion',
});
