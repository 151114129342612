import axios from "axios";
import { apicenter } from "../../../data/apicenter";

export const getMyVehicle = async ({ id }) => {
  const link = `${apicenter.API}/Vendor/GetVendorVehicle/${id}`;
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...apicenter.APIHEADER,
  };
  const res = await axios.get(link, { headers });
  return res;
};
