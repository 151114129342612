import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper, InputLabel, Grid, CardContent, Typography, Input, makeStyles, useTheme, Chip, FormControl, Select, MenuItem, Button,
} from '@material-ui/core/';
import NumericInput from "./NumericInput";
const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 500,
      width: 400,
    },
  },
};

function getStyles(product, selectedList, theme) {
  return {
    fontWeight:
      selectedList.indexOf(product) === -1
        ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

function PackCard({
  products, selectedList, setSelectedList, title, subtitle, par,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const [ind, setInd] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setSelectedList(event.target.value);
  };

  const next = () => {
    setLoading(true);
    const prod = [];
    selectedList.map((element) => {
      const aux = products.find((product) => product.name === element);
      prod.push(aux);
    });
    setSelectedList(prod);
    setLoading(false);
    setInd(1);
  };
  
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4">{title}</Typography>
      </Grid>
      {ind === 0
      && (
        <Grid item xs={12}>
          <Paper style={{ marginBottom: 10 }}>
            <CardContent>
              {/* {error && <span name="Packages" component="span" className={classes.error}>{error}</span>} */}
              <Grid item xs={12} sm={6} style={{ marginTop: 15, marginBottom: 15 }}>
                <InputLabel>
                  {subtitle}
                </InputLabel>
                <FormControl>
                  <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={selectedList}
                    style={{ width: 250 }}
                    onChange={(e) => handleChange(e)}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} className={classes.chip} />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {products && products.map((product) => (
                      <MenuItem key={product.idProduct !== undefined ? product.idProduct : product.idPackage} value={product.name} style={getStyles(product, selectedList, theme)}>
                        {product.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={() => next()}
                  disabled={selectedList.length < 1}
                  variant="contained"
                  color="primary"
                >
                  Continuar
                </Button>
              </Grid>
            </CardContent>
          </Paper>
        </Grid>
      )}
      {ind === 1 && !loading
      && (
        <Grid item xs={12}>
          <Paper style={{ marginBottom: 10 }}>
            <CardContent>
              {selectedList.length > 0 && selectedList.map((selectItem, index) => (
                <NumericInput
                  selectItem={selectItem}
                  selectedList={selectedList}
                  setSelectedList={setSelectedList}
                  index={index}
                  par={par}
                />
              ))}
            </CardContent>
          </Paper>
        </Grid>
      )}
    </>

  );
}

PackCard.propTypes = {
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  par: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  selectedList: PropTypes.array.isRequired,
  setSelectedList: PropTypes.func.isRequired,
};

export default PackCard;
