import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import NewReleasesIcon from '@material-ui/icons/NewReleasesRounded';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import FaceIcon from '@material-ui/icons/Face';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));


const Tags = ({ order }) => {
  const classes = useStyles();
  const todayCreated = moment(order.createdAt).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
  const newClientIcon = order.isNewClient ? (
    <Chip
      className={classes.chip}
      label="Cliente nuevo"
      color="primary"
      variant="outlined"
      icon={<FaceIcon />}
    />
  ) : (
    <Chip
      className={classes.chip}
      label="Cliente Recompra"
      color="primary"
      variant="outlined"
      icon={<FaceIcon />}
    />
  );
  const newOrder = todayCreated && (
    <Chip
      className={classes.chip}
      label="Pedido creado hoy"
      color="primary"
      variant="outlined"
      icon={<NewReleasesIcon />}
    />
  );
  const userType = order.userType && (
    <Chip
      className={classes.chip}
      label={order.userType}
      color="primary"
      variant="outlined"
      icon={<PeopleAltRoundedIcon />}
    />
  );
  const paidOrder = order.useBalance && (
    <Chip
      className={classes.chip}
      label="Pedido pagado"
      color="primary"
      variant="outlined"
      icon={<MonetizationOnRoundedIcon />}
    />
  );


  const offline = order.isOfflineSale && (
    <Chip
      className={classes.chip}
      label="Venta offline"
      color="primary"
      variant="outlined"
      icon={<WifiOffIcon />}
    />
  );
  return (
    <Grid item xs={12}>
      {newClientIcon}
      {newOrder}
      {userType}
      {offline}
      {paidOrder}
    </Grid>
  );
};

Tags.propTypes = {
  order: PropTypes.object.isRequired,
};

export default Tags;
