import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { getIndicator } from '../utils/api/getGoals';
import { NumberCards } from './GoalsComponents/NumberCards';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

const GoalsView = ({ userId }) => {
  const classes = useStyles();

  const [indicatorsDate, setIndicatorsDate] = useState();
  const [dateInit, setDateInit] = useState('');
  const [dateFinish, setDateFinish] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [loadingData, setLoadingData] = useState(false);

  const [error, setError] = useState('');

  const changeDate = (e, num) => {
    if (e.target.value) {
      if (dateFinish === '' && dateInit === '') {
        if (num === 1) setDateInit(e.target.value);
        else if (num === 2) setDateFinish(e.target.value);
      } else if (dateInit === '') {
        if (num === 1) {
          if (dateFinish < e.target.value) {
            setError(`La fecha inicial debe ser igual o anterior a el ${dateFinish}`); 
            setDisabled(true)
          } else { setDateInit(e.target.value); setError(''); setDisabled(false); }
        } else if (num === 2) { setDateFinish(e.target.value); setError(''); setDisabled(false); }
      } else if (dateFinish === '') {
        if (num === 1) { 
          setDateInit(e.target.value); setError(''); setDisabled(false);
        } else if (num === 2) {
          if (dateInit > e.target.value) {
            setError(`La fecha de termino debe ser igual o posteriora a el ${dateInit}`);
            setDisabled(true); 
          } else { setDateFinish(e.target.value); setError(''); setDisabled(false); }
        }
      } else if (dateFinish !== '' && dateInit !== '') {
        if (num === 1) { 
          if (dateFinish < e.target.value) {
            setError(`La fecha de termino debe ser igual o posteriora a el ${dateFinish}`);
            setDisabled(true); 
          } else { setDateInit(e.target.value); setError(''); setDisabled(false); }
        } else if (num === 2) {
          if (dateInit > e.target.value) {
            setError(`La fecha de termino debe ser igual o posteriora a el ${dateInit}`);
            setDisabled(true); 
          } else { setDateFinish(e.target.value); setError(''); setDisabled(false);}
        }
      }

    }
  };

  const getData = () => {
    setLoadingData(true);
    getIndicator(userId, dateInit, dateFinish, (resOrderS, statusOrderS) => {
      if (statusOrderS) {
        setIndicatorsDate(resOrderS);
      }
      setLoadingData(false);
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ marginBottom: '5%' }}>
        <Typography variant="h3">Tus Métricas</Typography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: '2%' }}>
        <Typography variant="body1">Seleccione la fecha de las métricas que quiere revisar</Typography>
        {error && <Typography variant="body1" style={{ marginTop: '1%' }} className={classes.error}>{error}</Typography>}
      </Grid>
      <Grid item xs={6} style={{ marginBottom: '5%' }}>
        <form className={classes.container} noValidate>
          <TextField
            id="dateInit"
            label="Fecha inicio"
            type="date"
            className={classes.textField}
            onChange={(e) => changeDate(e, 1)}
            inputProps={{
              max: moment().format('YYYY-MM-DD'),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </form>
      </Grid>
      <Grid item xs={6} style={{ marginBottom: '5%' }}>
        <form className={classes.container} noValidate>
          <TextField
            id="dateFinish"
            label="Fecha termino"
            type="date"
            className={classes.textField}
            onChange={(e) => changeDate(e, 2)}
            inputProps={{
              max: moment().format('YYYY-MM-DD'),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </form>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: '5%' }}>
        <Button
          onClick={() => getData()}
          disabled={disabled}
          variant="contained"
          color="primary"
          style={{ marginBottom: 20, marginLeft: 20 }}
        >
          Aceptar
        </Button>
      </Grid>
      {indicatorsDate && (
        <NumberCards indicators={indicatorsDate} loading={loadingData} />
      )}
    </Grid>
  );
};


GoalsView.propTypes = {
  userId: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  userId: state.session.info.userId,
});

export default connect(mapStateToProps)(GoalsView);
