/* eslint no-unused-expressions: [2, { allowShortCircuit: true }] */
/*
    Ejemplo => https://tricicloapidev.azurewebsites.net/api/Orders/GetOrderStatus
*/

import { apicenter } from '../../../../data/apicenter';

export function getOrderStatus(callback) {
  const link = `${apicenter.API}/Orders/GetOrderStatus/`;
  const method = 'GET';
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };

  fetch(link, { method, headers })
    .then((res) => res.json())
    .then((res) => {
      callback && callback(res, true);
    })
    .catch((error) => {
      callback && callback(error, false);
    });
}
