
const getFormFields = (checks) => {
  const fields = {};
  Object.entries(checks).forEach((entry) => {
    const [key, value] = entry;
    if (!['dispensers', 'package', 'pos'].includes(key)) {
      const ks = Object.keys(value[0]).filter((v) => v !== 'description');
      const k = ks.length === 1 ? ks[0] : ks.filter((v) => v.includes('Detail'))[0];
      fields[k] = {
        key: k,
        type: k.includes('Detail') ? 'CHECKBOX' : 'SELECT',
        options: value,
      };
    }
  });
  return fields;
};

export default getFormFields;
