import React from 'react';
// import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
// import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { obtainLanguage } from '../../data/lang/obtainLanguage';
import * as actions from '../../redux/actions/app';
import phoneImg from '../../assets/account_verification.svg';
// import { Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  inputRoot: {
    width: '100%',
  },
  typography: {
    color: '#273746',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
  },
  imgWrapper: {
    display: 'flex',
    margin: '20px auto 20px auto',
    // width: '70%',
  },
  button: {
    minWidth: '200px',
    margin: 'auto',
    width: '20vw',

  },
  phoneImg: {
    minWidth: '200px',
    margin: 'auto',
    width: '25vw',

  },
}));
function CollectView() {
  const history = useHistory();
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.typography} style={{ marginBottom: 10 }} variant="h4">{obtainLanguage.collectHome.title}</Typography>
      <Typography className={classes.typography} style={{ fontWeight: 500 }} variant="body1">{obtainLanguage.collectHome.search}</Typography>
      <div className={classes.imgWrapper}>
        <img className={classes.phoneImg} src={phoneImg} alt="" />
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          className={classes.button}
          style={{ marginTop: 15 }}
          variant="contained"
          color="primary"
          onClick={() => {
            history.push('/packcollectQR');
          }}
        >
          Escanear QR
        </Button>
        <Button
          className={classes.button}
          style={{ marginTop: 15 }}
          variant="contained"
          color="primary"
          onClick={() => {
            history.push('/packcollect');
          }}
        >
          Ingresar código
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  app: state.app,
});

export default connect(mapStateToProps, actions)(CollectView);
