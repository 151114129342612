import { apicenter } from "../../../data/apicenter";

export function getInZone({ lat, lng, idCountry }, callback) {
  const link = `${apicenter.API}/Address/InZone/${lat}/${lng}/${idCountry}`;
  const method = "GET";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...apicenter.APIHEADER,
  };

  fetch(link, { method, headers })
    .then((res) => res.json())
    .then((res) => {
      callback && callback(res, true);
    })
    .catch((error) => {
      console.log("Error GET getInZone =>", { error });
      callback && callback(error, false);
    });
}

export async function getInZoneAsync({ lat, lng, idCountry }) {
  const link = `${apicenter.API}/Address/InZone/${lat}/${lng}/${idCountry}`;
  const method = "GET";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...apicenter.APIHEADER,
  };
  const response = await fetch(link, { method, headers });
  const data = await response.json();
  console.log("getInZoneAsync", link);
  return data;
}
