import { takeLatest, put } from 'redux-saga/effects';
import { getOrders } from './orders/get';

function* obtainOrders({ payload }) {
  try {
    const result = yield getOrders(payload);
    if (result.data.error.errorCode !== 0) {
      if (result.data.error.errorCode === 1) {
        yield put({
          type: 'GET_ORDERS_BY_DATE',
          payload: {
            zoneActive: false,
            list: [],
          },
        });
      } else {
        yield put({ type: 'FAILED_ANY_FETCH', payload: 'Error al obtener Ordenes por Fecha' });
      }
    } else {
      yield put({
        type: 'GET_ORDERS_BY_DATE',
        payload: {
          zoneActive: true,
          list: result.data.data,
        },
      });
    }
  } catch (error) {
    yield put({ type: 'FAILED_ANY_FETCH', payload: 'Error al obtener Ordenes por Fecha' });
    console.log('Error GET_ORDERS_BY_DATE', { error, payload });
  }
}

export default function* getOrdersByDate() {
  yield takeLatest('LAUNCH_GET_ORDERS_BY_DATE', obtainOrders);
}
