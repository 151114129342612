import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  InputLabel, Grid, Typography, Input,
} from '@material-ui/core/';

function NumericInput({
  selectItem, selectedList, setSelectedList, index, par,
}) {

  const [cart, setCart] = useState(1);

  const updatePackages = (value) => {
    if ( value >= 0 ) {
      const elem = selectedList;
      elem[index].count = parseInt(value, 10);
      setSelectedList(elem);
      setCart(parseInt(value, 10));
    }
  };

  return (
    <>
      <Grid item key={selectItem.idProduct !== undefined ? selectItem.idProduct : selectItem.idPackage} xs={12} sm={6}>
        <Typography variant="h6">
          {selectItem.name}
        </Typography>
        <InputLabel key={selectItem.idProduct !== undefined ? selectItem.idProduct : selectItem.idPackage} style={{ marginTop: 10, marginBottom: 10 }}>
          Seleccione la cantidad de
          <b>{` ${par}`}</b>
        </InputLabel>
        <Input
          value={cart}
          key={selectItem.idProduct !== undefined ? selectItem.idProduct : selectItem.idPackage}
          type="number"
          onChange={(e) => updatePackages(e.target.value)}
          style={{ width: '100%', marginBottom: 10 }}
        />
      </Grid>

    </>

  );
}

NumericInput.propTypes = {
  selectItem: PropTypes.object.isRequired,
  selectedList: PropTypes.array.isRequired,
  setSelectedList: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  par: PropTypes.string.isRequired,
};


export default NumericInput;
