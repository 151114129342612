import { apicenter } from '../../../data/apicenter';

function getCheckInfo(storeId, callback) {
  const link = `${apicenter.API}/Check/GetCheck/${storeId}`;
  const method = 'GET';
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };

  fetch(link, { method, headers })
    .then((res) => res.json())
    .then((res) => {
      if (callback) callback(res, true);
    })
    .catch((error) => {
      if (callback) callback(error, false);
    });
}

function getCheckTypes(callback) {
  const link = `${apicenter.API}/Check/GetCheckType`;
  const method = 'GET';
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };

  fetch(link, { method, headers })
    .then((res) => res.json())
    .then((res) => {
      if (callback) callback(res, true);
    })
    .catch((error) => {
      if (callback) callback(error, false);
    });
}

function getPos(callback) {
  const link = `${apicenter.API}/Check/GetPos`;
  const method = 'GET';
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };

  fetch(link, { method, headers })
    .then((res) => res.json())
    .then((res) => {
      if (callback) callback(res, true);
    })
    .catch((error) => {
      if (callback) callback(error, false);
    });
}

export { getCheckInfo, getCheckTypes, getPos };
