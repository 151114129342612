import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {
  useParams,
} from 'react-router-dom';
import {
  Typography, CircularProgress,
} from '@material-ui/core';
import InfoReduced from './InfoReduced';
import PromoTable from './PromoTable';
import SelectPromo from './SelectPromo';
import Modal from './Modal';
import { getPromoClient } from '../../components/utils/api/getPromoClient';
import { getClient } from '../../components/utils/api/getClient';
import { getPromos } from '../../components/utils/api/getPromos';

const Promo = ({
  app, vendorId, setMessage, history,
}) => {
  const [modal, setModal] = useState(false);
  const [promosClient, setPromosClient] = useState(null);
  const [promoList, setPromoList] = useState([]);
  const [clientInfo, setClientInfo] = useState(null);
  const { clientId } = useParams();

  const handleObtainPromosClient = (id) => {
    getPromoClient(id, (res, status) => {
      if (status) {
        setPromosClient(res.data);
      }
    });
  };

  const handleObtainInfoClient = (id) => {
    getClient(id, (res, status) => {
      if (status) {
        setClientInfo(res.data.clientData);
      }
    });
  };

  const handleObtainPromosList = () => {
    getPromos( (res, status) => {
      if (status) {
        setPromoList(res.data);
      }
    });
  };
  const handleObtainAll = (id) => {
    handleObtainPromosClient(id);
    handleObtainInfoClient(id);
    handleObtainPromosList();
  };

  useEffect(() => {
    if (clientId) {
      handleObtainAll(clientId);
    } else {
      setMessage('Cliente no encontrado');
      history.push('/');
    }
  }, []);

  const { chargeBill } = app;
  const dataFront = [
    {
      show: clientInfo !== null,
      title: 'Información de cliente',
      Content: InfoReduced,
      data: clientInfo,
    },
    {
      show: clientInfo !== null,
      title: 'Asignar promociones',
      Content: SelectPromo,
      data: {promoList, clientInfo, handleObtainPromosClient},
    },
    {
      show: promosClient !== null,
      title: 'Promociones del Cliente',
      Content: PromoTable,
      data: promosClient,
    },
  ];

  return (
    <div>
      <Modal
        open={modal}
        closeModal={() => setModal(false)}
        handleObtainAll={() => handleObtainAll(clientId)}
        info={{
          ...chargeBill,
          id: vendorId,
          userId: clientId,
        }}
      />
      <Grid container spacing={1}>
        {
            dataFront.map(({
              title, Content, data, show,
            }) => (
              <Grid container key={title} spacing={1} style={{ marginTop: 5, marginBottom: 10 }}>
                <Typography variant="h2">{title}</Typography>
                <Grid container spacing={1} style={{ marginTop: 8 }}>
                  {show ? <Content data={data} /> : <CircularProgress />}
                </Grid>
              </Grid>
            ))
          }
      </Grid>
    </div>
  );
};

Promo.propTypes = {
  setMessage: PropTypes.func.isRequired,
  vendorId: PropTypes.string.isRequired,
  app: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
  vendorId: state.session.info.userId,
});

const mapDispatchToProps = (dispatch) => ({
  setMessage: (message) => dispatch({ type: 'ADD_STATUS', message }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Promo);
