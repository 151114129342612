import { apicenter } from "../../../../data/apicenter";

export async function getGeocode(address) {
  const language = "es-419";
  try {
    const link = `${apicenter.API}/Clients/GetGoogleGeoCode/${address}/${language}`;
    const method = "GET";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...apicenter.APIHEADER,
    };

    const response = await fetch(link, { method, headers });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error GET getGeocode =>", { error });
    return null;
  }
}
