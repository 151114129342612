const defaultState = {
  vehicles: [],
};

export function vehicles(state = defaultState, action) {
  switch (action.type) {
    case "GET_ALL_VEHICLE":
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
