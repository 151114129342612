import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Input } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import RoundIconButton from '../../../components/globals/Buttons/RoundIconButton';

const useStyles = makeStyles({
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  input: {
    width: '85%',
  },
});

const Comments = ({ order, onAddComment }) => {
  const classes = useStyles();
  const [enabledEdit, setEnabledEdit] = useState(false);
  const [newComment, setNewComment] = useState(order.vendorComment);

  const buttonText = order.vendorComment
    ? 'Editar comentario'
    : 'Agregar comentario';
  return (
    <Grid item xs={12}>
      {order.comment && (
        <div>
          <Typography variant="caption">
            <b>Comentario Admin:</b>
          </Typography>
          <Typography variant="body2">{order.comment}</Typography>
        </div>
      )}
      {order.vendorComment && !enabledEdit && (
        <div>
          <Typography variant="caption">
            <b>Comentario Vendedor:</b>
          </Typography>
          <Typography variant="body2">{order.vendorComment}</Typography>
        </div>
      )}
      {enabledEdit && (
        <div className={classes.inputContainer}>
          <Input
            className={classes.input}
            value={newComment}
            placeholder="Escribe un comentario"
            type="text"
            onChange={(e) => setNewComment(e.target.value)}
          />
          <RoundIconButton
            onClick={() => {
              setEnabledEdit(false);
              onAddComment({
                UserId: order.userId,
                VComment: newComment,
                OrderNumber: order.orderNumber,
              });
            }}
          >
            <Check />
          </RoundIconButton>
        </div>
      )}
      {!enabledEdit && (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setEnabledEdit(true)}
        >
          {buttonText}
        </Button>
      )}
    </Grid>
  );
};

Comments.propTypes = {
  order: PropTypes.object.isRequired,
  onAddComment: PropTypes.func.isRequired,
};


export default Comments;
