import React from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Fn } from '../../components/utils/Fn';


const useStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderColor: theme.palette.primary.main,
    border: '1px solid',
  },
}));


const SeeClientHistory = ({ data }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} style={{ marginBottom: 100 }}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.head}>Descripción</TableCell>
            <TableCell className={classes.head}>Fecha</TableCell>
            <TableCell className={classes.head}>Monto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? data.map((row) => (
            <TableRow key={`${row.description}${row.date}`}>
              <TableCell>{row.description}</TableCell>
              <TableCell>{moment(row.date).format('DD/MM/YYYY - HH:MM')}</TableCell>
              <TableCell>
                $
                {Fn.separadorMiles(row.amount)}
              </TableCell>
            </TableRow>
          )) : <TableRow><Typography><TableCell align="right">No hay datos</TableCell></Typography></TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

SeeClientHistory.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SeeClientHistory;
