/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getAllVehicle } from '../../redux/actions/vehicle';
import { getPitPoints } from '../../components/utils/api/getPitPoints';
import Map from './Views/Map';
import List from './Views/List';

const IndexView = ({ history, }) => {
  const [view, setView] = useState(1);
  const [tab, setTab] = useState(0);
  const [pitPoints, setPitPoints] = useState([]);


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllVehicle({payload:{}}));
    getPitPoints(async (res, status) => {
      if (status) {
        await setPitPoints(res.data);
      }
    });
  }, []);

  return (
    <div>
      {
        view
          ? <List setView={setView} history={history} tab={tab} setTab={setTab}/>
          : <Map setView={setView} tab={tab} pitPoints={pitPoints}/>
      }

    </div>
  );
};

IndexView.propTypes = {
  history: PropTypes.any.isRequired,
};


const mapStateToProps = (state) => ({
  orderNumber: state.app.indexViewQuestion.orderNumber,
  statusId: state.app.indexViewQuestion.status,
});


const mapDispatchToProps = (dispatch) => ({
  orderStatus: (payload) => dispatch({ type: 'orderStatus', payload }),
});


export default connect(mapStateToProps, mapDispatchToProps)(IndexView);
