const defaultState = {
  message: '',
};

export function status(state = defaultState, { message, type }) {
  switch (type) {
    case 'ADD_STATUS':
      return { message };
    default:
      return state;
  }
}
