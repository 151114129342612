/*
    Ejemplo => http://devalgramoapi.azurewebsites.net/api/Clients/Edit
*/

import { apicenter } from '../../../data/apicenter';

export async function postSetPromoClientList(req, callback) {
  const link = `${apicenter.API}/Promo/SetPromoClientList/${req.userId}`;

  const method = 'POST';
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };
  const body = JSON.stringify(req.promoList);

  fetch(link, { method, headers, body })
    .then((res) => res.json())
    .then((res) => {
      if (res.error.errorCode === 0) {
        if (callback) callback(res, true, '');
      } else if (callback) callback(res, false, res.error.errorMessage);
    })
    .catch((error) => {
      if (callback) callback(error, false, 'Error en la consulta');
    });
}
