/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  GoogleApiWrapper, Map, Marker,
} from 'google-maps-react';
import {
  Fab, makeStyles, useTheme, Button, Typography, MobileStepper, Grid, Avatar, Popover, ButtonGroup, Chip,
} from '@material-ui/core';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import FilterListIcon from '@material-ui/icons/FilterList';
import OpenInNew from '@material-ui/icons/OpenInNew';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { apicenter } from '../../../data/apicenter';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: 'block',
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
  },
}));


const IndexMap = ({
  google, orderStatusList, tab, setView, listOrders, unassignedListOrders, listOrdersOthersVehicles, pitPoints,
}) => {
  const orderData = () => {
    const newData = [];
    listOrders.map((order) => { // en camino
      if (order.status === 4) {
        newData.push(
          order,
        );
      }
      return true;
    });
    listOrders.map((order) => { // procesado
      if (order.status === 0) {
        newData.push(
          order,
        );
      }
      return true;
    });
    listOrders.map((order) => { // entregado
      if (order.status === 2) {
        newData.push(
          order,
        );
      }
      return true;
    });
    listOrders.map((order) => { // Cancelado
      if (order.status === 5) {
        newData.push(
          order,
        );
      }
      return true;
    });
    listOrders.map((order) => { // Cancelado por Algramo
      if (order.status === 3) {
        newData.push(
          order,
        );
      }
      return true;
    });
    return newData;
  };
  const [geo, setGeo] = useState('');
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [menuFilter, setMenuFilter] = useState(false);
  const [filterSelect, setFilterSelect] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [repeats, setRepeats] = useState([]);
  const [showPitPoints, setShowPitPoints] = useState(false);
  const [activePit, setActivePit] = useState(false);
  const [openPit, setOpenPit] = useState(false);
  const [orders] = useState(orderData());

  const obtainFilterSelect = () => {
    const list = [];
    orderStatusList.map((i) => {
      list.push({
        id: i.idOrderStatus,
        description: i.description,
        show: true,
        orders: orders.filter((e) => e.status === i.idOrderStatus),
      });
    });
    setFilterSelect(list);
  };

  useEffect(() => {
    if (orders.length > 0) {
      setLat(orders[activeStep].lat);
      setLng(orders[activeStep].lng);
    }
    navigator.geolocation.getCurrentPosition((position) => {
      setGeo(position.coords);
    });

    obtainFilterSelect();
  }, [orders]);

  useEffect(() => {
    const send = [];
    filterSelect.map((e) => {
      if (e.show) {
        e.orders.map((i) => {
          send.push(i);
        });
      }
    });
    setFilterData(send);
  }, [filterSelect]);

  const handleGeo = () => {
    setLat(geo.latitude);
    setLng(geo.longitude);
  };

  const obtainRepeats = () => {
    const all = [];
    const send = [];
    filterData.map((i) => {
      const finder = all.find((e) => e.lat === i.lat && e.lng === i.lng);
      if (finder) {
        send.push({
          lat: i.lat,
          lng: i.lng,
          orders: filterData.filter((e) => e.lat === i.lat && e.lng === i.lng),
        });
      } else {
        all.push(i);
      }
    });
    setRepeats(send);
  };

  useEffect(() => {
    if (filterData.length > 0) obtainRepeats();
  }, [filterData]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      const next = prevActiveStep + 1;
      setLat(orders[next].lat);
      setLng(orders[next].lng);
      return next;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      const next = prevActiveStep - 1;
      setLat(orders[next].lat);
      setLng(orders[next].lng);
      return next;
    });
  };

  const handleStepChange = (key) => {
    setActiveStep(key);
    setLat(orders[key].lat);
    setLng(orders[key].lng);
  };
  const openPitInfo = (pit) => {
    setLat(pit.lat);
    setLng(pit.long);
    setActivePit(pit);
    setOpenPit(true);
  };
  const statusPin = {
    0: 'procesado',
    4: 'encamino',
    3: 'cancelado',
    1: 'cancelado',
    6: 'cancelado',
    2: 'entregado',
    5: 'cancelado',
  };
  const activeRepeat = repeats.find((i) => i.lat === lat && i.lng === lng);
  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      <Map
        google={google}
        zoom={14}
        onDragend={(res) => {
          setLat(res.center.lat);
          setLng(res.center.lng);
        }}
        center={{
          lat, lng,
        }}
        fullscreenControl={false}
        streetViewControl={false}
        mapTypeControl={false}
      >
        {showPitPoints && (
          pitPoints.map((i) => (
            <Marker
              onClick={() => openPitInfo(i)}
              position={{ lat: i.lat, lng: i.long }}
              icon={{
                url: 'https://tricicloblobprod.blob.core.windows.net/images/pin/pin_bathroom.png',
                scaledSize: new google.maps.Size(40, 73),
              }}
            />
          ))

        )}
        {
          orders.map((i, key) => (
            <Marker
              onClick={() => handleStepChange(key)}
              position={{ lat: i.lat, lng: i.lng }}
              icon={{
                url: `https://tricicloblobprod.blob.core.windows.net/images/pin/pin_${statusPin[i.status]}.png`,
                scaledSize: new google.maps.Size(40, 73),
              }}
            />
          ))
        }
        <Marker
          position={{ lat: geo.latitude, lng: geo.longitude }}
          icon={{
            url: 'https://tricicloblobprod.blob.core.windows.net/images/pin/pin_yo.png',
            scaledSize: new google.maps.Size(40, 73),
          }}
        />
      </Map>

      {openPit ? (
        <div className="IndexStepper">
          <PitPoints pit={activePit} handleClose={() => setOpenPit(false)} />
        </div>
      ) : (
        orders.length > 0 && (
          <div className="IndexStepper">
            <Stepper data={orders} activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} handleStepChange={handleStepChange} />
          </div>
        )
      )}

      <Fab color="primary" aria-label="add" className="fabCenter" onClick={() => setView(1)}>
        <CloseIcon style={{ marginRight: 5 }} />
      </Fab>

      <Fab color="primary" aria-label="add" className="fabLocation" onClick={handleGeo}>
        <MyLocationIcon style={{ marginRight: 5 }} />
      </Fab>
      {/*
      <Fab color="primary" aria-label="add" className="fabFilter" onClick={() => setMenuFilter(true)}>
        <FilterListIcon style={{ marginRight: 5 }} />
      </Fab> */}

      <Fab color="primary" aria-label="add" className="fabFilter" onClick={() => setShowPitPoints(!showPitPoints)} style={{ marginTop: 50 }}>
        <img src="https://tricicloblobprod.blob.core.windows.net/images/pin/snorlax-icon-29.jpeg" alt="..." style={{ marginRight: 5, height: 50, width: 'auto' }} />
      </Fab>
      {activeRepeat && <MenuRepeats data={activeRepeat} active={filterData[activeStep]} setActiveStep={setActiveStep} allOrders={filterData} />}

      <MenuFilter menu={{
        menuFilter, setMenuFilter, filterSelect, setFilterSelect, setActiveStep,
      }}
      />

    </div>
  );
};

const PitPoints = ({
  pit, handleClose,
}) => {
  const classes = useStyles();
  return (
    <div key={pit.nameRestPoint} className={classes.root}>
      <div key={pit.nameRestPoint} className="IndexStepp-order">
        <Grid container spacing={1}>
          <Grid xs={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            <Typography variant="h6" style={{ marginLeft: 10 }}><b>{pit.nameRestPoint}</b></Typography>
          </Grid>
          <Grid xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            <Fab size="small" color="primary" onClick={handleClose}>
              <CloseIcon size="small" style={{ marginRight: 5 }} />
            </Fab>
          </Grid>
          {pit.reference && (
            <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="caption">Referencia: </Typography>
                {' '}
              <Typography variant="body1" style={{ marginLeft: 10 }}><b>{pit.reference}</b></Typography>
            </Grid>
          )}
          {pit.phoneOwner && (
            <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="caption">Teléfono: </Typography>
              {' '}
              <Typography variant="body1" style={{ marginLeft: 10 }}>
                <b>
                  <a href={`tel:+56${pit.phoneOwner}`}>
                    +56
                    {pit.phoneOwner}
                  </a>
                </b>
              </Typography>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

const Stepper = ({
  data, activeStep, handleNext, handleBack, handleStepChange,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const maxSteps = data.length;

  const openLocation = (lat, long) => {
    if (navigator.platform === 'iPhone' || navigator.platform === 'iPad' || navigator.platform === 'iPod' || navigator.platform === 'Android') {
      window.open(`maps://maps.google.com/maps?daddr=${lat},${long}&amp;ll=`);
    } else {
      window.open(`https://maps.google.com/maps?daddr=${lat},${long}&amp;ll=`);
    }
  };

  return (
    <div className={classes.root}>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {data.map((step, index) => (
          <div key={step.clientName} className="IndexStepp-order">
            <Grid container spacing={1}>
              <Grid xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar alt="Remy Sharp" src={step.imageSrc} />
                <Typography variant="h6" style={{ marginLeft: 10 }}><b>{step.clientName}</b></Typography>
              </Grid>
              <Grid xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography style={{ opacity: 0.4 }} variant="h6">
                  #
                  {step.orderNumber}
                </Typography>
              </Grid>
              <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="caption">Estado pedido:</Typography>
                {' '}
                <Typography variant="body1" style={{ marginLeft: 10 }}><b>{step.statusDescription}</b></Typography>
              </Grid>
              {/* <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="caption">Hora de entrega:</Typography>
                {' '}
                <Typography variant="body1" style={{ marginLeft: 10 }}><b>{step.block}</b></Typography>
              </Grid> */}
              <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="caption">Dirección: </Typography>
                {' '}
                <Typography variant="body1" style={{ marginLeft: 10, marginRight: 10 }}>
                  <b>{step.address}</b>
                </Typography>
                <Fab aria-label="open" style={{ height: 20, width: 45 }} onClick={() => { openLocation(step.lat, step.lng); }}>
                  <OpenInNew style={{ height: 16, width: 16 }} />
                </Fab>
              </Grid>
              <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="caption">Teléfono: </Typography>
                {' '}
                <Typography variant="body1" style={{ marginLeft: 10 }}>
                  <b>
                    <a href={`tel:+56${step.phone}`}>
                      +56
                      {step.phone}
                    </a>
                  </b>
                </Typography>
              </Grid>
            </Grid>

          </div>
        ))}
      </SwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={(
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        )}
        backButton={(
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        )}
      />
    </div>
  );
};

const MenuFilter = ({ menu }) => {
  const {
    menuFilter, setMenuFilter, filterSelect, setFilterSelect, setActiveStep,
  } = menu;

  const toggleButton = (id) => {
    const obj = Object.assign([], filterSelect);
    const key = obj.findIndex((i) => i.id === id);
    obj[key].show = !filterSelect[key].show;
    setFilterSelect(obj);
    setActiveStep(0);
  };

  return (
    <Popover
      id="menu-filter"
      open={Boolean(menuFilter)}
      anchorEl={menuFilter}
      onClose={() => setMenuFilter(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className="fabFilter-container">
        <Typography variant="h6">Filtro</Typography>
        <ButtonGroup
          orientation="vertical"
          color="primary"
          aria-label="vertical outlined primary button group"
        >
          {
            filterSelect.map((i) => <Button variant={i.show && 'contained'} onClick={() => toggleButton(i.id)}>{i.description}</Button>)
          }
          {/* <Button>Two</Button>
          <Button>Three</Button> */}
        </ButtonGroup>
      </div>
    </Popover>
  );
};

const MenuRepeats = ({
  data, active, setActiveStep, allOrders,
}) => (
  <div style={{
    position: 'absolute',
    top: '79px',
    right: '0px',
  }}
  >
    <Grid container spacing={1}>
      {
          data.orders.map((i) => {
            const isActive = active ? i.orderNumber === active.orderNumber : false;
            const finIndex = allOrders.findIndex((e) => e.orderNumber === i.orderNumber);
            return (
              <Grid item xs={12}>
                <Chip
                  onClick={() => setActiveStep(finIndex)}
                  color={isActive ? 'primary' : ''}
                  avatar={<Avatar alt="user" src={i.imageSrc} />}
                  label={`${i.clientName} | ${i.orderNumber}`}
                />
              </Grid>
            );
          })
        }
    </Grid>
  </div>
);

IndexMap.propTypes = {
  google: PropTypes.object.isRequired,
  tab: PropTypes.number.isRequired,
  listOrders: PropTypes.array.isRequired,
  unassignedListOrders: PropTypes.array.isRequired,
  listOrdersOthersVehicles: PropTypes.array.isRequired,
  orderStatusList: PropTypes.array.isRequired,
  setView: PropTypes.func.isRequired,
  pitPoints: PropTypes.array.isRequired,
};

MenuRepeats.propTypes = {
  data: PropTypes.array.isRequired,
  active: PropTypes.any.isRequired,
  setActiveStep: PropTypes.any.isRequired,
  allOrders: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  orderStatusList: state.app.orderStatus,
  listOrders: state.ordersByVehicle.listOrders ? state.ordersByVehicle.listOrders : [],
  unassignedListOrders: state.unassignedOrders.unassignedListOrders ? state.unassignedOrders.unassignedListOrders : [],
  listOrdersOthersVehicles: state.listOrdersOthersVehicles.listOrdersOthersVehicles ? state.listOrdersOthersVehicles.listOrdersOthersVehicles : [],
});

export default connect(mapStateToProps)(GoogleApiWrapper({
  apiKey: apicenter.GOOGLEKEY,
})(IndexMap));
