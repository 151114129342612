/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route, Redirect,
} from 'react-router-dom';


function PrivateRoute({
  isAuthenticated, children, ...rest
}) {
  if (isAuthenticated) {
    return <Route {...rest} />;
  }
  return <Route render={() => <Redirect to="/login" />} />;
}

PrivateRoute.propTypes = {
  children: PropTypes.any,
  component: PropTypes.any,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({ isAuthenticated: state.session.isAuthenticated });


export default connect(mapStateToProps)(PrivateRoute);
