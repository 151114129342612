import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QrReader from 'react-qr-reader';
import { isEmpty } from 'lodash';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';
import ValidationContainer from './ValidationContainer';
import { postPayment } from '../../components/utils/api/Sale/payment';
import { postPack } from '../../components/utils/api/Sale/pack';
import ResultCard from './ResultCard';


class OfflineView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sale: {},
      showToken: false,
      loadingSale: false,
      isValidSale: false,
      validSaleData: {},
    };

    this.handleScan = this.handleScan.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleIsValidSale = this.handleIsValidSale.bind(this);
    this.handleValidData = this.handleValidData.bind(this);
    this.handleSale = this.handleSale.bind(this);
    this.generateToken = this.generateToken.bind(this);
  }

  componentDidMount() {
    navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: 'environment',
      },
    });
  }

  handleScan(data) {
    if (data) {
      this.setState({
        sale: JSON.parse(data),
      });
    }
  }

  handleError() {
    const { setStatus } = this.props;
    setStatus('Tienes que dar permiso al browser para abrir la cámara');
  }

  handleIsValidSale(validation) {
    this.setState({
      isValidSale: validation,
    });
  }

  handleValidData(data) {
    this.setState({
      validSaleData: data,
    });
  }

  handleSale() {
    const { validSaleData, sale } = this.state;
    const { setStatus } = this.props;
    if (!isEmpty(validSaleData)) {
      this.setState({ loadingSale: true });
      if (isEmpty(validSaleData.pack)) {
        postPack(sale.identifier, sale.prodid, validSaleData.user.userId, sale.idPackage, (ok) => {
          if (ok) {
            postPayment(
              validSaleData.user, validSaleData.product, sale, validSaleData.order, (okResp) => {
                if (okResp) {
                  this.setState({ showToken: true, loadingSale: false });
                  setStatus('Venta realizada exitosamente');
                } else {
                  this.setState({ showToken: false, loadingSale: false });
                  setStatus('Error al realizar la venta, intente nuevamente.');
                }
              },
            );
          } else {
            this.setState({ showToken: false, loadingSale: false });
            setStatus('Error al realizar la venta, intente nuevamente.');
          }
        });
      } else {
        postPayment(validSaleData.user, validSaleData.product, sale, validSaleData.order, (ok) => {
          if (ok) {
            this.setState({ showToken: true, loadingSale: false });
            setStatus('Venta realizada exitosamente');
          } else {
            setStatus('Error al realizar la venta, intente nuevamente.');
            this.setState({ showToken: false, loadingSale: false });
          }
        });
      }
    }
  }

  generateToken() {
    const { sale } = this.state;
    let token = '';
    if (sale) {
      const { identifier } = sale;
      token = identifier.slice(-8).replace(':', '').replace(':', '').toUpperCase();
    }
    return token;
  }

  render() {
    const {
      sale, isValidSale, showToken, loadingSale, validSaleData,
    } = this.state;
    return (
      <Grid container spacing={2}>
        {isEmpty(sale) && (
          <>
            <Grid item xs={12}>
              <Typography variant="h2">Lectura QR</Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <QrReader
                  delay={300}
                  onError={this.handleError}
                  onScan={this.handleScan}
                  style={{ width: '80%', margin: 'auto' }}
                />

              </Paper>
            </Grid>
          </>
        )}
        {!isEmpty(sale) && (
          <ValidationContainer
            sale={sale}
            onIsValidSale={this.handleIsValidSale}
            onValidData={this.handleValidData}
          />
        )}
        {!isEmpty(sale) && (
          <ResultCard
            isValidSale={isValidSale}
            loadingSale={loadingSale}
            onSale={this.handleSale}
            data={validSaleData}
            sale={sale}
            token={this.generateToken()}
            showToken={showToken}
          />
        )}
      </Grid>

    );
  }
}
OfflineView.propTypes = {
  setStatus: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setStatus: (message) => dispatch({ type: 'ADD_STATUS', message }),
});

export default connect(null, mapDispatchToProps)(OfflineView);
