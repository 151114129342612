import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const CardFloatTitle = ({
  children, primaryColor, secondaryColor,
}) => {
  const useStyles = makeStyles((theme) => ({
    card: {
      background: `linear-gradient(20deg, ${primaryColor || 'gray'}, ${secondaryColor || 'gray'})`,
      color: theme.palette.primary.contrastText,
    },
  }));

  const classes = useStyles();
  return (
    <div className={`CardFloatTitle ${classes.card}`}>
      {children}
    </div>
  );
};


CardFloatTitle.propTypes = {
  children: PropTypes.any.isRequired,
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
};


export default CardFloatTitle;
