import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Select,
  MenuItem,
  Switch,
} from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AddBox from '@material-ui/icons/AddBox';
import IndeterminateCheckBox from '@material-ui/icons/IndeterminateCheckBox';
import Edit from '@material-ui/icons/Edit';
import CardFloatIcon from '../../../components/ui/Card/CardFloatIcon';
import Tags from './Tags';
import Comments from './Comments';
import { Fn } from '../../../components/utils/Fn';
import IndexNotifyOutside from './NotifyOutside';

const IndexLastOrder = ({
  data, orderStatus, setIndexViewQuestion,
  setQuestion, editClient, history, onOfflineSale, onAddComment,
  setQuestionGrey, setMessage, own,
}) => {
  const {
    imageSrc, clientName, phone, contact, address, addrRef, orderPackages,
    status, orderNumber, credits, total, userId, origin, deliveryCost, totalDscto,
    lat, lng, isOfflineSale, useBalance, orderProducts, statusDescription,
  } = data;

  const [open, setOpen] = useState(false);

  const addressLink = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

  const handleChangeStatus = (value) => {
    const statusId = value.target.value;
    setIndexViewQuestion({
      orderNumber,
      status: statusId,
    });
    setQuestion(true);
    // eslint-disable-next-line no-unused-expressions
    statusId === 1
      ? setQuestionGrey(true)
      : setQuestion(true);
  };

  const handleEditClient = (_userId) => {
    editClient({ userId: _userId });
    history.push(`client/${_userId}`);
  };

  const saldoSuficiente = useBalance || ((total <= credits) && !useBalance);
  return (
    <div>
      <Card className="CardFloatIcon-contain" style={{ marginBottom: 50 }}>
        <CardFloatIcon icon={LocalShippingIcon} iconStyles={{ color: 'white' }} background={['#00B3FF', '#96C5DA']} />
        <CardContent style={{ textAlign: 'left' }}>
          {own ? (
            <>
              <Typography variant="h6" style={{ opacity: 0.4, textAlign: 'right' }}>Siguiente pedido</Typography>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant="body1" style={{ opacity: 0.4, marginRight: 10 }}>
                  <b>
                    #
                    {orderNumber}
                  </b>
                </Typography>
                <Edit fontSize="small" onClick={() => handleEditClient(userId)} style={{ marginRight: 10 }} />
                <Typography variant="h5" style={{ marginRight: 10, overflow: 'hidden', whiteSpace: 'nowrap' }}><b>{clientName}</b></Typography>
                <Avatar alt="Remy Sharp" src={imageSrc} />
              </div>
            </>
          ) : (
            <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <div
                className="row"
                style={{
                  display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginRight: 40,
                }}
              >
                {open ? (<IndeterminateCheckBox fontSize="large" onClick={() => setOpen(false)} style={{ color: '00B5BB' }} />) : (<AddBox fontSize="large" onClick={() => setOpen(true)} style={{ color: '00B5BB' }} />)}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant="body1" style={{ opacity: 0.4, marginRight: 10 }}>
                  <b>
                    #
                    {orderNumber}
                  </b>
                </Typography>
                <Edit fontSize="small" onClick={() => handleEditClient(userId)} style={{ marginRight: 10 }} />
                <Typography variant="h5" style={{ marginRight: 10, overflow: 'hidden', whiteSpace: 'nowrap' }}><b>{clientName}</b></Typography>
                <Avatar alt="Remy Sharp" src={imageSrc} />
              </div>
            </div>
          )}

          {((!own && open) || (own && !open))
            && (
            <>
              <hr style={{ opacity: 0.3 }} />
              <Grid container spacing={1}>
                <Tags order={data} />
                <Grid item xs={12}>
                  <Typography variant="caption" style={{ color: saldoSuficiente ? 'black' : 'red' }}><b>Saldo:</b></Typography>
                  <Typography variant="body1" style={{ color: saldoSuficiente ? 'black' : 'red' }}>
                    ${Fn.separadorMiles(credits)}
                  </Typography>
                  {!saldoSuficiente && <small style={{ color: 'red' }}>Saldo insuficiente</small>}
                  <Typography variant="caption">
                    <br />
                    <b>Total Producto(s): </b>
                    ${Fn.separadorMiles(total)}
                  </Typography>
                  <Typography variant="caption">
                    <br />
                    <b>Valor despacho: </b>
                    ${Fn.separadorMiles(deliveryCost)}
                  </Typography>
                  <Typography variant="caption">
                    <br />
                    <b>Total a pagar: </b>
                      ${Fn.separadorMiles(totalDscto)}
                  </Typography>
                  <Typography variant="caption"><br /><b>Origen:</b> {origin}</Typography>
                  <Typography variant="caption">
                    <br />
                    <b>Teléfono: </b>
                    <a href={`tel:${phone}`}><Typography variant="body1" style={{ fontSize: 20 }}>{phone}</Typography></a>
                  </Typography>
                  <Typography variant="caption">
                    <br />
                    <b>Dirección:</b>
                  </Typography>
                  <Typography variant="body2"><a href={addressLink}>{address}</a></Typography>
                  <Typography variant="body2">{addrRef}</Typography>
                  <Typography variant="body2">{`Recibe: ${contact || 'No especificado'}`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <hr style={{ opacity: 0.3 }} />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="caption">
                    <b>Pedido:</b>
                  </Typography>
                  {orderProducts.map((product) => (
                    <div className="row ml-0">
                      <Grid item xs={3} lg={2}>
                        <Typography variant="caption">
                          {product.productName}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} lg={2}>
                        <Typography variant="caption">
                          {product.productQuantity}
                          {' '}
                          {product.unitMeasurement}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} lg={2}>
                        <Typography variant="caption">
                          $
                          {Fn.separadorMiles(product.productPrice)}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} lg={2}>
                        <Typography variant="caption">
                          <b>
                            {'Estado: '}
                            {product.dispensingStatus ? 'Dispensado' : 'No dispensado' }
                          </b>
                        </Typography>
                      </Grid>
                    </div>
                  ))}
                  {orderPackages
                    ? orderPackages.map((packages) => (
                      <div className="row ml-0">
                        <Grid item xs={4} lg={3}>
                          <Typography variant="caption">
                            {packages.packageName}
                            {' envase(s) '}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <Typography variant="caption">
                            $
                            {Fn.separadorMiles(packages.packagePrice)}
                          </Typography>
                        </Grid>
                      </div>
                    ))
                    : (<></>)}
                </Grid>
                { own
                  ? (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="caption">
                          <b>Estado del pedido:</b>
                        </Typography>
                        <Select
                          style={{ width: '100%' }}
                          value={status}
                          onChange={handleChangeStatus}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          {
                    orderStatus.map((i) => (
                      i.idOrderStatus !== 1
                      && (
                      <MenuItem
                        key={i.idOrderStatus}
                        value={i.idOrderStatus}
                      >
                        {i.description}
                      </MenuItem>
                      )
                    ))
                  }
                        </Select>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption">
                          <b>Venta en modo offline:</b>
                        </Typography>
                        <Switch
                          color="primary"
                          checked={isOfflineSale}
                          onChange={(event) => {
                            const { checked } = event.target;
                            onOfflineSale({
                              orderNumber,
                              IsOfflineSale: checked,
                              userId,
                            });
                          }}
                        />
                      </Grid>
                      <Comments order={data} onAddComment={onAddComment} />
                      <IndexNotifyOutside setMessage={setMessage} userId={userId} client={clientName} />
                    </>
                  ) : (
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        <b>Estado del pedido:</b>
                      </Typography>
                      <Typography variant="body2">{statusDescription}</Typography>
                    </Grid>
                  )}
              </Grid>
            </>
            )}
        </CardContent>
      </Card>
    </div>
  );
};

IndexLastOrder.propTypes = {
  data: PropTypes.object.isRequired,
  orderStatus: PropTypes.array.isRequired,
  setIndexViewQuestion: PropTypes.func.isRequired,
  onOfflineSale: PropTypes.func.isRequired,
  onAddComment: PropTypes.func.isRequired,
  setQuestion: PropTypes.func.isRequired,
  editClient: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  setQuestionGrey: PropTypes.any.isRequired,
  setMessage: PropTypes.any.isRequired,
  own: PropTypes.bool.isRequired,
  orderPackages: PropTypes.array.isRequired,
  orderProducts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  orderStatus: state.app.orderStatus,
});

const mapDispatchToProps = (dispatch) => ({
  setIndexViewQuestion: (payload) => dispatch({ type: 'indexViewQuestion', payload }),
  editClient: (payload) => dispatch({ type: 'editClient', payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexLastOrder);
