import axios from "axios";
import { apicenter } from "../../../data/apicenter";

export const getVehicle = async () => {
  const link = `${apicenter.API}/SalePoint/GetAllvehicle`;
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...apicenter.APIHEADER,
  };
  const res = await axios.get(link, { headers });
  return res;
};
