import React from 'react';
import PropTypes from 'prop-types';
import IndexMap from '../Components/Map';

const Map = ({
  tab, setView, pitPoints,
}) => (
  <div>
    <IndexMap tab={tab} setView={setView} pitPoints={pitPoints} />
  </div>
);

Map.propTypes = {
  setView: PropTypes.func.isRequired,
  tab: PropTypes.number.isRequired,
  pitPoints: PropTypes.array.isRequired,
};

export default Map;
