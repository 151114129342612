const defaultState = {
  sidebar: false,
  menu: false,
};

export function ui(state = defaultState, { type }) {
  switch (type) {
    case 'OPEN_SIDEBAR':
      return { ...state, sidebar: true };
    case 'CLOSE_SIDEBAR':
      return { ...state, sidebar: false };
    default:
      return state;
  }
}
