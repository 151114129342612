/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Input, InputLabel, Grid, Select, MenuItem, Typography,
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import RoundedIconButton from '../../globals/Buttons/RoundIconButton';


function TrycicleForm({
  tricycle, onNext, onPrev, initialVals,
}) {
  const [km, setKm] = useState(initialVals.Kms || 0);
  const [Battery, setBattery] = useState(initialVals.Battery || 0);
  const [IsReplacement, setIsReplacement] = useState(initialVals.IsReplacement || 0);
  const [HasLeak, setHasLeak] = useState(initialVals.HasLeak || 0);

  return (
    <div>
      <Grid container spacing={10}>
        <Grid item sm={12} xs={12} style={{ paddingBottom: 0 }}>
          <Typography variant="h4">{tricycle.description}</Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <InputLabel>Batería</InputLabel>
          <Input
            type="number"
            value={Battery}
            onChange={(event) => setBattery(event.target.value)}
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <InputLabel>Kilometraje</InputLabel>
          <Input
            type="number"
            value={km}
            onChange={(event) => setKm(event.target.value)}
            style={{ width: '100%' }}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <InputLabel>¿Es recambio?</InputLabel>
          <Select
            displayEmpty
            value={IsReplacement}
            style={{ width: '100%' }}
            inputProps={{ key: 'isReplacement' }}
            onChange={(event) => setIsReplacement(event.target.value)}
          >
            <MenuItem value={0}>No</MenuItem>
            <MenuItem value={1}>Sí</MenuItem>
          </Select>
        </Grid>

        <Grid item sm={12} xs={12}>
          <InputLabel>¿Hay una filtración de mangueras?</InputLabel>
          <Select
            displayEmpty
            value={HasLeak}
            style={{ width: '100%' }}
            inputProps={{ key: 'isReplacement' }}
            onChange={(event) => setHasLeak(event.target.value)}
          >
            <MenuItem value={0}>No</MenuItem>
            <MenuItem value={1}>Sí</MenuItem>
          </Select>
        </Grid>

        <Grid item container xs={12} sm={12} direction="row-reverse" justifyContent="space-between">
          <RoundedIconButton
            onClick={() => onNext({
              Kms: km, Battery, IsReplacement, HasLeak,
            })}
            size="medium"
          >
            <ChevronRight />
          </RoundedIconButton>

          <RoundedIconButton onClick={onPrev} size="medium">
            <ChevronLeft />
          </RoundedIconButton>
        </Grid>
      </Grid>
    </div>
  );
}

TrycicleForm.propTypes = {
  tricycle: PropTypes.object.isRequired,
  initialVals: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
};

export default TrycicleForm;
