/* eslint no-unused-expressions: [2, { allowShortCircuit: true }] */
/*
    Ejemplo => https://tricicloapidev.azurewebsites.net/api/Orders/ChangeStatus/{orderNumber}/{status}/{userId}
*/

import { apicenter } from "../../../../data/apicenter";

export async function postChangeOrderStatus(req, callback) {
  try {
    // const link = `${apicenter.API}/Orders/ChangeStatus/${orderNumber}/${status}/${userId}`;
    const link = `${apicenter.API}/Orders/changestatus`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...apicenter.APIHEADER,
    };
    const body = JSON.stringify(req);
    const response = await fetch(link, { method, headers, body });
    const data = await response.json();
    callback(data, true);
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("Error POST setVehicleToVendor =>", { req, error });
    callback(null, false);
    return null;
  }
}
