import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  InputLabel, Select, MenuItem, Grid,
} from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import RoundedIconButton from '../../globals/Buttons/RoundIconButton';


const CheckTypeForm = ({
  data, submit, defaultIdSalePoint, defaultIdPos,
}) => {
  const [idCheckType, setIdCheckType] = useState('');
  const [idSalePoint, setIdSalePoint] = useState(defaultIdSalePoint);
  const [idPos, setIdPos] = useState(defaultIdPos);

  return (
    <div>
      <Grid container spacing={10}>
        <Grid item sm={12} xs={12}>
          <InputLabel>Escoger CheckIn o CheckOut </InputLabel>
          <Select
            key="idCHtp"
            displayEmpty
            value={idCheckType}
            style={{ width: '100%' }}
            inputProps={{ key: 'idCheckType' }}
            onChange={(event) => setIdCheckType(event.target.value)}
          >
            {data.checkTypes.map((type) => (
              <MenuItem key={`${type.idCheckType}tk`} value={type.idCheckType}>{type.description}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item sm={12} xs={12}>
          <InputLabel>Escoger triciclo o tienda </InputLabel>
          <Select
            key="idSpt"
            displayEmpty
            value={idSalePoint}
            style={{ width: '100%' }}
            inputProps={{ key: 'idSalePoint' }}
            onChange={(event) => setIdSalePoint(event.target.value)}
          >
            {data.salePoints.map((sp) => (
              <MenuItem key={`${sp.idSalePoint}p`} value={sp.idSalePoint}>{sp.description}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item sm={12} xs={12}>
          <InputLabel>Escoger POS a usar</InputLabel>
          <Select
            key="iddPos"
            displayEmpty
            value={idPos}
            style={{ width: '100%' }}
            inputProps={{ key: 'idPos' }}
            onChange={(event) => setIdPos(event.target.value)}
          >
            <MenuItem key="-1ps" value={-1}>Sin POS</MenuItem>
            {data.posInfo.map((ps) => (
              <MenuItem key={`${ps.idPos}ps`} value={ps.idPos}>{ps.description}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item container xs={12} sm={12} direction="row-reverse" justifyContent="space-between">
          <RoundedIconButton size="medium" onClick={() => { submit({ idPos, idCheckType, idSalePoint }); }}>
            <ChevronRight />
          </RoundedIconButton>
        </Grid>
      </Grid>
    </div>
  );
};

CheckTypeForm.propTypes = {
  data: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  defaultIdPos: PropTypes.number.isRequired,
  defaultIdSalePoint: PropTypes.number.isRequired,
};

export default CheckTypeForm;
