import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import PlaylistAddCheckRoundedIcon from '@material-ui/icons/PlaylistAddCheckRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocalDrink from '@material-ui/icons/LocalDrink';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import MenuIcon from '@material-ui/icons/Menu';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import {
  BrowserRouter,
  Switch,
  Route,
  NavLink,
} from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Drawer, List, ListItem, ListItemText, ListItemIcon,
} from '@material-ui/core';
import ClientsView from '../components/views/ClientsView';
import CheckView from '../components/views/CheckView';
import Login from '../components/views/Login';
import PrivateRoute from '../components/globals/PrivateRoute';
import StatusBar from '../components/globals/Status/StatusBar';
import SeeClientView from '../modules/client/View';
import CreateClient from '../components/views/CreateClient';
import GoalsView from '../components/views/GoalsView';
import OfflineView from '../modules/offline/View';
import SalesView from '../components/views/SalesView';
import CollectView from '../components/views/CollectView';
import CollectQR from '../components/views/CollectQR';
import SaleView from '../modules/sale/View';
// Update new
import IndexView from '../modules/orders/View';
import SnackBarError from '../components/ui/Snackbar/SnackBarError';
import CollectHome from '../components/views/CollectHome';
import ClientsViewPromo from '../components/views/ClientsViewPromo';
import SetPromoClient from '../modules/client/Promos';

const useStyle = makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.secondary.main,
    marginBottom: '3%',
    width: '100%',
    boxShadow: theme.shadows[2],
  },
  navlink: {
    marginLeft: 20,
    color: 'white',
    textDecoration: 'none',
  },
  navlinkSidebar: {
    marginLeft: 20,
    textDecoration: 'none',
    color: 'inherit',
  },
  listIcon: {
    minWidth: '35px',
  },
}));


const menuVendor = [
  {
    title: 'Inicio',
    icon: <HomeRoundedIcon />,
    path: '/',
  },
  {
    title: 'Buscar Clientes',
    icon: <SearchRoundedIcon />,
    path: '/searchclient',
  },
  {
    title: 'Checkin/Checkout',
    icon: <PlaylistAddCheckRoundedIcon />,
    path: '/checkin',
  },
  {
    title: 'Crear Cliente',
    icon: <PersonAddRoundedIcon />,
    path: '/createClient',
  },
  {
    title: 'Tus Métricas',
    icon: <StarRoundedIcon />,
    path: '/goals',
  },
  {
    title: 'Venta offline',
    icon: <WifiOffIcon />,
    path: '/offline',
  },
  {
    title: 'Venta Retornabilidad',
    icon: <ShoppingCartIcon />,
    path: '/sales',
  },
  {
    title: 'Promociones',
    icon: <LoyaltyIcon />,
    path: '/promoClients',
  },
];
const menuPartner = [
  {
    title: 'Entrega Envases',
    icon: <LocalDrink />,
    path: '/packhome',
  },
];

function Layout({
  isAuthenticated, signOut, sidebar, openSidebar, closeSidebar, errorFetch, closeError, userType,
}) {
  const classes = useStyle();
  
  const menuSidebar = userType ? userType.toLowerCase() === 'partner' ? menuPartner : menuVendor : [];
  return (
    <BrowserRouter>
      <SnackBarError
        show={errorFetch.show}
        handleClose={closeError}
        message={errorFetch.message}
      />
      <div role={userType}>
        {isAuthenticated
          && (
            <AppBar className={classes.appbar} position="static">
              <Toolbar>

                <Sidebar sidebar={sidebar} closeSidebar={closeSidebar} signOut={signOut} menuSidebar={menuSidebar} />

                <Grid container={true} alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <Tooltip title="Menu">
                      <MenuIcon onClick={openSidebar} />
                    </Tooltip>
                    <NavLink to="/" className={classes.navlink}>
                      <img src="https://tricicloblobprod.blob.core.windows.net/images/LogoWhite.png" style={{ width: 100 }} alt="logo" />
                    </NavLink>
                  </Grid>
                  <Grid item>
                    {userType != undefined && userType.toLowerCase() === 'partner' && (
                      <Tooltip title="Home">
                        <NavLink to="/packhome" className={classes.navlink}>
                          <HomeRoundedIcon />
                        </NavLink>
                      </Tooltip>
                    )}
                    {userType != undefined && userType.toLowerCase() === 'vendor' && (
                      <Tooltip title="Pedidos">
                        <NavLink to="/" className={classes.navlink}>
                          <HomeRoundedIcon />
                        </NavLink>
                      </Tooltip>
                    )}
                    <Tooltip title="Buscador">
                      <NavLink to="/searchclient" className={classes.navlink}>
                        <SearchRoundedIcon />
                      </NavLink>
                    </Tooltip>
                  </Grid>

                </Grid>
              </Toolbar>
            </AppBar>
          )}
        <div className="container">
          <Switch>
            <Route path="/login" component={Login} />
            {userType && userType != undefined && isAuthenticated && userType.toLowerCase() === 'vendor' &&
              <>
              <PrivateRoute path="/" exact component={IndexView} />
                <PrivateRoute path="/sales" component={SalesView} />
                <PrivateRoute path="/searchclient" exact component={ClientsView} />
                <PrivateRoute path="/client/sale/:clientId" component={SaleView} />
                <PrivateRoute path="/client/:clientId" component={SeeClientView} />
                <PrivateRoute path="/createClient" component={CreateClient} />
                <PrivateRoute path="/checkin" component={CheckView} />
                <PrivateRoute path="/goals" component={GoalsView} />
                <PrivateRoute path="/offline" component={OfflineView} />
                <PrivateRoute path="/promoClients" component={ClientsViewPromo} />
                <PrivateRoute path="/promoClient/:clientId" component={SetPromoClient} />
              </>
            }
            {userType != undefined && isAuthenticated && userType.toLowerCase() === 'partner' &&
              <>
                <PrivateRoute path="/" exact component={CollectHome} />
                <PrivateRoute path="/packcollect" component={CollectView} />
                <PrivateRoute path="/packcollectQR" component={CollectQR} />
                <PrivateRoute path="/packhome" component={CollectHome} />
              </>
            }
            {!userType &&
              <>
                <PrivateRoute path="/" exact component={Login} />
              </>
            }
          </Switch>
        </div>
        <StatusBar />
      </div>
    </BrowserRouter >
  );
}

const Sidebar = ({ sidebar, closeSidebar, signOut, menuSidebar }) => {
  const classes = useStyle();
  return (
    <Drawer anchor="left" open={sidebar} onClose={closeSidebar}>
      <List>
        {menuSidebar.map((i) => (
          <NavLink to={i.path} className={classes.navlinkSidebar} key={`${i.title}kjhg`}>
            <ListItem button key={i.title}>
              <ListItemIcon className={classes.listIcon}>{i.icon}</ListItemIcon>
              <ListItemText primary={i.title} />
            </ListItem>
          </NavLink>
        ))}

        <NavLink
          className={classes.navlinkSidebar}
          to="/login"
          onClick={() => {
            signOut();
          }}
        >
          <ListItem button>
            <ListItemIcon className={classes.listIcon}><ExitToAppRoundedIcon /></ListItemIcon>
            <ListItemText primary="Cerrar Sesión" />
          </ListItem>
        </NavLink>

      </List>
    </Drawer>
  );
};

Layout.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
  sidebar: PropTypes.bool.isRequired,
  openSidebar: PropTypes.func.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  errorFetch: PropTypes.any.isRequired,
  closeError: PropTypes.func.isRequired,
  //userType: PropTypes.number.isRequired,
};

Sidebar.propTypes = {
  sidebar: PropTypes.bool.isRequired,
  closeSidebar: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.session.isAuthenticated,
  sidebar: state.ui.sidebar,
  errorFetch: state.app.failedAnyFetch,
  userType: state.session.userType,
});

const mapDispatchToProps = (dispatch) => ({
  signIn: () => dispatch({ type: 'SIGN_IN' }),
  signOut: () => dispatch({ type: 'SIGN_OUT' }),
  openSidebar: () => dispatch({ type: 'OPEN_SIDEBAR' }),
  closeSidebar: () => dispatch({ type: 'CLOSE_SIDEBAR' }),
  closeError: () => dispatch({ type: 'HIDE_FAILED_ANY_FETCH' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
