import React from 'react';
import PropTypes from 'prop-types';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    boxShadow: theme.shadows[1],
  },
}));

function RoundIconButton({
  onClick, children, size, width,
}) {
  const classes = useStyles();
  const sizee = size || isWidthUp('sm', width) ? size : 'small';

  return (
    <IconButton className={classes.iconButton} size={sizee} color="default" onClick={onClick}>
      {children}
    </IconButton>
  );
}

RoundIconButton.propTypes = {
  width: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.string,
};

RoundIconButton.defaultProps = {
  size: 'small',
};


export default withWidth()(RoundIconButton);
