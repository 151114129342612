const defaultState = {
  assignmentData: null,
  error: "",
};

export function myVehicles(state = defaultState, action) {
  switch (action.type) {
    case "GET_MY_VEHICLE":
      return { ...state, ...action.payload };
    case "CLEAN_MY_VEHICLE":
      return { ...state, assignmentData: null };
    default:
      return state;
  }
}
