import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import StarsRoundedIcon from '@material-ui/icons/StarsRounded';
import SwapHorizontalCircleRoundedIcon from '@material-ui/icons/SwapHorizontalCircleRounded';

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    marginRight: 13,
    marginBottom: 5,
  },
  cancel: {
    color: theme.palette.error.light,
  },
  success: {
    color: theme.palette.success.light,
  },
  alert: {
    color: theme.palette.warning.light,
  },
  main: {
    color: theme.palette.primary.light,
  },
}));


function IconText({
  text, iconType,
}) {
  const classes = useStyles();
  let icon;
  switch (iconType) {
    case 'SUCCESS':
      icon = <CheckCircleIcon className={classes.success} />;
      break;
    case 'ALERT':
      icon = <WarningRoundedIcon className={classes.alert} />;
      break;
    case 'ERROR':
      icon = <CancelIcon className={classes.cancel} />;
      break;
    case 'MONEY':
      icon = <MonetizationOnRoundedIcon className={classes.alert} />;
      break;
    case 'NEW':
      icon = <StarsRoundedIcon className={classes.main} />;
      break;
    case 'RECYCLE':
      icon = <SwapHorizontalCircleRoundedIcon className={classes.main} />;
      break;
    default:
      icon = <></>;
      break;
  }

  return (
    <Grid item style={{ display: 'flex' }}>
      <div className={classes.iconContainer}>
        {icon}
      </div>
      <Typography variant="body1">{text}</Typography>
    </Grid>
  );
}

IconText.propTypes = {
  text: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
};


export default IconText;
