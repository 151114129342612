import { apicenter } from '../../../../data/apicenter';


export function getOrder(userId, productId, rfid, idPackage, callback) {
  const link = `${apicenter.API}/Orders/GetOrders/${userId}/${productId}/${rfid}/${idPackage}`;
  const method = 'GET';
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };

  fetch(link, { method, headers })
    .then((res) => res.json())
    .then((res) => {
      if (callback) callback(res, true);
    })
    .catch((error) => {
      if (callback) callback(error, false);
    });
}
