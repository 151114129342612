import React from 'react';
import ReactBodymovin from 'react-bodymovin';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import animation from '../../../assets/lottiefiles/pin.json';

const IndexNotQuadrant = ({msg}) => {
  const bodymovinOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: animation,
  };

  return (
    <Grid container spacing={1} sm={12}>
      <Grid
        item
        xs={12}
        style={{
          justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column',
        }}
      >
        <div style={{ width: '100%', maxWidth: '500px' }}>
          <ReactBodymovin options={bodymovinOptions} />
        </div>
        <Typography variant="h4" style={{ color: '#76797F' }}>{msg}</Typography>
      </Grid>
    </Grid>
  );
};

export default IndexNotQuadrant;
