import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Button } from '@material-ui/core';

const StatusBarGlobal = ({ message, setMessage }) => (
  <Snackbar
    open={message !== ''}
    message={message}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    autoHideDuration={6000}
    action={(
      <Button color="primary" size="small" onClick={() => setMessage('')}>
        CERRAR
      </Button>
    )}
  />
);

StatusBarGlobal.propTypes = {
  message: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
};

export default StatusBarGlobal;
