import es from './es.json';
import en from './en.json';

function obtain(key) {
  switch (key) {
    case 'es': return es;
    case 'en': return en;
    default: return es;
  }
}
export const obtainLanguage = obtain('es');
