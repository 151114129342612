import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';


const StatusBar = ({ message, setMessage }) => (
  <Snackbar
    open={message !== ''}
    message={message}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    autoHideDuration={6000}
    action={(
      <Button color="primary" size="small" onClick={() => setMessage('')}>
        CERRAR
      </Button>
    )}
  />
);

StatusBar.propTypes = {
  message: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  message: state.status.message,
});

const mapDispatchToProps = (dispatch) => ({
  setMessage: (message) => dispatch({ type: 'ADD_STATUS', message }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusBar);
