// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { apicenter } from '../../../data/apicenter';
import { setToken } from '../../../redux/actions/session';
import { store } from '../../../redux/store';

export function getAuth(req, callback) {
  const link = `${apicenter.API}/Auths/LoginVendor`;
  const method = 'POST';
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };
  const body = JSON.stringify(req);

  fetch(link, { method, headers, body })
    .then((res) => res.json())
    .then((res) => {
      if (res.error.errorCode === 0) {
        const token = jwt_decode(res.token.value);
        store.dispatch(setToken({ token: res.token.value, userType: token.Role }));
        if (callback) callback(true, 'Ingresado con éxito', res.data);
      } else if (callback) callback(false, res.error.errorMessage);
    })
    .catch((e) => {
      if (callback) callback(false, 'Error del sistema');
    });
}
