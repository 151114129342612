import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {
  useParams,
} from 'react-router-dom';
import {
  Typography, CircularProgress,
} from '@material-ui/core';
import Info from './Info';
import Ammount from './Ammount';
import History from './History';
import Modal from './Modal';
import { getMovements } from '../../components/utils/api/getMovements';
import { getClient } from '../../components/utils/api/getClient';
import Coupon from './Coupon';
import { getCoupons, postCoupon } from '../../components/utils/api/coupons';

const View = ({
  app, vendorId, setMessage, history,
}) => {
  const [modal, setModal] = useState(false);
  const [salesHistory, setHistory] = useState(null);
  const [couponId, setCouponId] = useState(0);
  const [coupons, setCoupons] = useState([]);
  const [loadingCuopon, setLoadingCuopon] = useState(true);
  const [clientInfo, setClientInfo] = useState(null);
  const { clientId } = useParams();

  const handleObtainHistory = (id) => {
    getMovements(id, (res, status) => {
      if (status) {
        setHistory(res.data);
      }
    });
  };

  const handleObtainInfoClient = (id) => {
    getClient(id, (res, status) => {
      if (status) {
        setClientInfo(res.data.clientData);
      }
    });
  };
  const handleObtainAll = (id) => {
    handleObtainHistory(id);
    handleObtainInfoClient(id);
  };

  useEffect(() => {
    if (clientId) {
      handleObtainAll(clientId);
    } else {
      setMessage('Cliente no encontrado');
      history.push('/');
    }
    getCoupons()
      .then((data) => {
        setCoupons(data);
        setLoadingCuopon(false);
      })
      .catch((err) => { setMessage(err.message); });
  }, []);

  const handleCouponSend = () => {
    if (couponId > 0) {
      setLoadingCuopon(true);
      postCoupon(couponId, clientId, vendorId).then(() => {
        setLoadingCuopon(false);
        setMessage('Cupón ingresado exitosamente');
        history.go(0);
      }).catch((err) => {
        setLoadingCuopon(false);
        setMessage(err.message);
        setCouponId(0);
      });
    } else {
      setMessage('Debes seleccionar un cupón');
    }
  };

  const handleSelectCoupon = (cpId) => {
    setCouponId(cpId);
  };

  const { chargeBill } = app;
  const dataFront = [
    {
      show: clientInfo !== null,
      title: 'Información',
      Content: Info,
      data: { clientInfo, handleObtainInfoClient },
    },
    {
      show: !loadingCuopon,
      title: 'Cupones',
      Content: Coupon,
      data: {
        loading: loadingCuopon,
        coupons,
        couponId,
        onCouponSelect: handleSelectCoupon,
        onCouponSend: handleCouponSend,
      },
    },
    {
      show: true,
      title: 'Cargar Saldo',
      Content: Ammount,
      data: {
        openModal: () => setModal(true),
      },
    },
    {
      show: salesHistory !== null,
      title: 'Historial de cargas',
      Content: History,
      data: salesHistory,
    },
  ];

  return (
    <div>
      <Modal
        open={modal}
        closeModal={() => setModal(false)}
        handleObtainAll={() => handleObtainAll(clientId)}
        info={{
          ...chargeBill,
          id: vendorId,
          userId: clientId,
        }}
      />
      <Grid container spacing={1}>
        {
            dataFront.map(({
              title, Content, data, show,
            }) => (
              <Grid container key={title} spacing={1} style={{ marginTop: 20, marginBottom: 20 }}>
                <Typography variant="h2">{title}</Typography>
                <Grid container spacing={1} style={{ marginTop: 20 }}>
                  {show ? <Content data={data} /> : <CircularProgress />}
                </Grid>
              </Grid>
            ))
          }
      </Grid>
    </div>
  );
};

View.propTypes = {
  setMessage: PropTypes.func.isRequired,
  vendorId: PropTypes.string.isRequired,
  app: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
  vendorId: state.session.info.userId,
});

const mapDispatchToProps = (dispatch) => ({
  setMessage: (message) => dispatch({ type: 'ADD_STATUS', message }),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
