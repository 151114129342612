const defaultState = {
  listOrders: [],
};

export function ordersByVehicle(state = defaultState, action) {
  switch (action.type) {
    case "GET_ORDERS_BY_VEHICLE":
      return { ...state, ...action.payload };
    case "CLEAN_LIST_ORDERS":
      return { ...state, listOrders: [] };
    default:
      return state;
  }
}
