import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  Grid, Button, CircularProgress, InputBase, Paper,
} from '@material-ui/core';

import ClientSaleTable from './SaleComponents/Table';
import { getClientSearch } from '../utils/api/getClientSearch';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    margin: '3% 0',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  inputRoot: {
    width: '100%',
  },
}));

const SalesView = ({ userId }) => {
  const classes = useStyles();
  const history = useHistory();
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState(false);

  const HandleObtainClient = () => {
    setLoading(true);
    getClientSearch(text, (res, status) => {
      setLoading(false);
      setSearch(true)
      if (status) {
        setList(res.data || []);
      } else {
        setList([]);
      }
    });
  };

  const onChange = (value) => {
    if (search) setSearch(false)
    setText(value);
  };

  const handleSelect = (client) => {
    history.push(`client/sale/${client.userId}`);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ marginBottom: '3%' }}>
        <Typography variant="h2">Venta Retornabilidad</Typography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: '3%' }}>
        <Paper className={classes.search}>
          <InputBase
            value={text}
            onChange={(event) => {
              onChange(event.target.value);
            }}
            placeholder="Ingrese el correo del cliente"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginBottom: '3%',
        }}
      >
        <Button
          onClick={() => HandleObtainClient()}
          disabled={loading}
          variant="contained"
          color="primary"
        >
          Buscar cliente
        </Button>
        {loading && (
        <CircularProgress
          size="small"
          style={{ marginLeft: 20 }}
        />
        )}
        <Button
          onClick={() => history.push('createClient')}
          disabled={loading}
          variant="contained"
          color="primary"
        >
          Crear cliente
        </Button>
      </Grid>
      {search
        && (
        <Grid
          item
          xs={12}
          style={{
            display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginBottom: '3%',
          }}
        >
          <ClientSaleTable clients={list} onSelect={handleSelect} />
        </Grid>
        )}
    </Grid>
  );
};


SalesView.propTypes = {
  userId: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  userId: state.session.info.userId,
});

export default connect(mapStateToProps)(SalesView);
