import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

function CountButtons({
  id, onClickMinus, onClickPlus, size, width, quantity, title
}) {

  return (
    quantity > 0 &&
    <>
      <ButtonGroup aria-label="outlined button group">        
        <Button onClick={() => onClickMinus(id)} color="secondary">-</Button>
        {<Button disabled style={{fontWeight: "bold"}}>{quantity}</Button>}
        {<Button onClick={() => onClickPlus(id)} color="primary">+</Button>}
      </ButtonGroup>
    </>
  );
}

CountButtons.propTypes = {
  id: PropTypes.number.isRequired,
  onClickMinus: PropTypes.func.isRequired,
  onClickPlus: PropTypes.func.isRequired,
  size: PropTypes.string,
  width: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default CountButtons;
