import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, capitalize } from 'lodash';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


function PackCard({ pack }) {
  const packContent = !isEmpty(pack) && (
    <>
      <Grid item xs={12}>
        <Typography variant="h2">Datos envase</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ marginBottom: 10 }}>
          <CardContent>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td>
                    <Typography variant="body1">Nombre usuario asociado</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`${capitalize(pack.client.name)} ${capitalize(pack.client.lastName)}`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">Mail usuario asociado</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`${pack.client.email}`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">Rut usuario asociado</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`${pack.client.rut}`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">Impacto</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`${pack.client.impact}`}</Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </CardContent>
        </Paper>
        <Typography variant="caption">Datos generales del envase ingresado, muestra el usuario al que se encuentra asociado el envase.</Typography>
      </Grid>
    </>
  );

  return packContent;
}

PackCard.propTypes = {
  pack: PropTypes.object,
};

PackCard.defaultProps = {
  pack: null,
};


export default PackCard;
