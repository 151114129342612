import { takeLatest, put } from "redux-saga/effects";
import { getMyVehicle } from "./orders/getMyVehicle";

function* obtainMyVehicle({ payload }) {
  try {
    const result = yield getMyVehicle(payload);
    if (result.data.error.errorCode !== 0) {
      if (result.data.error.errorCode === 1) {
        yield put({
          type: "GET_MY_VEHICLE",
          payload: {
            assignmentData: null,
            error: "",
          },
        });
      } else if (result.data.error.errorMessage === "No tiene auto asignado") {
        yield put({
          type: "GET_MY_VEHICLE",
          payload: {
            assignmentData: null,
            error: "No tiene auto asignado",
          },
        });
      } else {
        yield put({
          type: "FAILED_ANY_FETCH",
          payload: "Error al obtener la asignación",
        });
      }
    } else {
      yield put({
        type: "GET_MY_VEHICLE",
        payload: {
          assignmentData: result.data.data,
          error: "",
        },
      });
    }
  } catch (error) {
    yield put({
      type: "FAILED_ANY_FETCH",
      payload: "Error al obtener la asignación",
    });
    console.log("Error GET_MY_VEHICLE", { error, payload });
  }
}

export default function* getMyAssignedVehicle() {
  yield takeLatest("LAUNCH_GET_MY_VEHICLE", obtainMyVehicle);
}
