import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Typography,
  Card,
  CardContent,
  Grid,
  InputLabel,
  Input,
  Select,
  Button,
  CircularProgress,
  makeStyles,
  Box,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import { useHistory } from 'react-router-dom';
import { getPlacesBack } from '../utils/api/googleApi/getPlacesBack';
import { getGeocode } from '../utils/api/googleApi/getGeocode';
import { postCreateClient } from '../utils/api/postCreateClient';
import { getInZoneAsync } from '../utils/api/getInZone';
import { getState } from '../utils/api/Address/getState';
import { getCountry } from '../utils/api/Address/getCountry';
import { getPlaceOfOrigin } from '../utils/api/Address/getPlaceOfOrigin';
import { getCity } from '../utils/api/Address/getCity';
import { Fn } from '../utils/Fn';

const useStyle = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
  eyes: {
    display: 'initial',
  },
  contentIcons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const CreateClient = ({
  setStatus, editClient, codRef, countryCode,
}) => {
  const classes = useStyle();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [geo] = useState(null);
  const [data, setData] = useState([]);
  const [communeOfOriginData, setcommuneOfOriginData] = useState([]);
  const [placeOfOriginData, setPlaceOfOriginData] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorCom, setErr] = useState('');
  const [placeOfOrigins, setPlaceOfOrigins] = useState('');
  const [communeOfOrigin, setCommuneOfOrigin] = useState('');
  const [rec, setRec] = useState('');
  const [typePass, setTypePass] = useState('password');


  const getInfo = async () => {
    const placeOfOrigin = await getPlaceOfOrigin();
    const comunne = await getCity(15);
    setPlaceOfOriginData(placeOfOrigin.data);
    setcommuneOfOriginData(comunne.data);
  };

  useEffect(() => {
    getInfo();
  }, []);

  const getPlaces = async (input) => {
    if (input === '') {
      return [];
    }

    setLoading(true);
    const dataAux = await getPlacesBack({ input, location: geo });
    const d = JSON.parse(dataAux.data);
    setData(d.predictions);
    setLoading(false);
  };

  const changeTypePass = () => {
    typePass === 'password' && setTypePass('text');
    typePass === 'text' && setTypePass('password');
  };

  return (
    <div>
      <Typography variant="h2">Crear cliente</Typography>
      <Card style={{ marginTop: 30 }}>
        <CardContent>
          <Formik
            initialValues={{
              name: '',
              lastName: '',
              email: '',
              confirmEmail: '',
              pass: '',
              confirmPass: '',
              rut: '',
              dir: '',
              phone: '',
              numberDir: '',
              codRef,
              origin: placeOfOrigins,
              commune: communeOfOrigin,
            }}
            validate={(values) => {
              const errors = {};
              if (placeOfOrigins !== '' || communeOfOrigin !== '') {
                values.origin = placeOfOrigins;
                values.commune = communeOfOrigin;
              }
              if (!values.name) {
                errors.name = 'Este campo es requerido';
              }
              if (!values.lastName) {
                errors.lastName = 'Este campo es requerido';
              }
              if (!values.email) {
                errors.email = 'Este campo es requerido';
              }
              if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  values.email.trim(),
                )
              ) {
                errors.email = 'Correo inválido';
              }
              if (!values.confirmEmail) {
                errors.confirmEmail = 'Este campo es requerido';
              }
              if (values.email !== values.confirmEmail) {
                errors.confirmEmail = 'No coinciden los campos';
              }
              if (!values.pass) {
                errors.pass = 'Este campo es requerido';
              }
              if (!values.confirmPass) {
                errors.confirmPass = 'Este campo es requerido';
              }
              if (values.pass !== values.confirmPass) {
                errors.confirmPass = 'No coinciden los campos';
              }
              if (values.rut !== '' && !Fn.validaRut(values.rut)) {
                errors.rut = 'El rut no es válido';
              }
              if (values.dir !== '' && !Fn.contain_num(values.dir)) {
                errors.dir = 'Debe contener un número';
                getPlaces(values.dir);
                setOpen(true);
              }
              if (values.dir !== '' && values.dir.split(',').length < 3) {
                errors.dir = 'Te faltó ingresar una ciudad';
                getPlaces(values.dir);
                setOpen(true);
              }
              if (
                values.dir.split(',').length >= 3
                && !!Fn.contain_num(values.dir)
              ) {
                setOpen(false);
              }
              if (values.dir === '' && values.numberDir !== '') {
                errors.dir = 'Debe ingresar la dirección';
              }
              if (values.dir !== '' && values.numberDir === '') {
                errors.numberDir = 'Este campo es requerido';
              }
              if (values.phone && values.phone.length !== 9) {
                errors.phone = 'Ingresa 9 caracteres';
              }
              if (!values.commune) {
                errors.commune = 'Este campo es requerido';
              }
              if (!values.origin) {
                errors.origin = 'Este campo es requerido';
              }
              if (values.origin && values.origin !== 1) {
                if (values.dir === '') {
                  errors.dir = 'Este campo es requerido';
                }
                if (values.numberDir === '') {
                  errors.numberDir = 'Este campo es requerido';
                }
              }
              return errors;
            }}
            onSubmit={async (values, { resetForm }) => {
              setLoading(true);
              let send = null;
              if (values.dir !== '') {
                try {
                  const geoCodeAux = await getGeocode(values.dir);
                  const geoCode = JSON.parse(geoCodeAux.data);
                  const geoCodeResult = geoCode.results[0];
                  const countryName = geoCodeResult.address_components.find(
                    (i) => i.types[0] === 'country',
                  );
                  const countryList = await getCountry();
                  const IdCountry = countryList.data.find(
                    (i) => i.description === countryName.long_name,
                  );
                  const { lat, lng } = geoCodeResult.geometry.location;
                  const getInZone = await getInZoneAsync({
                    lat,
                    lng,
                    idCountry: IdCountry.idCountry,
                  });
                  if (!countryName) {
                    setErr('Debe contener un país');
                  }
                  if (!countryList || !IdCountry || !IdCountry.idCountry) {
                    setRec('No tenemos despacho a este país');
                  }
                  const stateName = geoCodeResult.address_components.find(
                    (i) => i.types[0] === 'administrative_area_level_1',
                  );
                  const stateList = await getState(IdCountry.idCountry);
                  const findLongName = stateList.data.find(
                    (i) => i.description === stateName.long_name.toUpperCase(),
                  );
                  const findShortName = stateList.data.find(
                    (i) => i.code === stateName.short_name.toUpperCase(),
                  );
                  const IdState = findLongName || findShortName;
                  if (!stateName) {
                    setErr('Debe contener una comuna o estado');
                  }
                  if (!stateList || !IdState) {
                    setRec('No tenemos despacho a esta comuna o estado');
                  }
                  if (IdCountry.idCountry === 43) {
                    // Find City
                    const cityName = geoCodeResult.address_components.find(
                      (i) => i.types[0] === 'administrative_area_level_3',
                    );
                    const cityList = await getCity(IdState.idState);
                    const IdCity = cityList.data.find(
                      (i) => i.description.toUpperCase()
                        === cityName.long_name.toUpperCase(),
                    );
                    if (!cityName) {
                      setErr('Debe contener una ciudad');
                    }
                    if (!cityList || !IdCity) {
                      setRec('No tenemos despacho a esta ciudad');
                    }

                    send = {
                      Address1: values.dir,
                      IdCountry: IdCountry.idCountry,
                      IdState: IdState.idState,
                      IdCity: IdCity.idCity,
                      IdCommune: IdCity.idCity,
                      Lat: lat,
                      Lng: lng,
                      IdZone: getInZone.data,
                      BuildName: '',
                      Number: values.numberDir,
                      Comment: '',
                    };
                  } else {
                    send = {
                      Address1: values.dir,
                      IdCountry: IdCountry.idCountry,
                      IdState: IdState.idState,
                      IdCity: 19221,
                      IdCommune: 19221,
                      Lat: lat,
                      Lng: lng,
                      IdZone: getInZone.data,
                      BuildName: '',
                      Number: values.numberDir,
                      Comment: '',
                    };
                  }
                } catch (error) {
                  console.log('err catch ---> ', error);
                }
              }
              if (errorCom === '') {
                const req = {
                  IdCountry: countryCode,
                  Name: values.name,
                  Rut: values.rut,
                  CodRef: values.codRef,
                  LastName: values.lastName,
                  Email: values.email.trim(),
                  User: { Password: values.pass },
                  FavoriteAddress: send,
                  Phone: values.phone,
                  IdOriginCommune: values.commune,
                  IdOriginPlace: values.origin,
                };
                postCreateClient(req, (res, status, err) => {
                  setLoading(false);

                  setStatus(status ? '¡Cliente creado con éxito!' : err);
                  if (status) {
                    resetForm({});
                    editClient(res.data);
                    setTimeout(() => {
                      history.push(`client/${res.data.userId}`);
                    }, 1000);
                  }
                });
              }
            }}
          >
            {() => (
              <Form style={{ width: '100%', padding: 20 }}>
                <Grid container spacing={10}>
                  <Grid item sm={6} xs={12}>
                    <InputLabel>Origen *</InputLabel>
                    {placeOfOriginData.length > 0 && (
                      <Select
                        key="origin"
                        displayEmpty
                        value={placeOfOrigins}
                        style={{ width: '100%' }}
                        inputProps={{ key: 'origin' }}
                        onChange={(event) => { setPlaceOfOrigins(event.target.value); }}
                      >
                        {placeOfOriginData.map((placeOfOrigins) => (
                          <MenuItem key={`${placeOfOrigins.idClientOriginPlace}`} value={placeOfOrigins.idClientOriginPlace}>{placeOfOrigins.description}</MenuItem>
                        ))}
                      </Select>
                    )}
                    <ErrorMessage
                      name="origin"
                      component="div"
                      className={classes.error}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <InputLabel>Comuna *</InputLabel>
                    {communeOfOriginData.length > 0 && (
                      <Select
                        key="commune"
                        displayEmpty
                        value={communeOfOrigin}
                        style={{ width: '100%' }}
                        inputProps={{ key: 'commune' }}
                        onChange={(event) => { setCommuneOfOrigin(event.target.value); }}
                      >
                        {communeOfOriginData.map((communeOfOrigins) => (
                          <MenuItem key={`${communeOfOrigins.idCity}`} value={communeOfOrigins.idCity}>{communeOfOrigins.description}</MenuItem>
                        ))}
                      </Select>
                    )}
                    <ErrorMessage
                      name="commune"
                      component="div"
                      className={classes.error}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <InputLabel>Nombre *</InputLabel>
                    <Field
                      type="text"
                      name="name"
                      as={Input}
                      style={{ width: '100%' }}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className={classes.error}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <InputLabel>Apellido *</InputLabel>
                    <Field
                      type="text"
                      name="lastName"
                      as={Input}
                      style={{ width: '100%' }}
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className={classes.error}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <InputLabel>Mail *</InputLabel>
                    <Field
                      type="text"
                      name="email"
                      as={Input}
                      style={{ width: '100%' }}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className={classes.error}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <InputLabel>Confirmar Mail *</InputLabel>
                    <Field
                      type="text"
                      name="confirmEmail"
                      as={Input}
                      style={{ width: '100%' }}
                    />
                    <ErrorMessage
                      name="confirmEmail"
                      component="div"
                      className={classes.error}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <InputLabel>Contraseña *</InputLabel>
                    <div className={classes.contentIcons}>
                      <Field
                        type={typePass}
                        name="pass"
                        as={Input}
                        style={{ width: '85%' }}
                      />
                      <IconButton className={classes.eyes} size="small" aria-label="close" color="inherit" onClick={() => changeTypePass()}>
                        <Visibility />
                      </IconButton>
                    </div>
                    <ErrorMessage
                      name="pass"
                      component="div"
                      className={classes.error}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <InputLabel>Confirmar Contraseña *</InputLabel>
                    <div className={classes.contentIcons}>
                      <Field
                        type={typePass}
                        name="confirmPass"
                        as={Input}
                        style={{ width: '85%' }}
                      />
                      <IconButton className={classes.eyes} size="small" aria-label="close" color="inherit" onClick={() => changeTypePass()}>
                        <Visibility />
                      </IconButton>
                    </div>
                    <ErrorMessage
                      name="confirmPass"
                      component="div"
                      className={classes.error}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <InputLabel>RUT (formato XXXXXXXX-X)</InputLabel>
                    <Field
                      type="text"
                      name="rut"
                      as={Input}
                      style={{ width: '100%' }}
                    />
                    <ErrorMessage
                      name="rut"
                      component="div"
                      className={classes.error}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <InputLabel>Teléfono</InputLabel>
                    <Box display="flex">
                      <Typography style={{ marginTop: '4px' }}>+56</Typography>
                      <Field
                        type="phone"
                        name="phone"
                        as={Input}
                        style={{ width: '100%' }}
                        maxLength={9}
                      />
                    </Box>
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className={classes.error}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <InputLabel>Dirección</InputLabel>
                    <Field name="dir" as={Input} style={{ width: '100%' }} />
                    <ErrorMessage
                      name="dir"
                      component="div"
                      className={classes.error}
                    />
                    {data.length > 0 && open && (
                      <Field name="dir" as={Select} style={{ width: '100%' }}>
                        {data.map((direction) => (
                          <option value={direction.description}>
                            {direction.description}
                          </option>
                        ))}
                      </Field>
                    )}
                    <Typography style={{ color: 'red' }}>{errorCom}</Typography>
                    <Typography style={{ color: 'blue' }}>{rec}</Typography>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <InputLabel>Número de depto/casa/of</InputLabel>
                    <Field
                      type="text"
                      name="numberDir"
                      as={Input}
                      style={{ width: '100%' }}
                    />
                    <ErrorMessage
                      name="numberDir"
                      component="div"
                      className={classes.error}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <InputLabel>Código de referidos</InputLabel>
                    <Field
                      type="text"
                      name="codRef"
                      as={Input}
                      style={{ width: '100%' }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      disabled={loading}
                      variant="contained"
                      color="primary"
                    >
                      Crear cliente
                    </Button>
                    {loading && (
                      <CircularProgress
                        size="small"
                        style={{ marginLeft: 20 }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

CreateClient.propTypes = {
  setStatus: PropTypes.func.isRequired,
  editClient: PropTypes.func.isRequired,
  codRef: PropTypes.string,
  countryCode: PropTypes.string.isRequired,
};

CreateClient.defaultProps = {
  codRef: '',
};

const mapStateToProps = (state) => ({
  codRef: state.session.info.codRef,
  countryCode: state.session.info.countryCode,
});

const mapDispatchToProps = (dispatch) => ({
  setStatus: (message) => dispatch({ type: 'ADD_STATUS', message }),
  editClient: (user) => dispatch({ type: 'editClient', payload: user }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateClient);
