import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { obtainLanguage } from '../../data/lang/obtainLanguage';
import * as actions from '../../redux/actions/app';
import { getClientByCode } from '../utils/api/getClient';
import ClientInfoReduced from '../../modules/client/InfoReduced';
import SnackBarError from '../ui/Snackbar/SnackBarError';
import ValidateCollect from '../../modules/client/ValidateCollect';
import QrReader from 'react-qr-reader';

// import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    margin: '3% 0',
    display: 'flex',
  },
  searchBoxBtn: {
    position: 'relative',
    width: '100%',
    margin: 'auto',
    display: 'flex',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  inputRoot: {
    width: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '100%',
  },
  button: {
    minWidth: '80%',
    margin: 'auto',
    width: '100%',
  },

}));
const CollectQR = ({
  app, vendorId
}) => {
  const [userCode, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [enableScan, setEnableScan] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [orderInfo, setOrderInfo] = useState(null);
  const [orderProdInfo, setOrderProdInfo] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [message, setMessage] = useState(obtainLanguage.collect.useCam);
  // const [list, setList] = useState([]);
  const classes = useStyles();
  
  const handleScan = async (data) => {
    if (data) {
      let datQR  = JSON.parse(data)
      console.log(datQR);
      handleEnabledScan(false);
      /* */
      setLoading(true)
      setUserInfo(null);
      setOrderInfo(null);
      
      const res = await getClientByCode(datQR.code, datQR.orderNumber);
      
      //  llamado a la api para buscar
      if (!res.data || res.data.length === 0) {
        setMessage(obtainLanguage.collect.codeNotFound)
        setLoading(false);
        setNotFound(true);
        return;
      }
      setLoading(false);
      setNotFound(false);
      const {
        name,
        lastName,
        email,
        userId,
      } = res.data.client;
      const {orderNumber} = res.data.globalOrder;
      const { nameProduct, isStarterPack, isDeliverable, priceProduct, orderStatus, orderStatusName } = res.data.productOrder;
      setUserInfo({
        name,
        lastName,
        email,
        userId,
      });
      setOrderInfo({orderNumber});
      setOrderProdInfo({ nameProduct, isStarterPack, isDeliverable, priceProduct, orderStatus, orderStatusName });
      /* */
    }
  }

  const handleError = () => {
    setMessage(obtainLanguage.collect.useCam)
    setNotFound(true);
  }

  const handleReset = () => {
    setCode('');
    setUserInfo(null);
    setOrderInfo(null);
  }
  const handleEnabledScan = (enabled) => {
    setEnableScan(enabled);
    if (enabled){
      setOrderInfo(null);
      setUserInfo(null);
    }
  }
  return (
    <div>
      <SnackBarError
        show={notFound}
        handleClose={() => { setNotFound(false); }}
        message={message}
      />
      <Typography variant="h4">{obtainLanguage.collect.title}</Typography>
      <Typography variant="h6">{obtainLanguage.collect.searchQR}</Typography>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Typography variant="body1">{obtainLanguage.collect.searchQRTitle}</Typography>
        </Grid>
        { enableScan && <Grid item xs={12}>
          <Paper>
            <QrReader
              delay={600}
              onError={handleError}
              onScan={handleScan}
              style={{ width: '80%', margin: 'auto' }}
            />
          </Paper>
        </Grid>
        }
        { !enableScan && <Grid item xs={12} md={6} className={classes.searchBoxBtn}>
        <Paper className={classes.buttonWrapper}>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            onClick={() => {
              handleEnabledScan(true);
            }}
          >
            {obtainLanguage.collect.reSearchQR}
          </Button>
        </Paper>
      </Grid>
      }
      </Grid>
      <div>
        {userInfo && <ClientInfoReduced data={userInfo} />}
        {orderInfo && orderProdInfo && <ValidateCollect handleReset={handleReset} dataOrder={orderInfo} dataProdOrder={orderProdInfo} vendorId={vendorId} />}
        {loading && <CircularProgress style={{ margin: 'auto' , display: 'flex', marginTop: '20px',}} />}
      </div>
    </div>
  );
}
CollectQR.propTypes = {
  vendorId: PropTypes.string.isRequired,
  app: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  app: state.app,
  vendorId: state.session.info.userId,
});

export default connect(mapStateToProps, actions)(CollectQR);
