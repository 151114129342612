/*
    Ejemplo => https://tricicloapidev.azurewebsites.net/api/Address/GetCity/15
*/

import { apicenter } from "../../../../data/apicenter";

export async function getCountry() {
  try {
    const link = `${apicenter.API}/Address/GetCountry`;
    const method = "GET";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...apicenter.APIHEADER,
    };

    const response = await fetch(link, { method, headers });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error GET getCountry =>", { error });
    return null;
  }
}
