import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button/Button';
import {
  Card, CardContent, Grid, Typography, InputLabel, Input,
} from '@material-ui/core';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import { getAuth } from '../utils/api/getAuth';
import { apicenter } from '../../data/apicenter';

function Login({ signIn }) {
  const history = useHistory();

  const status = apicenter.STATUS === 'development' ? 'Dev' : '';

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Card style={{ maxWidth: 500 }}>
        <CardContent>
          <Grid container alignItems="center" style={{ marginLeft: 10, height: 100 }}>
            <img src="https://tricicloblobprod.blob.core.windows.net/images/Logo.png" style={{ width: 150 }} alt="logo" />
          </Grid>
          <Formik
            initialValues={{
              email: '',
              pass: '',
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Required';
              } else if (!values.pass) {
                errors.pass = 'Required';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              setTimeout(() => {
                getAuth({
                  user: values.email,
                  pass: values.pass,
                }, (res, message, user = {}) => {
                  if (res) {
                    signIn(user);
                    history.push('/');
                  } else {
                    setFieldError('pass', message);
                  }
                });
                setSubmitting(false);
              }, 400);
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ width: '100%', padding: 20 }}>
                <Grid container spacing={2}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid container item xs={12} spacing={2}>
                      <Typography variant="h2" style={{ marginBottom: 30 }}><b>Iniciar sesión</b></Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                      <InputLabel>Email</InputLabel>
                      <Field type="text" name="email" as={Input} style={{ width: '100%' }} />
                      <ErrorMessage name="email" component="div" />
                    </Grid>
                    <Grid container item xs={12} spacing={2} style={{ marginTop: 40 }}>
                      <InputLabel>Contraseña</InputLabel>
                      <Field type="password" name="pass" as={Input} style={{ width: '100%' }} />
                      <ErrorMessage name="pass" component="div" />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={2} justifyContent="flex-end" alignItems="flex-end">
                    <Button type="submit" disabled={isSubmitting} variant="contained" color="primary" style={{ marginTop: 30 }}>CARGAR</Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          <small style={{ color: 'rgba(0,0,0,.2)' }}>
            1.2.4
            {' '}
            {status}
          </small>
        </CardContent>
      </Card>
    </Grid>
  );
}

Login.propTypes = {
  signIn: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  signIn: (user) => dispatch({ type: 'SIGN_IN', payload: user }),
});

export default connect(null, mapDispatchToProps)(Login);
