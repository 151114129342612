import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, capitalize } from 'lodash';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


function UserCard({ user, sale, product }) {
  const orderContent = !isEmpty(sale) && !isEmpty(product) && (
    <>
      <Grid item xs={12}>
        <Typography variant="h2">Datos Ingresados</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ width: '100%' }}>
          <CardContent>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td>
                    <Typography variant="body1">Datos ingresados</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{sale.user}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">Carga ingresada</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`${sale.charged}ml`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">Producto</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`${product.name}`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">Precio por litro</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`$${product.conversion}/${product.unitMeasurement}`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">Precio dispensación</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`$${(sale.charged * product.conversion) / 1000}`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">Precio envase nuevo</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`$${product.packPrice}`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">Capacidad envase</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`${product.vol / 1000} ${product.unitMeasurement}`}</Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </CardContent>
        </Paper>
        <Typography variant="caption">Datos generales de la venta ingresada y dispensador.</Typography>
      </Grid>
    </>
  );

  const userContent = !isEmpty(user) && (
    <>
      <Grid item xs={12}>
        <Typography variant="h2">Datos usuario</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ marginBottom: 10 }}>
          <CardContent>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td>
                    <Typography variant="body1">Nombre</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`${capitalize(user.name)} ${capitalize(user.lastName)}`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">Saldo</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`$${user.credits}`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body1">Mail</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`${user.email}`}</Typography>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Typography variant="body1">Rut</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">{`${user.rut}`}</Typography>
                  </td>
                </tr>
              </tbody>

            </table>
          </CardContent>
        </Paper>
        <Typography variant="caption">Datos del usuario ingresado en el dispensador.</Typography>
      </Grid>
    </>
  );
  return (
    <>
      {orderContent}
      {userContent}
    </>
  );
}

UserCard.propTypes = {
  sale: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};


export default UserCard;
