/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, InputLabel, Grid, IconButton,
} from '@material-ui/core';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    boxShadow: theme.shadows[2],
  },
  iconButtonS: {
    color: 'white',
    backgroundColor: theme.palette.success.light,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
    boxShadow: theme.shadows[2],
  },

}));

function PackagesForm({
  packages, onSubmit, onPrev,
}) {
  const classes = useStyles();

  const packInitials = { comment: '' };
  packages.forEach((p) => {
    packInitials[p.idCheckPackage] = '0';
  });

  return (
    <div>
      <Formik
        initialValues={packInitials}
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {};
          packages.forEach((p) => {
            if (values[`${p.idCheckPackage}`] === '') {
              errors[`${p.idCheckPackage}`] = 'Este campo es obligatorio';
            }
          });
          return errors;
        }}
      >
        <Form style={{ width: '100%', padding: 20 }}>
          <Grid container spacing={10}>
            {packages.map((pack) => (
              <Grid item sm={6} xs={12} key={`p${pack.idCheckPackage}`}>
                <InputLabel>{pack.description}</InputLabel>
                <Field
                  type="number"
                  name={`${pack.idCheckPackage}`}
                  as={Input}
                  style={{ width: '100%' }}
                />
                <ErrorMessage name={pack.idCheckPackage} component="div" />
              </Grid>
            ))}

            <Grid item sm={12} xs={12}>
              <InputLabel>Observaciones generales</InputLabel>
              <Field
                type="text"
                name="comment"
                as={Input}
                style={{ width: '100%' }}
              />
            </Grid>

            <Grid item container xs={12} sm={12} direction="row-reverse" justifyContent="space-between" style={{ marginTop: '3%' }}>
              <IconButton type="submit" color="default" className={classes.iconButtonS}>
                <Check />
              </IconButton>
              <IconButton onClick={onPrev} color="default" className={classes.iconButton}>
                <ChevronLeft />
              </IconButton>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </div>
  );
}

PackagesForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  packages: PropTypes.array.isRequired,
  onPrev: PropTypes.func.isRequired,
};

export default PackagesForm;
