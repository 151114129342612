import { isEmpty } from 'lodash';

export const isNewPack = (pack) => pack === null || pack.package.impact === 0;

export const getPrice = (pack, product, sale) => {
  const price = (sale.charged * product.conversion) / 1000;
  const packPrice = isNewPack(pack) * product.packPrice;
  return price + packPrice;
};

export const getOrderPrice = (order, product, sale) => {
  const price = (sale.charged * product.conversion) / 1000;
  const packPrice = product.packPrice * order.isNewPackage;
  return price + packPrice;
};

const getUserValidations = (user, pack) => ([
  {
    key: 'correctUser',
    isAlert: false,
    condition: isEmpty(pack) || (!isEmpty(pack) && user.userId === pack.client.userId),
    successText: isEmpty(pack) ? 'Envase nuevo' : 'Envase ya pertenece a usuario',
    errorText: 'Envase no pertenece a usuario ingresado',
    fixText: 'Por favor vuelva a hacer la venta usando otro envase',
  },
]);

const getNoOrderValidations = (user, pack, product, sale) => ([
  {
    key: 'correctPayment',
    isAlert: false,
    condition: getPrice(pack, product, sale) <= user.credits,
    successText: 'Usuario tiene saldo suficiente',
    errorText: 'Usuario no tiene saldo suficiente',
    fixText: 'Por favor dispense menos o cargue saldo al cliente',
  },
]);

const getOrderValidations = (user, product, sale, order) => ([
  {
    key: 'creditNotUsed',
    isAlert: false,
    condition: getOrderPrice(order, product, sale)
        <= order.creditNotUsed + user.credits + order.qtyPkgNotUsed * product.packPrice,
    successText: 'Usuario tiene saldo suficiente',
    errorText: 'Usuario no tiene saldo suficiente',
    fixText: 'Por favor dispense menos o cargue saldo al cliente',
  },
  {
    key: 'alertAdditionalCharge',
    isAlert: true,
    condition: (sale.charged * product.conversion) / 1000 > order.creditNotUsed,
    successText: '',
    errorText: 'Se planea dispensar más de lo pagado, por lo que se haría un cobro adicional al saldo del cliente.',
    fixText: '',
  },
  {
    key: 'alertPackCharge',
    isAlert: true,
    condition: order.qtyPkgNotUsed < order.isNewPackage,
    successText: '',
    errorText: 'El envase no está pagado, por lo que se haría un cobro adicional.',
    fixText: '',
  },

]);

export const getValidations = (user, pack, product, sale, order = null) => {
  const vals = [];
  vals.push({
    key: 'userExist',
    isAlert: false,
    condition: !isEmpty(user),
    successText: 'Usuario existente',
    errorText: 'Usuario no existente',
    fixText: 'Por favor revisar datos ingresados',
  });
  if (!isEmpty(user)) {
    vals.push(...getUserValidations(user, pack));
    if (!isEmpty(order)) {
      vals.push(...getOrderValidations(user, product, sale, order));
    } else {
      vals.push(...getNoOrderValidations(user, pack, product, sale));
    }
  }
  return vals;
};
