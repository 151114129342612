import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

const useStyles = makeStyles((theme) => ({
  cancel: {
    color: theme.palette.error.light,
  },
  success: {
    color: theme.palette.success.light,
  },
  alert: {
    color: theme.palette.warning.light,
  },
}));


function Validation({
  condition, successText, errorText, fixText, isAlert,
}) {
  const classes = useStyles();
  const success = (
    <>
      <Grid item>
        <CheckCircleIcon className={classes.success} />
      </Grid>
      <Grid item>
        <Typography variant="body1">{successText}</Typography>
      </Grid>
    </>
  );
  const alert = (
    <>
      <Grid item>
        <WarningRoundedIcon className={classes.alert} />
      </Grid>
      <Grid item>
        <Typography variant="body1">{errorText}</Typography>
      </Grid>
    </>
  );
  const error = (
    <>
      <Grid item>
        <CancelIcon className={classes.cancel} />
      </Grid>
      <Grid item>
        <Typography variant="body1">{errorText}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">{fixText}</Typography>
      </Grid>
    </>
  );

  return (
    <Paper style={{ marginBottom: 10 }}>
      <Grid container spacing={2} style={{ paddingLeft: 10 }}>
        {condition && isAlert && alert}
        {condition && !isAlert && success}
        {!condition && !isAlert && error}
      </Grid>
    </Paper>
  );
}

Validation.propTypes = {
  condition: PropTypes.bool.isRequired,
  successText: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
  fixText: PropTypes.string.isRequired,
  isAlert: PropTypes.bool.isRequired,
};


export default Validation;
