import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  MenuItem,
  Select,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import CardFloatTitle from '../../../components/ui/Card/CardFloatTitle';
import RoundedIconButton from '../../../components/globals/Buttons/RoundIconButton';
import { Fn } from '../../../components/utils/Fn';
import Tags from './Tags';
import Comments from './Comments';

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const IndexListOrder = ({
  data,
  orderStatus,
  setQuestion,
  setIndexViewQuestion,
  history,
  editClient,
  onAddComment,
  filterData,
  setQuestionGrey,
  filterRecompra,
  filterNuevo,
  productFilter,
  own,
}) => {
  const classes = useStyles();
  const handleChangeStatus = (value, orderNumber) => {
    const statusId = value.target.value;
    setIndexViewQuestion({
      orderNumber,
      status: statusId,
    });
    // eslint-disable-next-line no-unused-expressions
    statusId === 1
      ? setQuestionGrey(true)
      : setQuestion(true);
  };

  const handleEditClient = (userId) => {
    editClient({ userId });
    history.push(`client/${userId}`);
  };
  const findprod = (orderProducts) => {
    let show = true;
    orderProducts.map((order) => {
      const aux = productFilter.find((product) => product.id === order.productId);
      if (aux !== undefined && aux.show !== true) {
        show = false;
      }
    });
    return show;
  };
  const orderCancel = orderStatus && orderStatus.find((e) => e.idOrderStatus === 5);
  const showStatus = (status) => {
    const element = filterData.find((statusElement) => statusElement.id === status);
    return element ? element.show : true;
  }
  return (
    <div>
      {
        orderStatus && orderStatus.map((orderItem) =>
          // eslint-disable-next-line react/prop-types
          (
            orderItem.idOrderStatus !== 4 && showStatus(orderItem.idOrderStatus) && (
              <Card key={orderItem.idOrderStatus} className="CardFloatTitle-contain" style={{ marginTop: 60 }}>
                <CardFloatTitle
                  primaryColor={orderItem.primaryColor}
                  secondaryColor={orderItem.secondaryColor}
                >
                  <div style={{
                    paddingLeft: 15, paddingRight: 15, paddingTop: '2%', paddingBottom: '2%',
                  }}
                  >
                    <Typography variant="h5">{orderItem.description}</Typography>
                  </div>
                </CardFloatTitle>
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      {
                        data.map((i) => {
                          const condition = i.isNewClient ? filterNuevo : filterRecompra;
                          const showOrderByProduct = findprod(i.orderProducts);
                          const saldoSuficiente = i.useBalance || ((i.total <= i.credits) && !i.useBalance);

                          return condition && showOrderByProduct && i.status === orderItem.idOrderStatus && (
                            <div key={`${i.orderNumber}s`}>
                              <Grid container spacing={1}>
                                <Grid item xs={12} lg={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                  <RoundedIconButton onClick={() => handleEditClient(i.userId)}>
                                    <Edit fontSize="small" />
                                  </RoundedIconButton>
                                  <Tooltip title={i.clientName}>
                                    <Typography
                                      variant="h6"
                                      style={{
                                        overflow: 'hidden', marginLeft: 10, marginRight: 10, textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                                      }}
                                    >
                                      <b>{i.clientName}</b>
                                    </Typography>
                                  </Tooltip>
                                  <Typography variant="body1" style={{ opacity: 0.4, whiteSpace: 'nowrap' }}>
                                    <b>
                                      #
                                      {i.orderNumber}
                                    </b>
                                  </Typography>
                                </Grid>

                                <Tags order={i} />

                                <Grid item xs={6}>

                                  <Typography variant="caption" style={{ color: saldoSuficiente ? 'black' : 'red' }}>Saldo: </Typography>
                                  <Typography variant="body1" style={{ color: saldoSuficiente ? 'black' : 'red' }}>
                                    <b>
                                      {' '}
                                      $
                                      {
                                      Fn.separadorMiles(i.credits)
                                      }
                                    </b>
                                  </Typography>
                                  {'  '}
                                  {!saldoSuficiente && <small style={{ color: 'red' }}>Saldo insuficiente</small>}
                                </Grid>
                                {/* <Grid item xs={6} style={{ textAlign: 'right' }}>
                                  <Typography variant="caption">Horario: </Typography>
                                  <Typography variant="body1"><b>{i.block}</b></Typography>
                                </Grid> */}
                                <Grid item xs={12} lg={10}>
                                  {i.orderProducts.map((product) => (
                                    <div className="row ml-0">
                                      <Grid item xs={3} lg={2}>
                                        <Typography variant="caption">
                                          {product.productName}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={2} lg={2}>
                                        <Typography variant="caption">
                                          {product.productQuantity} {product.unitMeasurement}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={2} lg={2}>
                                        <Typography variant="caption">
                                          $
                                          {Fn.separadorMiles(product.productPrice)}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={3} lg={2}>
                                        <Typography variant="caption">
                                          <b>
                                            {'Estado: '}
                                            {product.dispensingStatus ? 'Dispensado' : 'No dispensado' }
                                          </b>
                                        </Typography>
                                      </Grid>
                                    </div>
                                  ))}
                                  {i.orderPackages
                                    ? i.orderPackages.map((packages) => (
                                      <div className="row ml-0">
                                        <Grid item xs={4} lg={3}>
                                          <Typography variant="caption">
                                            {packages.packageName}
                                            {' envase(s) '}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={4} lg={3}>
                                          <Typography variant="caption">
                                            $
                                            {Fn.separadorMiles(packages.packagePrice)}
                                          </Typography>
                                        </Grid>
                                      </div>
                                    ))
                                    : (<></>)}
                                  <Typography variant="caption">
                                    <br />
                                    <b>Dirección: </b>
                                    {i.address}
                                  </Typography>
                                  <Typography variant="caption">
                                    <br />
                                    <b>Referencia: </b>
                                    {i.addrRef}
                                  </Typography>
                                  <Typography variant="caption">
                                    <br />
                                    <b>Recibe: </b>
                                    {`${i.contact || 'No especificado'}`}
                                  </Typography>
                                  <Typography variant="caption">
                                    <br />
                                    <b>Origen: </b>
                                    {i.origin}
                                  </Typography>
                                  <Typography variant="caption">
                                    <br />
                                    <b>Total Producto(s): </b>
                                    $ {Fn.separadorMiles(i.total)}
                                  </Typography>
                                  <Typography variant="caption">
                                    <br />
                                    <b>Valor despacho: </b>
                                    $ {Fn.separadorMiles(i.deliveryCost)}
                                  </Typography>
                                  <Typography variant="caption">
                                    <br />
                                    <b>
                                      Total a pagar: $
                                      {Fn.separadorMiles(i.totalDscto)}
                                    </b>
                                  </Typography>
                                </Grid>
                                {own
                                  ? (
                                    <Grid item xs={12} lg={10}>
                                      <Typography variant="caption">
                                        <b>Estado del pedido: </b>
                                      </Typography>
                                      { (orderItem.idOrderStatus !== 5 && orderItem.idOrderStatus !== 3) ? (
                                        <Select
                                          style={{ width: '100%' }}
                                          value={i.status}
                                          onChange={(val) => handleChangeStatus(val, i.orderNumber)}
                                          displayEmpty
                                          className={classes.selectEmpty}
                                          inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                          {orderStatus.map((order) => (
                                            order.idOrderStatus !== 1
                                            && (
                                              <MenuItem
                                                key={order.idOrderStatus}
                                                value={order.idOrderStatus}
                                              >
                                                {order.description}
                                              </MenuItem>
                                            )
                                          ))}
                                        </Select>
                                      ) : (
                                        <Typography variant="caption">
                                          <b>{orderCancel.description}</b>
                                        </Typography>
                                      )}
                                      <Comments order={i} onAddComment={onAddComment} />
                                    </Grid>
                                  )
                                  : (
                                    <Grid item xs={12}>
                                      <Typography variant="caption">
                                        <b>Estado del pedido:</b>
                                      </Typography>
                                      <Typography variant="body2">{i.statusDescription}</Typography>
                                    </Grid>
                                  )}
                                <Grid
                                  item
                                  xs={12}
                                  style={{ marginBottom: 30, marginTop: 20, opacity: 0.3 }}
                                >
                                  <hr />
                                </Grid>
                              </Grid>
                            </div>
                          );
                        })
                      }
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )
          ))
      }
    </div>
  );
};

IndexListOrder.propTypes = {
  data: PropTypes.array.isRequired,
  orderStatus: PropTypes.array.isRequired,
  filterData: PropTypes.array.isRequired,
  setQuestion: PropTypes.func.isRequired,
  setIndexViewQuestion: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  editClient: PropTypes.func.isRequired,
  onAddComment: PropTypes.func.isRequired,
  setQuestionGrey: PropTypes.any.isRequired,
  filterRecompra: PropTypes.bool.isRequired,
  filterNuevo: PropTypes.bool.isRequired,
  productFilter: PropTypes.array.isRequired,
  own: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  orderStatus: state.app.orderStatus,
  userId: state.session.id,
});

const mapDispatchToProps = (dispatch) => ({
  setIndexViewQuestion: (payload) => dispatch({ type: 'indexViewQuestion', payload }),
  editClient: (payload) => dispatch({ type: 'editClient', payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexListOrder);
