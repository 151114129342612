import { apicenter } from '../../../../data/apicenter';


export function getProduct(mac, callback) {
  const link = `${apicenter.API_DISP}/Dispenser/${mac}`;
  const method = 'GET';
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };

  fetch(link, { method, headers })
    .then((res) => res.json())
    .then((res) => {
      if (callback) callback(res, true);
    })
    .catch((error) => {
      if (callback) callback(error, false);
    });
}
