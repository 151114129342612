import { apicenter } from '../../../data/apicenter';


export async function postCreateClient(req, callback) {
  const link = `${apicenter.API}/Clients/Insert`;

  const method = 'POST';
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json', ...apicenter.APIHEADER };
  const body = JSON.stringify(req);

  fetch(link, { method, headers, body })
    .then((res) => res.json())
    .then((res) => {
      if (res.error.errorCode === 0) {
        if (callback) callback(res, true);
      } else if (callback) callback(res, false, res.error.errorMessage);
    })
    .catch((error) => {
      if (callback) callback(error, false, 'Error en el sistema');
    });
}
/*
  {
    "IdCountry":46,
    ""Rut"": ""1919191933"",
      ""Name"": ""Test"",
      ""LastName"": ""Test"",
      ""Phone"":""+56999999999"",
      ""BirthDate"": ""1990/01/01"",
      ""Email"" : ""juan.33@gearsolutions.com"",
      ""Imagesrc"":""http://RutaDeImagen"",
    ""User"":{
    ""Password"" : ""juan.2019"",
    ""UserType"" : ""0""
    }
}
*/

/* --------- RES ----------------

    address : null
    address2 : null
    age : null
    birthDate : "1996-05-06T00:00:00"
    createdAt : "2020-01-19T18:09:10.8475628Z"
    credits : 0
    email : "dev@gmail.com"
    errorMessage : null
    group : null
    id : 9264
    imagesrc : "https://tricicloblobprod.blob.core.windows.net/images/imageDefault.png"
    impact : null
    isActive : true
    isEmailValidate : null
    isPhoneValidate : null
    lastName : "user"
    name : "dev"
    phone : "966217532"
    points : 0
    rut : "19360214-2"
    updatedAt : "2020-01-19T18:09:10.8475642Z"
    user : Object
    userId : 9304


 */
