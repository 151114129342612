const defaultState = {
  list: [],
  zoneActive: false,
};

export function orders(state = defaultState, action) {
  switch (action.type) {
    case "GET_ORDERS_BY_DATE":
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
